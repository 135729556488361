import React, { useEffect, useState } from "react";
import './styles.scss';
import { DatePicker, Form, Input, Modal, Select } from "antd";
import esES from 'antd/es/date-picker/locale/es_ES'
import { BagOrderFormProps } from "./types";
import { useBrands } from "../../../Services/useBrands";
import { BagOrderValues, useBagOrder } from "../../../Services/useBagOrder";
import { useGetHybrid } from "../../../Services/useGetHybrid";
import { useGetCampaigns } from "../../../Services/useGetCampaigns";
import { SelectValues } from "../LotByProductionForm";

export interface FieldsValues{
    value: string,
    id: string,
    key: number,
  }
export interface BrandsFormFilters { 
    brand_code: string  
}

export interface BrandFormFields{
    brand_code: string,
}

export const BagOrderForm: React.FC<BagOrderFormProps> =({
    openForm,
    rowSelect,
    handleCancelForm,
    form_bag_order
})=>{
    useEffect(()=>{
        form_bag_order.resetFields()
    },[])
    
    const [loadingBtn, setLoadingBtn] = useState(false)
    const { createBagOrder, editBagOrder} = useBagOrder()    
    const { data: campaigns } = useGetCampaigns()
    const { Option } = Select;
    const {data: hybrid} = useGetHybrid()

    const handleCancel =()=>{
        handleCancelForm()
        setLoadingBtn(false)
    }

    const handleSave = async (values: {
        hybrid: SelectValues,
        campaign: SelectValues,
        quantity: number,
        female_availability: string,
        female_coment: string,
        male_availability: string,
        male_coment: string,
        sterility_percentage: string
    })=>{

        let newData ={
            hybrid: Number(values.hybrid.key),
            campaign:  Number(values.campaign.key),
            quantity: values.quantity,
            female_availability: values.female_availability,
            female_coment: values.female_coment,
            male_availability: values.female_availability,
            male_coment: values.male_coment,
            sterility_percentage: values.sterility_percentage
        }
        if(rowSelect){ //Edit
            await editBagOrder(handleCancel, rowSelect.id, newData)           
        }else {
            await createBagOrder(handleCancel, newData)
        }  
    }    

    return(
        <div>
            <Modal
                title={rowSelect ? 'Editar Orden de bolsas': 'Agregar Orden de bolsas'}
                open={openForm}
                onCancel={handleCancel}  
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={() => form_bag_order.submit()}
            >
                <Form
                    form={form_bag_order}
                    id='form_bag_order'
                    onFinish={handleSave}
                    layout="vertical"
                    
                >
                     <Form.Item
                        label='Híbrido'
                        name='hybrid'
                        initialValue={rowSelect ? {
                            value: rowSelect?.hybrid_code,
                            label: rowSelect?.hybrid_code,
                            key: rowSelect?.hybrid_id
                        } : null}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                         <Select                        
                            labelInValue  
                            loading={!hybrid || hybrid.length === 0}
                            placeholder={!hybrid || hybrid.length === 0 ? 'Cargando híbridos...' : 'Seleciona un híbrido'}                           
                        >
                            {hybrid && hybrid.map((item) => (
                                <Option value={item.code} key={item.id} placeholder='híbrido'>
                                {item.code}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item> 
                    <Form.Item
                        label='Campaña'
                        name='campaign'
                        initialValue={rowSelect ?{
                            value: rowSelect?.campaign_code,
                            label: rowSelect?.campaign_code,
                            key: rowSelect?.campaign_id
                        } : null}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                         <Select                        
                            labelInValue                            
                        >
                            {campaigns && campaigns.map((item) => (
                                <Option value={item.code} key={item.id} placeholder='Campaña'>
                                {item.code}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item>  
                    <Form.Item
                        label='Disponibilidad de hembra'
                        name='female_availability'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        initialValue={rowSelect?.female_availability}
                    >
                        <Input
                            type="number"
                            placeholder="%"
                        />
                    </Form.Item>   
                    <Form.Item
                        label='Comentario (disponibilidad hembra)'
                        name='female_coment'
                        initialValue={rowSelect?.female_coment}
                    >
                        <Input
                            placeholder="Observaciones"
                        />
                    </Form.Item>  
                    <Form.Item
                        label='Disponibilidad de Macho'
                        name='male_availability'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        initialValue={rowSelect?.female_availability}
                    >
                        <Input
                            type="number"
                            placeholder="%"
                        />
                    </Form.Item>   
                    <Form.Item
                        label='Comentario (disponibilidad macho)'
                        name='male_coment'
                        initialValue={rowSelect?.male_coment}
                    >
                        <Input
                            placeholder="Observaciones"
                        />
                    </Form.Item>     
                    <Form.Item
                        label='% de esterilidad (S/F)'
                        name='sterility_percentage'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        initialValue={rowSelect?.sterility_percentage}
                    >
                        <Input
                            type="number"
                            placeholder="%"
                        />
                    </Form.Item>  
                    <Form.Item
                        label='Cantidad de bolsas'
                        name='quantity'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        initialValue={rowSelect?.quantity}
                    >
                        <Input
                            type="number"
                            placeholder=""
                        />
                    </Form.Item>  
                </Form>
            </Modal>
        </div>
    )
}

export default BagOrderForm;