import {  useContext,  } from 'react';
import  { KeyedMutator } from "swr";
import { useAuthSWR } from './useAuthSWR';
import { END_POINT_GENERIC_PROVINCE } from '../Axios/ENDPOINTS';
import { UserContext } from '../Contexts/UserContext/UsersContext';

export interface ProvinceProps {
  id: number;
  name: string,
}


export interface ResponseGetProvince{
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: ProvinceProps[]  
}

export const useGetProvince = () => {
  
    const {setAccesToken} = useContext(UserContext)
    
    const { data, error, mutate }:{
      data: ProvinceProps[], 
      error: any,
      mutate: KeyedMutator<any>
    } = useAuthSWR([END_POINT_GENERIC_PROVINCE])

  
    return {
      data,
      error,
      mutate
    };
};