import { axiosBackEndInstance } from '../Axios';
import { END_POINT_GENERIC_YIELD_BY_ZONE } from '../Axios/ENDPOINTS';
import { notification } from 'antd';
import { useErrorHandlingReturn400 } from '../hooks/useErrorHandlingReturn400';
import { useGetYieldByZone } from './useGetYieldByZone';

export interface YieldByZoneValues{
  hybrid: number,
  zone: number,
  bag_per_hectare: number,
} 

export const useYieldByZone= () => {
  
  const {errorHandling} = useErrorHandlingReturn400()
  const {mutate} = useGetYieldByZone()    

  const createYieldByZone = async (
    callback: Function,
    values: YieldByZoneValues
  ): Promise<any | undefined> => {      
    try {
      const  data = await axiosBackEndInstance.post(
        END_POINT_GENERIC_YIELD_BY_ZONE, values
      )
      const status =  errorHandling(data).request.status      
      if(status === 201 || status === 200){
        mutate()
        callback()
        notification.success({
          message: 'Éxito', 
          description:'Rendimiento de zona asignado con éxito', 
          placement: 'topRight'
       })
      }
    } catch (newError: any) {
      callback()      
      let status = errorHandling(newError).request.status
      if(status == 400){
        const errorMessage =
        newError.response && newError.response.data &&  newError.response.data.non_field_errors
          ? JSON.stringify(newError.response.data)
          : 'Unknown Error';
        if(errorMessage.includes('CODE 460')){
          notification.warning({
            message: 'Error', 
            placement: 'topRight'
          })
        }else{
          notification.error({
            message: 'Error',
            description: 'Hubo un error, comuníquese con el administrador', 
            placement: 'topRight'
          })
        }
      }
      console.log('status: ' + status + ' ' + newError);
    }
  };

  const editYieldByZone = async (
    callback: Function,
    id: number,
    values: YieldByZoneValues                           
  ): Promise<void> => {            
    try {
      const data  = await axiosBackEndInstance.patch(
        `${END_POINT_GENERIC_YIELD_BY_ZONE}/${id}`,values
      );
      callback()   
      if(data.status === 200 || data.status === 201){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Rendimiento de zona editado con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {      
      let status = errorHandling(newError).request.status
      if(status == 400){
        
        notification.error({
          message: 'Error',
          description: 'Hubo un error, comuníquese con el administrador', 
          placement: 'topRight'
        })
        
      }
      console.log('status: ' + status + ' ' + newError);
    }
  };

  const deleteYieldByZone = async (
    callback: Function,
    id: number                            
  ): Promise<void> => {        
    try {
      const data  = await axiosBackEndInstance.delete(
        `${END_POINT_GENERIC_YIELD_BY_ZONE}/${id}`, 
      );
      callback()   
      if(data.status === 200){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Rendimiento de zona eliminado con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      callback()
      let status = errorHandling(newError).request.status
      console.log(newError);
      console.log(status)
    }
  };

  return {
    createYieldByZone,
    deleteYieldByZone,
    editYieldByZone
  };
};
