import { axiosBackEndInstance } from '../Axios';
import { END_POINT_GENERIC_GDU_BY_ZONE } from '../Axios/ENDPOINTS';
import { notification } from 'antd';
import { useErrorHandling } from '../hooks/useErrorHandling';
import { ZoneProps } from './useGetZone';

export interface GDUbyZoneProps{
    id: number,
    zone: ZoneProps,
    date: string,
    gdu: number,
  
}

export const useGDUperZone = () => {

  
  const {errorHandling} = useErrorHandling()
     
  const getGDUbyZone =  async(
    zone: string
  ): Promise<GDUbyZoneProps[] | undefined> =>{
    try {
      const  data = await axiosBackEndInstance.get(
        `${END_POINT_GENERIC_GDU_BY_ZONE}/${zone}`,
      )
      //callback()
      if(data){
        return data.data
      }
    } catch (newError) {
      //callback()
      const status =  await errorHandling(newError).request.status      
      console.log(newError);
    }
  }    

    const createGDUperZone = async (
      zone: string,
      date: string,
      gdu: string,        
    ): Promise<GDUbyZoneProps | undefined> => {
      
      let newData = {        
        zone: zone,
        date: date,
        gdu: gdu
      }
    try {
      const  data = await axiosBackEndInstance.post(
        END_POINT_GENERIC_GDU_BY_ZONE, newData
      );
      if(data){
        return (data.data)
      } 
    } catch (newError) {
      let status: number = errorHandling(newError).request.status
      console.log(status);      
    }
  };

  const editGDUperZone = async (
    id: number,
    gdu: string,                             
  ): Promise<GDUbyZoneProps | undefined> => {            
    try {
      const data  = await axiosBackEndInstance.patch(
        `${END_POINT_GENERIC_GDU_BY_ZONE}/${id}`,{
          gdu:gdu
        } );
      if(data){
        return (data.data)
      }     
    } catch (newError) {  
      let status = errorHandling(newError).request.status
      console.log(newError);
      console.log(status)
    }
  };

  const deleteGDUperZone = async (
    callback: Function,
    id: number                            
  ): Promise<void> => {        
    try {
      const data  = await axiosBackEndInstance.delete(
        `${END_POINT_GENERIC_GDU_BY_ZONE}/${id}`, 
      );
      callback()   
      if(data.status === 200){
        //mutate()
        notification.success({
          message: 'Éxito', 
          description: 'GDU por zona  eliminada con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      callback()
      let status = errorHandling(newError).request.status
      console.log(newError);
      console.log(status)
    }
  };


  return {
    createGDUperZone,
    deleteGDUperZone,
    editGDUperZone,
    getGDUbyZone
    
  };
};
