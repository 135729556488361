import { useEffect } from 'react';
import  { KeyedMutator } from "swr";
import { useAuthSWR } from './useAuthSWR';
import { END_POINT_GENERIC_PARENTAL } from '../Axios/ENDPOINTS';
import { BrandProps } from './useGetBrands';
import { MaterialTypeProps } from './useGetMaterialsType';
import { FemaleAndMaleMaterial, SuggestedSowRelation } from './useSowPlanificationView';



interface ParentalProps {
  id: number;
  code: string;
  brand: BrandProps;
  material_type: MaterialTypeProps;
  female_material: FemaleAndMaleMaterial;
  male_material: FemaleAndMaleMaterial;
  suggested_sow_relation: SuggestedSowRelation;
  female_sow_gdus: number;
  male_1_sow_gdus: number;
  male_2_sow_gdus: number;
  male_3_sow_gdus: number;
}


export interface ResponseGetMaterials{
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: ParentalProps[]  
}

export const useGetParental = () => {
   
  const {data, error, mutate }:{
    data: ParentalProps[], 
    error: any,
    mutate: KeyedMutator<any>
  } = useAuthSWR([END_POINT_GENERIC_PARENTAL])
  
  useEffect(() => {
    if (!data && !error) {
      mutate();
    }
  }, [data, mutate]);
  
  return {
    data,
    mutate,
    error
  };
};