import  { KeyedMutator } from "swr";
import { useAuthSWR } from './useAuthSWR';
import { END_POINT_GENERIC_CAMPAIGN } from '../Axios/ENDPOINTS';

export interface CampaignProps {
  id: number,
  code: string,
  start_date: string,
  end_date: string
}

export interface ResponseGetCampaigns{
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: CampaignProps[]  
}

export const useGetCampaigns = () => {

    const {data, error, mutate }:{
      data: CampaignProps[], 
      error: any,
      mutate: KeyedMutator<any>
    } = useAuthSWR([END_POINT_GENERIC_CAMPAIGN])    
      
    return {
      data,
      mutate,
      error
    };
};