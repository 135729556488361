import React, { useEffect, useState } from "react";
import './styles.scss';
import { Form, Input, Modal, Select } from "antd";
import { TypeOfDetasselingFormProps } from "./types";
import { useDetasseledEquipment } from "../../../Services/useDetasseledEquipment";

export interface FieldsValues{
    value: string,
    id: string,
    key: number,
  }
export interface TypeOfDetasselingFormFilters { 
    id: number,
    detasseling_equipment_type_code: string,
    ha_of_detasseling_per_hour: number
}

export interface TypeOfDetasselingFormFields{
    detasseling_equipment_type_code: string,
    ha_of_detasseling_per_hour: number
}

export const DetasseledEquipmentForm: React.FC<TypeOfDetasselingFormProps> =({
    form_detasseled_equipment,
    rowSelect,
    handleCancelForm,
})=>{
    
    useEffect(()=>{
        form_detasseled_equipment.resetFields()
    },[])

    const {createDetasseledEquipment, editDetasseledEquipment} = useDetasseledEquipment()
    const [loadingBtn, setLoadingBtn] = useState(false)

    const handleCancel =()=>{     
        handleCancelForm() 
        setLoadingBtn(false)
    }

    const handleSave = async(values: {
        code: string,
        hectares_of_detasseling_per_hour: number
    })=>{
        if(rowSelect.type === 'create'){
            await createDetasseledEquipment(handleCancel, values)
        }else if(rowSelect.type === 'edit' && rowSelect.id){
            await editDetasseledEquipment(handleCancel, rowSelect.id, values)
        }        
    }     

    return(
        <div>
            <Modal
                title={rowSelect.type === 'edit' ? 'Editar Equipo de despanojado' : 'Nuevo Equipo de despanojado'}
                open={rowSelect.show}
                onCancel={handleCancel}  
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar"                 
                onOk={() => form_detasseled_equipment.submit()}
            >
                <Form
                    form={form_detasseled_equipment}
                    id='form_type_of_detasseling'
                    onFinish={handleSave}
                    layout="vertical"                    
                >
                    <Form.Item
                        label='Código de Equipo de despanojado'
                        name='code'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        initialValue={rowSelect?.code}
                    >
                        <Input
                            defaultValue={rowSelect?.code}
                            placeholder="Código / Nombre"
                        />
                    </Form.Item>  
                    <Form.Item
                        label='Hectáreas de despanojado por hora'
                        name='hectares_of_detasseling_per_hour'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        initialValue={rowSelect?.hectares_of_detasseling_per_hour}
                    >
                        <Input
                            type= 'number'
                            defaultValue={rowSelect?.hectares_of_detasseling_per_hour}  
                            placeholder= 'Cantidad de hectáreas'
                        />
                    </Form.Item>                                                    
                </Form>
            </Modal>
        </div>
    )
}

export default DetasseledEquipmentForm;