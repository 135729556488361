import { useContext } from 'react';
import { notification } from 'antd';
import { AxiosResponse } from 'axios';
import { UserContext } from '../Contexts/UserContext/UsersContext';


export const useVerifySession =() => {

  const {setAccesToken} = useContext(UserContext)

  const errorHandling = (data: AxiosResponse<any> | any ) =>{
    if(data.request.status === 401){
      console.log('useVerifySession')
      setAccesToken(undefined)
        notification.error({
          message: 'Error', 
          description:
            'Sesión vencida, vuelva a loguearse.', 
          placement: 'top'
        })
        return data
    }else{
      return data 
    } 
}

  return {
    errorHandling
  };
}