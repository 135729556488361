
export interface PerformanceByZoneProps {
  id: number,
  hybrid: {
    id: number,
    name: string
  },
  zone:{
    id: number,
    zone_code: string
  },
  bags_per_hectare: number
}


export interface ResponseGetPerformanceByZones{
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: PerformanceByZoneProps[]  
}

export const useGetPerformanceByZone = () => {

  let data = {
    count: 1,
    current_page: 1,
    next: '2',
    previous: 'ooo',
    results:[ 
        {
          id: 1,
          hybrid: {
            id: 1,
            name: 'HIBRIDO'
          },
          zone:{
            id: 1,
            zone_code: 'Codigo de zona'
          },
          brand:{
            id: 1,
            brand_code:'JOHN DEERE'
          },
          bags_per_hectare: 203
        }, 
        {
          id: 2,
          hybrid: {
            id: 1,
            name: 'PARENTAL'
          },
          zone:{
            id: 1,
            zone_code: 'Codigo de zona'
          },
          brand:{
            id: 1,
            brand_code:'JOHN DEERE'
          },
          bags_per_hectare: 313
        }, 
    ]
}
    
    // const {data, error, mutate }:{
    //   data: ResponseGetPerformanceByZones, 
    //   error: any,
    //   mutate: KeyedMutator<any>
    // } = useAuthSWR([URL])

    
    
    // useEffect(() => {
    //   if (!data && !error) {
    //     mutate();
    //   }
    // }, [data, mutate]);
  
    return {
      data,
    };
};