import React, {  useContext, useState } from 'react';
import './styles.scss'
import {  Col, Row} from 'antd';
import {
    EditOutlined,
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import ProfileForm from '../../Forms/ProfileForm';
import { UserContext } from '../../../Contexts/UserContext/UsersContext';



const ProfileSections: React.FC = () => {

    const [form_profile] = useForm()
    const {userData} = useContext(UserContext)

    const [showForm, setShowForm] = useState(false)

    const styleProfileData ={
        display: 'flex',
        color: 'white'        
    }

    const handleCancelForm =()=>{
        setShowForm(false)
    }

  return( 
    <>       
        {showForm && userData && <ProfileForm 
            form_profile={form_profile}
            showForm = {showForm}
            data={userData}
            handleCancelForm={handleCancelForm}
        /> }  
        <div className='profile_section_container'>                                  
            <Row
                style={{                    
                    display: 'flex', 
                    alignItems: 'center',
                    width: '100%',
                    borderBottom: '1px solid white'
                }}
            >
                <h2>Perfil</h2>                
            </Row>
            <Row 
                style={{
                    width: '100%',
                    marginTop: '20px',
                    paddingLeft:'30px',
                    marginLeft: '5px',
                }}
            >
                <Col 
                    span={3}
                >
                    <h4>Editar datos de usuario</h4>
                </Col>
                <Col 
                    span={1} 
                    style={{
                        display: 'flex', 
                        alignItems: 'center',
                    }}>
                        <EditOutlined 
                            onClick={()=>
                                setShowForm(true)   
                            }
                            style={{color:'#0072ce', fontSize: '15px'}}/>
                </Col>
                <Col span={18}>
                </Col>
            </Row>
            {
                userData && 
                    <Row 
                        className='profile_section_data'
                        style={{
                            display:'flex',
                            flexDirection: 'column',
                            width: '100%',
                            height: '70%',
                            padding: '30px 40px',
                            border: '1px solid  #1e2957'
                        }}
                    >
                        <div
                            style={styleProfileData}
                        > 
                            <h4>Nombre: </h4> <p>{userData?.first_name}</p>
                        </div>  
                        <div
                            style={styleProfileData}
                        > 
                            <h4>Apellido: </h4><p>{userData?.last_name}</p>
                        </div>  
                        <div
                            style={styleProfileData}
                        > 
                            <h4>Nombre de usuario: </h4><p>{userData?.username}</p>
                        </div>                                      
                    </Row> 
            }
                
                  
        </div>
    </>        
  )};

export default ProfileSections;