import React, { useState } from "react";
import './styles.scss';
import { Form, Input, Modal, Select } from "antd";
import { UserFormProps } from "./types";

export interface FilterValues{
    value: string,
    id: string,
    key: number,
  }
export interface UserFormFilters {
    id: number,
    user_code: string,
    name: string,
    lastname: string, 
    userType:FilterValues,
    phone: string
}

export const UserForm: React.FC<UserFormProps> =({
    showModal,
    setShowModal,
    form_user
})=>{

    
    const [loadingBtn, setLoadingBtn] = useState(false)

    const handleCancel =()=>{
        setShowModal(false)
    }

    const handleSave =()=>{
        
    }    

    return(
        <div>
            <Modal
                title='Agregar Usuario'
                open={showModal}
                onCancel={handleCancel}    
                onOk={handleSave} 
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
            >
                <Form
                    form={form_user}
                    id='form_user'
                    onFinish={handleSave}
                    layout="vertical"
                    
                >
                    <Form.Item
                        label='Usuario'
                        name='user_code'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                            <Input
                                placeholder="Ingresa un email"
                            />

                    </Form.Item>    
                    <Form.Item
                        label='Nombre'
                        name='name'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Input
                            placeholder="Nombre"
                        />
                    </Form.Item>       
                    <Form.Item
                        label='Apellido'
                        name='lastname'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Input
                            placeholder="Apellido"
                        />
                    </Form.Item>  
                    <Form.Item
                        label='Teléfono'
                        name='phone'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Input
                            placeholder="Teléfono"
                        />
                    </Form.Item>   
                    <Form.Item
                        label='Tipo de usuario'
                        name='userType'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Select
                            labelInValue
                        ></Select>
                    </Form.Item> 
                </Form>


            </Modal>
        </div>
    )
}

export default UserForm;