import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import './styles.scss'
import { Button, Col, Modal, Row, Table, Tooltip } from 'antd';
import {
    EditOutlined,
    DeleteOutlined
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import {  ResponseBagOrder, useGetBagOrder } from '../../../Services/useGetBagOrder';
import { useBagOrder } from '../../../Services/useBagOrder';
import BagOrderForm from '../../Forms/BagOrderForm';
import { StylesContext } from '../../../Contexts/UserContext/StylesContext';
import { UserContext } from '../../../Contexts/UserContext/UsersContext';


export interface DataType {
    index: number;
    id: number;
    hybrid_code: string;
    hybrid_id: string;
    campaign_code: string;
    campaign_id: string;
    brand_id: string;
    brand_code: string;
    female_code: string;
    female_id: string;
    quantity: number;
    female_availability: string
    female_coment: string,
    male_id: string,
    male_code: string,
    male_availability: string,
    male_coment: string,
    sterility_percentage: string,
    [key: string]: any;   
}

const BagOrderSection: React.FC = () => {

    const {data: dataApi, mutate, error} = useGetBagOrder()    
    const {deleteBagOrder} = useBagOrder()
    const {adjustedTableHeigthScroll} = useContext(StylesContext)
    const {userData} = useContext(UserContext)

    const [form_bag_order] = useForm()
    const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
    const [loadingFilters, setLoadingFilters] = useState(false)
    const [dataTable, setDataTable] = useState<DataType[]>([])  
    const [rowSelect, setRowSelect] = useState<DataType | undefined>(undefined)
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [openForm, setOpenForm] = useState(false)
  
    const [totalsValues, setTotalValues] = useState<{
        assigned_total_quantity: number,
        total_quantity: number,
        diference: number,
        color_diference: string,
        total_planned_bags: number,
        volume_vs_planned_percentage: number
        color_V_vs_P: string
    } | undefined>()

    const numberFormatter = new Intl.NumberFormat('es-ES'); 

    useEffect(()=>{
        setLoadingTableData(true)
        if(dataApi){
            rowData() 
            setLoadingTableData(false)
        }
    },[dataApi])   

    const rowData = (data? : ResponseBagOrder) =>{
        let filterData: DataType[]= []
        let list: ResponseBagOrder = data ? data : dataApi
        const difference = list.total_planned_bags - list.total_quantity;
        let colorDif_VvsP = 'black';   
        if (difference < -4.6 || difference > 4.6) {
            colorDif_VvsP = 'red';
        } else if (difference >= -4.6 && difference <= 4.6) {
            colorDif_VvsP = 'green';
        }
        setTotalValues({
            assigned_total_quantity: list.assigned_total_quantity,
            total_quantity: list.total_quantity,           
            diference: difference,
            color_diference: colorDif_VvsP,
            total_planned_bags: list.total_planned_bags,
            volume_vs_planned_percentage: list.volume_vs_planned_percentage,
            color_V_vs_P: colorDif_VvsP
        });
        

        if(list){
          list.bag_orders.map((data, index)=>{  
            let info: DataType =   {
                index: index,
                id: data.id,
                campaign_code: data?.campaign?.code || '',
                campaign_id: data?.campaign?.id.toString() || '',
                hybrid_code: data?.hybrid?.code || ' ',
                hybrid_id: data?.hybrid?.id.toString() || '',
                brand_id: data?.hybrid?.brand.id.toString()  || '',
                brand_code: data?.hybrid?.brand.code || '',
                female_id: data?.hybrid?.female_material?.id.toString() || '',
                female_code: data?.hybrid?.female_material?.code || '',
                quantity: data?.quantity || 0,
                female_availability: Number(data.female_availability).toFixed(),
                female_coment: data?.female_coment || ' ',
                male_code: data?.hybrid?.male_material?.code || '',
                male_id: data?.hybrid?.male_material?.id.toString()  || '',
                male_availability:  Number(data.male_availability).toFixed(),
                male_coment: data?.male_coment || '',
                sterility_percentage:  Number(data.sterility_percentage).toFixed()                
            }                        
            filterData.push(info)
          })       
        }       
        setDataTable(filterData)
    }


    const callback = () =>{
        setLoadingFilters(false)
        setLoadingTableData(false)
        setShowConfirmDelete(false)     
        setRowSelect(undefined)
    }

    const ModalConfirmDelete =() => {
        return(
            <Modal
                title="Eliminar"
                open={showConfirmDelete}
                onOk={()=>{
                    if(rowSelect && rowSelect.id){
                        deleteBagOrder(callback, rowSelect.id)
                    }}}
                onCancel={()=>setShowConfirmDelete(false)}
                okText="Eliminar"
                cancelText="Cancelar"
            >
                <p>¿Estás seguro de que desea eliminar el pedido de bolsa?</p>
            </Modal>
        )
    }

    const handleRenderer = useCallback((text: string, data: DataType) =>{
        return (
            <div className='bag_order_action_render'>
                <EditOutlined 
                    className='icons'
                    onClick={()=>{
                        setRowSelect(data)
                        setOpenForm(true)
                    }}
                />
                <DeleteOutlined 
                    className='icons'
                    onClick={()=>{
                        setRowSelect(data)
                        setShowConfirmDelete(true)
                    }}
                    style={{marginLeft:'30px'}}
                    />
            </div>  
        )      
    },[])

    const filters = useCallback((type: 'hybrid' | 'brand' | 'female' | 'male' | 'male_avalaibility' | '%_sterility') => {
        let textAndValue: { text: string, value: string }[] = [];
        let uniqueValues = new Set();
    
        dataApi?.bag_orders.forEach((item) => {
            let code
            switch(type){
                case 'hybrid':
                    code = item?.hybrid?.code 
                    break;
                case 'brand':
                    code = item?.hybrid?.brand.code
                    break;
                case 'female':
                    code = item?.hybrid?.female_material?.code
                    break
                case 'male':
                    code = item?.hybrid?.male_material?.code
                    break            
            }
           
            if (code && !uniqueValues.has(code)) {
                uniqueValues.add(code);
                textAndValue.push({
                    text: code,
                    value: code
                });
            }

        });
        return textAndValue;
    },[])
  
    const columns: ColumnsType<DataType> = useMemo(() => {
        const baseColumns: ColumnsType<DataType> = [
            {
                title: 'Híbrido',
                dataIndex: 'hybrid_id',
                render: (text, record)=> <p>{record.hybrid_code}</p>,
                key: '1',
                width: 150,
                filters: filters('hybrid'),
                onFilter: (value, record) => typeof value === 'string' && record.hybrid_code.includes(value),
                filterSearch: true,            
                rowScope:  'row',
                sorter: (a, b) => a.hybrid_id.localeCompare(b.hybrid_id),
                sortDirections: ['ascend', 'descend'],
            },
            {
                title: 'Marca',
                dataIndex: 'brand_id',
                render: (text, record)=> <p>{record.brand_code}</p>,
                key: '2',
                width: 150,
                filters: filters('brand'),
                onFilter: (value, record) => typeof value === 'string' && record.brand_code.includes(value),
                filterSearch: true,
                sorter: (a, b) => a.brand_id.localeCompare(b.brand_id),
                sortDirections: ['ascend', 'descend'],
            },
            {
                title: 'Hembra',
                dataIndex: 'female_code',
                render: (text, record)=> <p>{record.female_code}</p>,
                key: '3',
                width: 150,
                filters: filters('female'),
                onFilter: (value, record) => typeof value === 'string' && record.female_code.includes(value),
                filterSearch: true,
                rowScope:  'row',
                sorter: (a, b) => a.female_code.localeCompare(b.female_code),
                sortDirections: ['ascend', 'descend'],
            },
            {
                title: 'Disponibilidad hembra',
                dataIndex: 'female_availability',
                key: '4',
                width: 160,
                render: (text, record)=> 
                    <Tooltip title={record.female_coment}>
                        <p>
                            {text}%  {record.female_coment}
                        </p>
                    </Tooltip>
                    ,  
                ellipsis: true,
                className: 'cursor_pointer',
                sorter: (a, b) => Number(a.female_availability) - Number(b.female_availability),            
            },
            {
                title: 'Macho',
                dataIndex: 'male_code',
                render: (text, record)=> <p>{record.male_code}</p>,
                key: '5',
                width: 150,
                filters: filters('male'),
                onFilter: (value, record) => typeof value === 'string' && record.male_code.includes(value),
                filterSearch: true,
                rowScope:  'row',            
                sorter: (a, b) => a.male_code.localeCompare(b.male_code),
                sortDirections: ['ascend', 'descend'],
            }, 
            {
                title: 'Disponibilidad macho',
                dataIndex: 'male_availability',
                key: '6',
                width: 150,            
                ellipsis: true,
                render: (text, record)=> <p>{text}%  {record.male_coment}</p>,
                sorter: (a, b) => Number(a.male_availability) - Number(b.male_availability)
            },
            {
                title: '% De esterilidad (S/F)' ,
                dataIndex: 'sterility_percentage',
                key: '7',
                width: 150,
                render: (text, record)=> <p>{text}% </p>,
                rowScope:  'row',
                sorter: (a, b) => Number(a.sterility_percentage) - Number(b.sterility_percentage)
            }, 
            {
                title: 'Pedido de bolsas' ,
                dataIndex: 'quantity',
                key: '8',
                width: 150,
                sorter: (a, b) => Number(a.quantity) - Number(b.quantity)
            },         
                 
        ];
        if (userData?.is_staff) {
            baseColumns.push({
                title: 'Acciones',
                dataIndex: 'id',
                render:  handleRenderer,
                key: '9',
                width: 110,
                ellipsis: true,     
                fixed: 'right',
                rowScope:  'row',       
            });
        }

        return baseColumns;
    }, [userData, filters, handleRenderer]);

    const handleCancelForm = ()=>{
        form_bag_order.resetFields() 
        setRowSelect(undefined)   
        setOpenForm(false)           
    }
    
    return( 
        <>
            <ModalConfirmDelete/>
            {openForm  && <BagOrderForm
                openForm={openForm} 
                handleCancelForm={handleCancelForm}
                form_bag_order={form_bag_order}
                rowSelect={rowSelect}
            />}     
            <div className='bag_order_section_container'>                                  
                <Row
                    className='bodyContainer_title'
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <div>
                        <h2>Ordenes de Bolsas</h2>   
                    </div>
                    <div
                        className='bag_distribution_section_col'
                    >                       
                       <h3 className='h3_title'>Volume Plan:</h3>  
                        <h3 className='h3' >{ totalsValues && numberFormatter.format(totalsValues?.total_quantity)}</h3>
                        <h3 className='h3_title'>Total Sol:</h3>  
                        <h3 className='h3'>{ totalsValues && numberFormatter.format(totalsValues?.assigned_total_quantity)}</h3>
                        <h3 className='h3_title'>Total Plan:</h3>  
                        <h3 className='h3'>{ totalsValues && numberFormatter.format(totalsValues?.total_planned_bags)}</h3>
                        <h3 className='h3_title'>VP vs Tot Plan.:</h3>  
                        <h3 style={{
                            color: totalsValues?.color_V_vs_P
                        }}>{ totalsValues && numberFormatter.format(totalsValues?.volume_vs_planned_percentage)}</h3>
                        <h3 className='h3_title'>Diferencia: </h3>      
                        {totalsValues &&
                            <h3 
                                style={{
                                    color: totalsValues.color_diference,
                                    marginRight: '20px'
                                }}
                            > {numberFormatter.format(totalsValues.diference)}
                            </h3>
                        }        
                    </div>                                                             
                </Row>
                
                {
                    userData && userData?.is_staff ?
                        <Row 
                            className='header_row'
                        >
                            <Button
                                type='primary'
                                onClick={()=>{
                                setOpenForm(true)
                                    }}
                                >   Agregar orden de bolsas
                            </Button>                          
                        </Row>
                    :
                        <div style={{marginBottom: '40px'}}>

                        </div>                                    
                }                
                <Row 
                    className='bodyContent_container_table'
                >
                    {loadingTableData
                        ? <SpinerHorizontal/> 
                        : <Table 
                            style={{
                                width:'95%',
                            }}
                            bordered
                            columns={columns} 
                            dataSource={dataTable}                                      
                            scroll={{ y: adjustedTableHeigthScroll }}
                            pagination={false}
                        />    
                    } 
                </Row>             
            </div>
        </>       
        
)};

export default BagOrderSection;