import { axiosBackEndInstance } from '../Axios';
import { END_POINT_LOTS_BY_PRODUCTION_SOW_PLANIFICATION_VIEW } from '../Axios/ENDPOINTS';
import { MaterialTypeProps } from './useGetMaterialsType';
import { FarmProps } from './useGetFarms';
import { BrandProps } from './useGetBrands';
import { useErrorLoguin } from '../hooks/useErrorLoguin';

export interface FemaleAndMaleMaterial {
  id: number;
  code: string;
  gdus_to_flower: number;
  brand: BrandProps;
  material_type: MaterialTypeProps;
}

export interface SuggestedSowRelation {
  id: number;
  code: string;
  female_sow_relationship: number;
  male_sow_relationship: number;
}

export interface Hybrid {
  id: number;
  code: string;
  gdus_to_flower: number;
  brand: BrandProps;
  material_type: MaterialTypeProps;
  female_material: FemaleAndMaleMaterial;
  male_material: FemaleAndMaleMaterial;
  suggested_sow_relation: SuggestedSowRelation;
  female_sow_gdus: number;
  male_1_sow_gdus: number;
  male_2_sow_gdus: number;
  male_3_sow_gdus: number | null;
}

interface Campaign {
  id: number;
  code: string;
  start_date: string;
  end_date: string;
}

export interface Day {
  date: string,
  accumulated_gdus: number,
  accumulated_flowering_gdus: number,
  female_sow?: boolean,
  male_1_sow?: boolean,
  male_2_sow?: boolean,  
  male_3_sow?: boolean,
  flowering_day?: boolean,
  puller: boolean,
  cutter: boolean,
  set_manualy: boolean,
  resources: number
}

export interface LotByProductionResults{
  lot:{
    id: number;
    code: string;
    farm: FarmProps;
    hectares: number;
    contract_start_date: string;
    contract_end_date: string
  }
  id: number;
  campaign: Campaign;
  hybrid: Hybrid;
  tentative_sow_date: string | null;
  sow_date: string | null;
  harvest_date: string | null;
  detasseled_date: string | null;
  flowering_gdus: number;
  flowering_50_gdus: number;
  female_sow_gdus: number;
  male_1_sow_gdus: number;
  male_2_sow_gdus: number;
  male_3_sow_gdus: number | null;
  observations: string;
  days: Day[] | string | {error: number, response: string, date?: string},
}

export interface DayOfWork {
  date: string;
  resources: number;
}

export interface DaysOfWork {
  [date: string]: DayOfWork;
}

export interface SowPlanificationViewProps {
  first_date: string,
  last_date: string,
  lots_by_production_restults: LotByProductionResults [] 
  days_of_manual_work: DaysOfWork
}

export interface ResponseGetPerformanceByZones{
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: SowPlanificationViewProps[]  
}

export const useSowPlanificationView = () => {

  const {errorHandling} = useErrorLoguin()

  const getSowPlanificationView =  async(
    campaign: string,
    zone: number[],
    is_full_grower: number,
    callback: Function
  ): Promise<SowPlanificationViewProps | undefined> =>{

    var params = new URLSearchParams();
    zone && params.append("zones", zone.join(',')) 
  
    try {
      const  data = await axiosBackEndInstance.get(
        `${END_POINT_LOTS_BY_PRODUCTION_SOW_PLANIFICATION_VIEW}/${campaign}/${is_full_grower}`, 
        {
          params
        }
      )
      //callback()
      if(data){    
        return data.data
      }
    } catch (newError) {
      callback()
      const status =  await errorHandling(newError).request.status      
      console.log(newError);
    }
  }    

    return {
      getSowPlanificationView,
    };
};