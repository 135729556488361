import React, { useContext, useState } from 'react';
import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import { FieldType } from './types';
import './styles.scss'
import { messages } from './messages';
import { useIntl } from 'react-intl';
import logo from '../../assets/logo/Corteva_logo.png'
import { Navigate, useNavigate } from 'react-router';
import { UserContext } from '../../Contexts/UserContext/UsersContext';
import { useLogin } from '../../Services/useLogin';
import { useForm } from 'antd/es/form/Form';


const Login: React.FC = () => {
    
    const navigate = useNavigate();
    const [form_login] = useForm()
    const[loadingBtn, setLoadingBtn] = useState(false)

    const {accesToken, setAccesToken } = useContext(UserContext);
    const { loginRequest } = useLogin()

    const intl = useIntl();

    const callback=()=>{
        setLoadingBtn(false)
    }

    const onFinish = async(values: {username: string, password: string}) => {
       setLoadingBtn(true)
       const data = await loginRequest(callback, values)        
        if(data?.status === 200){
            setAccesToken(data.data.access)
        }       
    };
      
    const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
    };

    return( 
        <div className='login_container'>
            <div className='login_row_logo'>
                <img src={logo}/>
            </div>
           <div className='login_row_form'>
                <Form
                    layout="vertical"
                    name="form_login"
                    form={form_login}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: 600 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    
                >
                    <Form.Item<FieldType>
                        name="username"
                        rules={[{ 
                            required: true, 
                            message: 'Campo obligatorio'//intl.formatMessage(messages.rule) 
                        }]}
                    >
                        <Input 
                            placeholder='Usuario'//{intl.formatMessage(messages.placeholderUser)}
                        />
                    </Form.Item>
                    <Form.Item<FieldType>
                        name="password"
                        rules={[{ 
                            required: true, 
                            message: 'Campo obligatorio'// intl.formatMessage(messages.rule)  
                        }]}
                    >
                        <Input.Password 
                            placeholder='Contraseña'//{intl.formatMessage(messages.placeholderPassword)}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button 
                            type="primary" 
                            htmlType="submit"
                            loading={loadingBtn}    
                        >
                            ingresar
                        </Button>
                    </Form.Item>
                </Form>
            </div>
           
            <div className='login_background'>
            
            </div>
        </div>
        
        
)};

export default Login;