import React, { useEffect, useState } from "react";
import './styles.scss';
import { Form, Input, Modal } from "antd";
import { ProvinceFormProps } from "./types";
import { useProvince } from "../../../Services/useProvince";

export interface ProvinceFormFilters {
    name: string,

}

export const ProvinceForm: React.FC<ProvinceFormProps> =({
    form_province,
    rowSelect,
    handleCancelForm,
})=>{

    useEffect(()=>{
        form_province.resetFields()
    },[])
    
    const {createProvince, editProvince} = useProvince()
    const [loadingBtn, setLoadingBtn] = useState(false)

    const handleCancel =()=>{     
        handleCancelForm() 
        setLoadingBtn(false)
    }

    const handleSave = async(value:{name: string})=>{
        if(rowSelect.type === 'create'){
            await createProvince(handleCancel, value )
        }else if(rowSelect.type === 'edit' && rowSelect.id){
            await editProvince(handleCancel, rowSelect.id, value)
        }        
    }    

    return(
        <div>
            <Modal
                title={rowSelect.type === 'edit' ? 'Editar Provincia' : 'Nueva Provincia'}
                open={rowSelect.show}
                onCancel={handleCancel} 
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={() => form_province.submit()}
            >
                <Form
                    form={form_province}
                    id='form_province'
                    onFinish={handleSave}
                    layout="vertical"
                    
                >
                    <Form.Item
                        label='Provincia'
                        name='name'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        initialValue={rowSelect?.name}
                    >
                            <Input
                                defaultValue={rowSelect?.name}
                            />

                    </Form.Item>                   
                </Form>


            </Modal>
        </div>
    )
}

export default ProvinceForm;