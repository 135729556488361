import React, { useState } from "react";
import './styles.scss'
import { FiltersProps } from "./types";
import { Col, Row, Tooltip } from "antd";
import {
    PlusCircleOutlined,
    FilterOutlined
  } from '@ant-design/icons';
import { calc } from "antd/es/theme/internal";

export const Filters: React.FC<FiltersProps> = ({
    components,
    icons,
    tooltip,
    difference,
    widthContainer,
    marginTopContainer
})=>{

    const [collapsedFilsters, setCollapsedFilters] = useState(false)

    const handleFilters = ()=>{
        setCollapsedFilters(!collapsedFilsters)
    }

    return(
        <div 
            style={{ height:`calc(100% - ${difference})`}}
            className={collapsedFilsters ? "filters_container" : "filters_container collapsed"}
        >
            <div
                style={{ 
                   // marginTop: marginTopContainer,
                    width: widthContainer ? widthContainer : '400px', 
                    height: '100%', 
                    display: 'flex',
                }}
            >
                <div
                    onClick={handleFilters}
                    style={{
                        width: '66px',
                        display: 'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        backgroundColor:'#121832',
                        height: '50px',
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '10px',                        
                        cursor: 'pointer', 
                        borderBottom:'1px solid #0072ce',
                        borderLeft:'1px solid #0072ce'
                    }}
                >
                    <Tooltip
                        title={tooltip}
                    >
                        {icons}
                    </Tooltip>
                </div>
                <div
                    style={{
                        width: 'calc(100% - 66px)',
                        backgroundColor:'#121832', 
                        height: '100%',
                        
                    }}
                >
                    {components}
                </div>
            </div>           
        </div>
    )
}

export default Filters