import { axiosBackEndInstance } from '../Axios';
import { END_POINT_GENERIC_FARM } from '../Axios/ENDPOINTS';
import { notification } from 'antd';
import { useErrorHandling } from '../hooks/useErrorHandling';
import { FarmProps, useGetFarms } from './useGetFarms';


export const useFarms= () => {

    const {errorHandling} = useErrorHandling()
    const { mutate } = useGetFarms()
     
    const getFilteredFarm = async (
      callback: Function,
      field_id: number,
      field_code: string,
      description: string,
      city_id: number,
      farm_owner_id: number,      
      pageValue?: string | undefined,        
      ): Promise<FarmProps[] | undefined> => {
      
      var params = new URLSearchParams();
      pageValue && params.append("page", pageValue)
      field_id && params.append("field_id", field_id.toString()) 
     
      try {
        const {
          data,
        }: { data: any } = await axiosBackEndInstance.get(
          END_POINT_GENERIC_FARM,
          { 
            params
          }
        );
        callback()
        return data;
      } catch (newError) {
        console.log(newError);
        callback()
      }
    };

  const createFarm = async (
    callback: Function,
    newData:{
      code: string,
      description: string,
      city: number, 
      farm_owner: number,
      is_full_grower_farm: boolean,
      contract_start_date: Date,
      contract_end_date: Date,
    }): Promise<any> => {      
    try {
      const  data = await axiosBackEndInstance.post(
        END_POINT_GENERIC_FARM, newData
      )
      callback()   
      if(data.status === 200 || data.status === 201){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Campo creado con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      console.log(newError);
      callback()
    }
  };

  const editFarm = async (
    callback: Function,
    id: number,
    values:{
      code: string,
      description: string,
      city: number, 
      farm_owner: number,
      is_full_grower_farm: boolean,
      contract_start_date: Date,
      contract_end_date: Date,
    }
                            
  ): Promise<void> => {
      
    try {
      const data  = await axiosBackEndInstance.put(
        `${END_POINT_GENERIC_FARM}/${id}`,values
      );
      callback()   
      if(data.status === 200 || data.status === 201){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Campo editado con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      callback()
      let status = errorHandling(newError).request.status
      console.log('status: ' + status + ' ' + newError);
    }
  };

  const deleteFarm = async (
    callback: Function,
    id: number                            
  ): Promise<void> => {
      
    try {
      const data  = await axiosBackEndInstance.delete(
        `${END_POINT_GENERIC_FARM}/${id}`, 
      );
      callback()   
      if(data.status === 200){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Campo eliminado con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      let status = errorHandling(newError).request.status
      console.log('status: ' + status + ' ' + newError);
      callback()
    }
  };

  return {
    getFilteredFarm,
    createFarm,
    deleteFarm,
    editFarm
  };
};
