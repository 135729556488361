import React, { useContext, useEffect, useState } from "react";
import { FormItemProps, ModifyHumanResourcesFormProps } from "./types";
import { Button, Col, DatePicker, Flex, Form, Input, Popover, Row, message  } from "antd";
import { useForm } from "antd/es/form/Form";
import {PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import dayjs from "dayjs";
import { useDaysOfManualDetasselingByLot } from "../../../Services/useDaysOfManualDetasselingByLot";
import { PlanificationContext } from "../../../Contexts/UserContext/PlanificationContext";
import { useAsyncError } from "react-router";


export const ModifyHumanResourcesForm: React.FC<ModifyHumanResourcesFormProps> = ({
    rowSelect_id,
    icons_manual,
    quantity,
    mutateTable,
}) => {

    const {editDaysOfManualDatasseling} = useDaysOfManualDetasselingByLot()
    const {zoneAndCampaignSelected, setResources, resources  } = useContext(PlanificationContext)
    
    const [messageApi, contextHolder] = message.useMessage();
    const [showDatesForm, setShowDatesForm] = useState<boolean>(false)
    const [ form_human_resources ] = useForm()
    const [ amountRows, setAmountRows ] = useState<FormItemProps[]>([])
    const [ doRefresh, setDoRefresh] = useState<boolean>(false)

    const hide = () => {
        setShowDatesForm(false);
    };

    useEffect(()=>{
        if(showDatesForm){
             setAmountRows(resources[rowSelect_id])
        }       
    },[resources, showDatesForm])       

    const handleOpenChange = (newOpen: boolean) => {
        setShowDatesForm(newOpen);
        form_human_resources.resetFields() 
        if(newOpen){
            if(amountRows.length == 0){
                setDoRefresh(!doRefresh)                                 
            }else{
                if(amountRows !== resources[rowSelect_id]){
                    setDoRefresh(!doRefresh)
                }
            }
        }
        if(!newOpen){
            setAmountRows([])
        }        
    };

    const callback = () =>{
        setShowDatesForm(false)  
        form_human_resources.resetFields() 
        setAmountRows([])
    }

    const handleSave = async (calculateBased: boolean) =>{
        await editDaysOfManualDatasseling(callback, rowSelect_id, calculateBased ? [] : amountRows)          
        mutateTable(zoneAndCampaignSelected) 
        messageApi.info('Actualizando datos');          
    }

    const add = () =>{       
        const newRow = {
            date: undefined,
            quantity: undefined,
        };
        setAmountRows([...amountRows, newRow]);
    }

    const remove = (index: number, date: string | undefined) => {
        form_human_resources.setFieldValue(`${date}`, undefined )       
        form_human_resources.setFieldValue(`quantity_${date}`, undefined ) 
        setAmountRows(amountRows.filter((_, i) => i !== index));
    };

    const handleChangeDatePicker = (e: dayjs.Dayjs, item: FormItemProps, index: number)=>{
        const formattedDate = e.format('YYYY-MM-DD')
        amountRows.filter((x, i) => {
            if (i === index) {
                x.date = formattedDate;
            }
        });
    }

    const handleChangeQuantity = (e: React.ChangeEvent<HTMLInputElement>, item: FormItemProps, index: number) => {
        const updatedAmountRows = [...amountRows]; // Clonar el array para mantener la inmutabilidad
        updatedAmountRows[index] = {
            ...updatedAmountRows[index], // Clonar el elemento actual para no mutar directamente
            quantity: Number(e.target.value) // Actualizar solo la cantidad en el elemento clonado
        };
        setAmountRows(updatedAmountRows); // Actualizar el estado con el nuevo array clonado
    };

    const FormPopup = () =>{
        return(
            <Form
                style={{marginTop: '20px'}}
                form={form_human_resources}
                id='form_human_resources'
                onFinish={()=>handleSave(false)}
                layout="vertical"                
            >
             {
                amountRows.map((item, index)=>
                    <Row key={index}>
                        <Col span={11}>
                            <Form.Item
                                name={item.date}
                                initialValue={item.date ? dayjs(item.date, 'YYYY-MM-DD') : null}
                                rules={[
                                    {
                                        required: true,
                                        message:'Este campo es obligatorio'
                                    }
                                ]}
                            >              
                                <DatePicker
                                    onChange={(e)=>handleChangeDatePicker(e , item, index)}
                                    placeholder="Fecha"    
                                                            
                                />                                                              
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                name={`quantity_${item.date}`}
                                initialValue={item.quantity}
                            >
                                <Input                                
                                    placeholder="Cantidad"
                                    type="number"
                                    onChange={(e)=>handleChangeQuantity(e , item, index)}
                                       
                                /> 
                            </Form.Item>  
                        </Col>  
                        <Col span={2}>
                            <DeleteOutlined 
                                onClick={()=>remove(index, item.date)}
                                className= 'icons'
                                style={{ marginLeft: '10px', cursor: 'pointer'}}/>
                        </Col>                                                                                  
                    </Row>                
                )
             }    
             <Form.Item>
                <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '100%' }}
                    icon={<PlusOutlined />}
                >
                    Agregar fecha
                </Button>            
            </Form.Item>    
            <Form.Item>
                <Button
                    onClick={()=>handleSave(true)}
                    danger
                    style={{ width: '100%'}}                
                >
                    Calcular en base
                </Button>   
            </Form.Item>                 
            <Form.Item 
                style={{                
                    width: '100%',
                }}
            >
                <div style={{
                    width: '100%',
                    display:'flex',
                    justifyContent: 'space-between'
                }}>
                    <Button type="primary" htmlType="submit">
                        Guardar
                    </Button>
                    <Button  onClick={()=>{
                        handleOpenChange(false)
                    }} >
                        Cancelar
                    </Button>
                </div>
                
            </Form.Item>    
          
            </Form>
        )
    }

    return(
        <>
            {contextHolder}
                <p style={{
                    cursor: 'pointer',
                    color:'#00b303', 
                    fontSize: '10px',
                    display: 'flex',
                }}
                    onClick={()=>setShowDatesForm(true)}
                >
                    {icons_manual} {quantity}
                </p> 
        </>
        
    )
}