import React, { useState } from "react";
import './styles.scss';
import { Form, Input, Modal, Select } from "antd";
import { PerformanceByZoneFormProps } from "./types";

export interface FieldsValues{
    value: string,
    id: string,
    key: number,
  }
export interface PerformanceByZoneFormFilters { 
    id: number,
    hybrid: FieldsValues,
    zone: FieldsValues,
    brand: FieldsValues,
    bags_per_hectare: number
}

export interface PerformanceByZoneFormFields{
    hybrid: FieldsValues,
    zone: FieldsValues,
    bags_per_hectare: number
}

export const PerformanceByZoneForm: React.FC<PerformanceByZoneFormProps> =({
    showModal,
    setShowModal,
    form_performance_by_Zone
})=>{
    
    const [loadingBtn, setLoadingBtn] = useState(false)

    const handleCancel =()=>{
        setShowModal(false)
        setLoadingBtn(false)
    }

    const handleSave =()=>{
        
    }    

    return(
        <div>
            <Modal
                title='Agregar rendimiento por zona'
                open={showModal}
                onCancel={handleCancel}    
                onOk={handleSave} 
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
            >
                <Form
                    form={form_performance_by_Zone}
                    id='form_performance_by_Zone'
                    onFinish={handleSave}
                    layout="vertical"
                    
                >
                    <Form.Item
                        label='Híbrido'
                        name='hybrid'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Select
                            placeholder="Híbrido"
                        ></Select>
                    </Form.Item>  
                    <Form.Item
                        label='Zona'
                        name='zone'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Select
                            placeholder= 'Seleccione la zona'
                        >
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label='Bolsas'
                        name='bags'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Input
                            placeholder="Cantidad de bolsas"
                            type= 'number' 
                        />
                    </Form.Item>                                                     
                </Form>
            </Modal>
        </div>
    )
}

export default PerformanceByZoneForm;