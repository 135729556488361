import  { KeyedMutator } from "swr";
import { useAuthSWR } from './useAuthSWR';
import { END_POINT_GENERIC_BRAND } from '../Axios/ENDPOINTS';

export interface BrandProps {
  id: number,
  code: string, 
}

export interface ResponseGetBrands{
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: BrandProps[]  
}

export const useGetBrands = () => {

    const {data, error, mutate }:{
      data: BrandProps[], 
      error: any,
      mutate: KeyedMutator<any>
    } = useAuthSWR([END_POINT_GENERIC_BRAND])
 
    return {
      data,
      mutate,
      error
    };
};