import React, { useEffect, useState } from "react";
import './styles.scss';
import { Checkbox, Form, Input, Modal, Select } from "antd";
import { MaterialFormProps } from "./types";
import { BrandProps, useGetBrands } from "../../../Services/useGetBrands";
import { MaterialTypeProps, useGetMaterialsType } from "../../../Services/useGetMaterialsType";
import { MaterialsValues, useMaterials } from "../../../Services/useMaterials";
import { MaterialProps, useGetMaterials } from "../../../Services/useGetMaterials";
import { SowRelationProps, useGetSowRelation } from "../../../Services/useGetSowRelation";

export interface FieldsValues{
    value: string,
    label: string,
    key: number,
  }
export interface MaterialsFormFilters { 
    material_code: string  
    gdus_to_flowering: number,
    brand:FieldsValues,
    material_type: FieldsValues
}

export interface MaterialFormValues{
    code: string,
    brand: FieldsValues,
    material_type: FieldsValues,
    female_material: FieldsValues | undefined,
    male_material: FieldsValues | undefined
    suggested_sow_relation: FieldsValues | undefined,
    female_sow_gdus: number | undefined,
    male_1_sow_gdus: number | undefined,
    male_2_sow_gdus: number | undefined,
}

export const MaterialForm: React.FC<MaterialFormProps> =({
    openForm,
    handleCancelForm,
    form_material,
    rowSelect,
    materials
})=>{

    useEffect(()=>{
        form_material.resetFields()
        if(rowSelect){
            if(rowSelect.material_type_id == 1){
                setIsHybrid(true)
            }
        }
        if(rowSelect && rowSelect.is_sterile == 'true'){
            setChecked(true)
        }
    },[])

   
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [isHybrid, setIsHybrid] = useState<boolean>(false)
    const [checked, setChecked] = useState(false);
    const {data: brands} = useGetBrands()
    const {data: materialsType} = useGetMaterialsType()
    const {data: sowRelatios} = useGetSowRelation()
    const {createMaterial, editMaterials} = useMaterials()
    const {Option} = Select

    const handleCancel =()=>{
        handleCancelForm()
        setLoadingBtn(false)
    }
    
    const handleSave = async(values: MaterialFormValues)=>{    
        setLoadingBtn(true)

        const modifiedValues = {
            ...values,
            brand: values.brand.key,
            material_type: values.material_type.key, 
            female_material: values.female_material?.key || undefined,
            male_material: values.male_material?.key || undefined,
            suggested_sow_relation: values.suggested_sow_relation?.key || undefined

        };
        if(rowSelect ){
            await editMaterials(handleCancel, rowSelect.id, modifiedValues)
            
        }else{
            await createMaterial(handleCancel, modifiedValues)
        }        
    }    

    const handleChangeMaterialType = (value:{
        label: string,
        key: number,
        value: string
    }) =>{
        if(value.key == 1){
            setIsHybrid(true)
        }else{
            setIsHybrid(false)
        }
    }

    
    const onChangeCheck=(e: boolean)=>{
        setChecked(e)
        form_material.setFieldValue('is_sterile', e)
    }

    return(
        <div>
            <Modal
                title= {rowSelect ? 'Editar Material' : 'Agregar material'}
                open={openForm}
                onCancel={handleCancel}    
                onOk={() => form_material.submit()} 
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar"                 
            >
                <Form
                    form={form_material}
                    id='form_Material'
                    onFinish={handleSave}
                    layout="vertical"                    
                >
                    <Form.Item
                        label='Nombre del material'
                        name='code'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        initialValue={rowSelect?.code}
                    >
                        <Input
                            placeholder="Código"
                            
                        />
                    </Form.Item>                    
                    <Form.Item
                        label='Marca'
                        name='brand'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        initialValue={rowSelect && {
                                value: rowSelect?.brand_name,
                                key: rowSelect?.brand_id,
                                label: rowSelect?.brand_name
                            }}
                    >
                        <Select
                            placeholder='Marca'
                            showSearch
                            labelInValue
                        >
                            {brands &&
                                brands.map((brand: BrandProps) => (
                                <Option value={brand.code} key={brand.id}>
                                    {brand.code}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>                
                    <Form.Item
                        label='Tipo de material'
                        name='material_type'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        initialValue={rowSelect && rowSelect?.material_type_code && rowSelect?.material_type_id ?  {
                                value: rowSelect?.material_type_code,
                                key: rowSelect?.material_type_id,
                                label: rowSelect?.material_type_code
                            } : undefined}
                    >
                        <Select
                            placeholder='Tipo de material'
                            showSearch
                            labelInValue                          
                            onChange={handleChangeMaterialType}
                        >
                            {materialsType &&
                                materialsType.map((materialType: MaterialTypeProps) => (
                                <Option value={materialType.code} key={materialType.id}>
                                    {materialType.code}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item> 
                    <Form.Item
                                name='is_sterile'
                                initialValue={rowSelect?.is_sterile == 'true' ? true : false}
                                rules={[
                                    {
                                        required: true,
                                        message:'Este campo es obligatorio'
                                    }
                                ]}
                            >
                                <Checkbox
                                    checked={checked}
                                    onChange={(e) => onChangeCheck(e.target.checked)}
                                >
                                    Es Esteril
                                </Checkbox>
                            </Form.Item>  
                    {
                        isHybrid &&
                        <>
                            <Form.Item
                                label='Hembra'
                                name='female_material'
                                rules={[
                                    {
                                        required: true,
                                        message:'Este campo es obligatorio'
                                    }
                                ]}
                                initialValue={rowSelect && rowSelect.female_material_code ? {
                                        value: rowSelect?.female_material_code,
                                        key: rowSelect?.female_material_id,
                                        label: rowSelect?.female_material_code
                                    } : undefined}
                            >
                                <Select
                                    placeholder='Material'
                                    showSearch
                                    labelInValue                  
                                >
                                    {materials &&
                                        materials.map((material: MaterialProps) => (
                                        <Option value={material.code} key={material.id}>
                                            {material.code}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label='Macho'
                                name='male_material'
                                rules={[
                                    {
                                        required: true,
                                        message:'Este campo es obligatorio'
                                    }
                                ]}
                                initialValue={rowSelect && rowSelect.male_material_code ? {
                                        value: rowSelect?.male_material_code,
                                        key: rowSelect?.male_material_id,
                                        label: rowSelect?.male_material_code
                                    } : undefined}
                            >
                                <Select
                                    placeholder='Material'
                                    showSearch
                                    labelInValue                     
                                >
                                    {materials &&
                                        materials.map((material: MaterialProps) => (
                                        <Option value={material.code} key={material.id}>
                                            {material.code}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            
                            <Form.Item
                                label='Relación de siembra sugerida'
                                name='suggested_sow_relation'
                                rules={[
                                    {
                                        required: true,
                                        message:'Este campo es obligatorio'
                                    }
                                ]}
                                initialValue={rowSelect && rowSelect.suggested_sow_relation_code ? {
                                        value: rowSelect?.suggested_sow_relation_code,
                                        key: rowSelect?.suggested_sow_relation_id,
                                        label: rowSelect?.suggested_sow_relation_code
                                    } : undefined}
                            >
                                <Select
                                    placeholder='Selecciona una relación'
                                    showSearch
                                    labelInValue 
                                >
                                    {sowRelatios &&
                                        sowRelatios.map((sowRelatios: SowRelationProps) => (
                                        <Option value={sowRelatios.code} key={sowRelatios.id}>
                                            {sowRelatios.code}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>                                 
                            <Form.Item
                                label='gdus de siembra hembra'
                                name='female_sow_gdus'
                                rules={[
                                    {
                                        required: true,
                                        message:'Este campo es obligatorio'
                                    }
                                ]}
                                initialValue={rowSelect?.suggested_sow_relation_id}
                            >
                                <Input
                                    type= 'number' 
                                    placeholder="Unidad"
                                />
                            </Form.Item>      
                            <Form.Item
                                label='gdus de siembra macho 1'
                                name='male_1_sow_gdus'
                                rules={[
                                    {
                                        required: true,
                                        message:'Este campo es obligatorio'
                                    }
                                ]}
                                initialValue={rowSelect?.male_1_sow_gdus}
                            >
                                <Input
                                    type= 'number' 
                                    placeholder="Unidad"
                                />
                            </Form.Item>      
                            <Form.Item
                                label='gdus de siembra macho 2'
                                name='male_2_sow_gdus'
                                rules={[
                                    {
                                        required: true,
                                        message:'Este campo es obligatorio'
                                    }
                                ]}
                                initialValue={rowSelect?.male_2_sow_gdus}
                            >
                                <Input
                                    type= 'number' 
                                    placeholder="Unidad"
                                />
                            </Form.Item>                            
                        </>
                    }
                                                      
                </Form>
            </Modal>
        </div>
    )
}

export default MaterialForm;