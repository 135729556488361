
import { KeyedMutator } from "swr";
import { useAuthSWR } from './useAuthSWR';
import {     END_POINT_GENERIC_YIELD_BY_ZONE
 } from '../Axios/ENDPOINTS';
import { Hybrid } from "./useSowPlanificationView";
import { ZoneProps } from "./useGetZone";


export interface YieldByZoneProps{
  id: number;
  hybrid: Hybrid;
  zone: ZoneProps;
  bag_per_hectare: number;
  bag_per_hectare_late: number;
}

export interface ResponseGetuseGetYieldByZone{
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: YieldByZoneProps[]  
}

export const useGetYieldByZone = () => {
   
  const {data, error, mutate }:{
    data: YieldByZoneProps[] , 
    error: any,
    mutate: KeyedMutator<any>
  } = useAuthSWR([END_POINT_GENERIC_YIELD_BY_ZONE
])

  return {
    data,
    mutate,
    error
  };
};