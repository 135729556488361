import React, { useContext, useEffect, useRef, useState } from 'react';
import './styles.scss'
import { Input, InputRef, Form, message} from 'antd';
import { useForm } from 'antd/es/form/Form';
import {DataType} from './index'
import { useBagDistribution } from '../../../Services/useBagDistribution';
import { UserContext } from '../../../Contexts/UserContext/UsersContext';


const EditingCell: React.FC<{
    record: DataType
    dataIndex: string | number,
    callback: Function
    zoneId: number
}> = ({
    record,
    dataIndex,
    callback,
    zoneId
}) => {
    
    const [editingCell, setEditingCell] = useState(false);
    const [form_edit_cell] = useForm();
    const inputRef = useRef<InputRef>(null);

    const {editBagDistribution, createBagDistribution} =useBagDistribution()
    const {userData} = useContext(UserContext)

        useEffect(() => {
        if (editingCell) {
            (inputRef.current as HTMLInputElement | null)?.focus();
        }
    }, [editingCell]);
    
    const save = async () => {    
        setEditingCell(false)
        const value = await form_edit_cell.validateFields();
        if(record[`bag_distribution_${zoneId}`]){
            try {
               await editBagDistribution( callback, record[`bag_distribution_${zoneId}`], {['assigned_percentage']: value.porcent})
              
            } catch (errInfo) {
                console.log('Save failed:', errInfo);
            }            
        }else{
            try{
                let newData ={
                    bag_order: record.id,
                    zone:  zoneId,
                    assigned_percentage: Number(value.porcent), 
                }
                await createBagDistribution(callback, newData)                
            }catch (errInfo){
                console.log('Save failed:', errInfo);
            }
        }        
    }
    return (
       <div>
            {
                editingCell && userData && userData.is_staff                   
                    ? <Form
                        form={form_edit_cell}
                        name='form_edit_cell'
                    >
                        <Form.Item
                            name='porcent'
                            style={{ padding: '0px', margin:'0px',height: '18.84px', }}
                            initialValue={Number(dataIndex)}
                        >
                            <Input
                                //onClick={onClickInput}
                                type='number'
                                ref={inputRef}  
                                onPressEnter={save} />
                        </Form.Item>  
                    </Form>
                    : <div 
                        className="editable-cell-value-wrap" 
                        style={{ 
                            color: 'red',
                            fontWeight: 500,
                            minWidth: '63px',
                            minHeight: '22px',
                            cursor: 'pointer'
                        }} 
                        onClick={()=>setEditingCell(true)} 
                    >                        
                        {dataIndex}  
                    </div>
            }
       </div>
    )
};

export default EditingCell