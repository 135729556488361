import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import MainRoutes from './Routes/MainRoutes';
import { UserContextProvider } from './Contexts/UserContext/UsersContext';
import './assets/global.scss'
import { PlanificationContextProvider } from './Contexts/UserContext/PlanificationContext';
import { BagDistributionContexProvider } from './Contexts/UserContext/BagDistributionContex';
import { StylesContextProvider } from './Contexts/UserContext/StylesContext';

function App() {
  return (
    <BrowserRouter>
      <StylesContextProvider>
        <UserContextProvider>
          <PlanificationContextProvider>
            <BagDistributionContexProvider>
              <MainRoutes/>
            </BagDistributionContexProvider>          
          </PlanificationContextProvider>        
        </UserContextProvider>      
      </StylesContextProvider>
    </BrowserRouter>
  );
}

export default App;
