import { KeyedMutator } from "swr";
import { useAuthSWR } from './useAuthSWR';
import { END_POINT_GENERIC_MATERIAL_FLOWERING_BY_MONTH } from '../Axios/ENDPOINTS';
import { MaterialProps } from './useGetMaterials';
import { ZoneProps } from './useGetZone';


export interface MaterialFloweringMonthProps {
  id: number;
  material: MaterialProps;
  month: number;
  gdus_until_beginning_of_flowering: string;
  gdus_until_50_percent_of_flowering: string;
  gdus_until_beginning_of_pollen: string;
  gdus_until_50_percent_of_pollen: string;
  zone?: ZoneProps;
}


export interface ResponseGetMaterials{
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: MaterialFloweringMonthProps[]  
}

export const useGetMaterialFloweringMonth = () => {
   
  const {data, error, mutate }:{
    data: MaterialFloweringMonthProps[], 
    error: any,
    mutate: KeyedMutator<any>
  } = useAuthSWR([END_POINT_GENERIC_MATERIAL_FLOWERING_BY_MONTH])
  
  return {
    data,
    mutate,
    error
  };
};