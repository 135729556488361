
import { KeyedMutator } from "swr";
import { useAuthSWR } from './useAuthSWR';
import {     END_POINT_GENERIC_YIELD_BY_ZONE_FOR_DISTRIBUTION
 } from '../Axios/ENDPOINTS';
import { Hybrid } from "./useSowPlanificationView";


export interface YieldByZoneForDistributionProps{
    id: number,
    hybrid: Hybrid,
    zone: {
        id: number,
        code: string,
        description: string,
        has_late_sow: boolean,
        order: number
    },
    bag_per_hectare: number
}

export interface ResponseGetuseGetYieldByZone{
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: YieldByZoneForDistributionProps[]  
}

export const useGetYieldByZoneForDistribution = () => {
   
  const {data, error, mutate }:{
    data: YieldByZoneForDistributionProps[] , 
    error: any,
    mutate: KeyedMutator<any>
  } = useAuthSWR([END_POINT_GENERIC_YIELD_BY_ZONE_FOR_DISTRIBUTION
])

  return {
    data,
    mutate,
    error
  };
};