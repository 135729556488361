import React, { useEffect, useState } from "react";
import './styles.scss';
import { DatePicker, Form, Input, Modal } from "antd";
import { CampaignFormProps } from "./types";
import esES from 'antd/es/date-picker/locale/es_ES'
import { useCampaigns } from "../../../Services/useCampaigns";
import dayjs from 'dayjs';
import moment from "moment";

export interface FieldsValues{
    value: string,
    id: string,
    key: number,
  }
export interface CampaignsFormFilters { 
    campaign_code: string  
}

export interface BatchFormFields{
    campaign_code: string,
    start_date: string,
    end_start: string
}

export const CampaignForm: React.FC<CampaignFormProps> =({
    form_campaign,    
    rowSelect,
    handleCancelForm,
})=>{

    useEffect(()=>{
        form_campaign.resetFields()
    },[])
    
    const [loadingBtn, setLoadingBtn] = useState(false)
    const {createCampaigns, editCampaign} = useCampaigns()
    const dateFormat = 'YYYY/MM/DD';

    const handleCancel =()=>{     
        handleCancelForm() 
        setLoadingBtn(false)
    }

    const handleSave = async(values: {
        code: string, 
        start_date: Date,
        end_date: Date
    })=>{
        
        let day: any = values.start_date
        let end_day: any = values.end_date
        const startDate = day && moment(day.$d).format('YYYY-MM-DD')
        const endDate = end_day && moment(end_day.$d).format('YYYY-MM-DD')

        let newData = {
            code: values.code,
            start_date: startDate,
            end_date: endDate,
        }

        if(rowSelect.type === 'create'){
            await createCampaigns(handleCancel, newData)
        }else if(rowSelect.type === 'edit' && rowSelect.id){
            await editCampaign(handleCancel, rowSelect.id, newData)
        }        
    }      

    return(
        <div>
            <Modal
                title={rowSelect.type === 'edit' ? 'Editar Campaña' : 'Nueva Campaña'}
                open={rowSelect.show}
                onCancel={handleCancel}    
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={() => form_campaign.submit()}
            >
                <Form
                    form={form_campaign}
                    id='form_campaign'
                    onFinish={handleSave}
                    layout="vertical"
                    
                >
                    <Form.Item
                        label='Código de campaña'
                        name='code'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        initialValue={rowSelect?.code}
                    >
                            <Input
                                placeholder="Código"
                                defaultValue={rowSelect?.code}
                            />

                    </Form.Item>                       
                    <Form.Item
                        label='Inicio de campaña'
                        name='start_date'
                        initialValue={rowSelect?.start_date ? dayjs(rowSelect.start_date, dateFormat) : null}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <DatePicker
                            defaultValue={rowSelect?.start_date ? dayjs(rowSelect.start_date, dateFormat) : null}
                            locale={esES} 
                        />
                    </Form.Item>
                    <Form.Item
                        label='Fin de campaña'
                        name='end_date'
                        initialValue={rowSelect?.end_date ? dayjs(rowSelect.end_date, dateFormat) : null}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <DatePicker
                            defaultValue={rowSelect?.end_date ? dayjs(rowSelect.end_date, dateFormat) : null}
                            locale={esES} 
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default CampaignForm;