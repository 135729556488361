import  { KeyedMutator } from "swr";
import { useAuthSWR } from './useAuthSWR';
import { END_POINT_GENERIC_FARM_OWNER } from '../Axios/ENDPOINTS';

export interface OwnersProps {
  id: number,
  code: string,
  name: string,
  last_name: string, 
  phone: string
}


export interface ResponseGetOwners{
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: OwnersProps[]  
}

export const useGetOwners = () => {
   
    const {data, error, mutate }:{
      data: OwnersProps[], 
      error: any,
      mutate: KeyedMutator<any>
    } = useAuthSWR([END_POINT_GENERIC_FARM_OWNER])    
    
    return {
      data,
      mutate,
      error
    };
};