import { axiosBackEndInstance } from '../Axios';
import { URL } from '../Axios/ENDPOINTS';
import { notification } from 'antd';
import { useErrorHandling } from '../hooks/useErrorHandling';
import { ResponseGetPerformanceByZones } from './useGetPerformanceByZone';


export const usePerformancebyZone= () => {

    const {errorHandling} = useErrorHandling()
     
    const getFilteredPerformancebyZone = async (
      callback: Function,
      id: number,
      hybrid_id: number,
      zone_id: number,
      bags_per_hectare: number,
      pageValue?: string | undefined,        
      ): Promise<ResponseGetPerformanceByZones | undefined> => {
      
      var params = new URLSearchParams();

      pageValue && params.append("page", pageValue)
      hybrid_id && params.append("hybrid_id", hybrid_id.toString())
      zone_id && params.append("zone_id", zone_id.toString())
      bags_per_hectare && params.append("bags_per_hectare", bags_per_hectare.toString()) 

      try {
        const {
          data,
        }: { data: any } = await axiosBackEndInstance.get(
          URL,
          { 
            params
          }
        );
        callback()
        return data;
      } catch (newError) {
        console.log(newError);
        callback()
        //throw newError;
      }
    };

    const createPerformancebyZone= async (
      callback: Function,
      newPerformanceByZone:{
        id: number,
        hybrid_id: number,
        zone_id: number,
        bags_per_hectare: number,
      }
      
      ): Promise<any | undefined> => {      
    try {
      const  data = await axiosBackEndInstance.post(
        URL, newPerformanceByZone
      )
      const status =  errorHandling(data).request.status
      if(status === 200){
        callback()
        notification.success({
          message: 'Éxito', 
          description:'Lote creado con éxito', 
          placement: 'topRight'
      })
      }
    } catch (newError) {
      console.log(newError);
      callback()
      //throw newError;
    }
  };

  return {
    getFilteredPerformancebyZone,
    createPerformancebyZone
  };
};
