import React, { useEffect, useState } from 'react';
import './styles.scss'
import {  Button, Modal, Row, Table } from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    CheckOutlined, 
    CloseOutlined
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { FarmsSectionProps } from './types';
import FieldForm, { FieldFormFilters } from '../../Forms/FarmForm';
import { FarmProps, useGetFarms } from '../../../Services/useGetFarms';
import { useFarms } from '../../../Services/useFarms';
import moment from 'moment';

export interface DataType {
    index: number;
    id: number;
    code: string,
    description: string,
    city_id: number,
    city_name: string,
    farm_owner_id: number,
    farm_owner_name: string,
    is_full_grower_farm: string,    
    contract_start_date: string | undefined,
    contract_end_date: string | undefined,  
}

const FarmsSection: React.FC<FarmsSectionProps> = () => {

    const {data: dataApi} = useGetFarms()    
    const {getFilteredFarm, deleteFarm} = useFarms()

    const [form_farm] = useForm()
    const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
    const [loadingFilters, setLoadingFilters] = useState(false)
    const [dataTable, setDataTable] = useState<DataType[]>([])
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [openForm, setOpenForm] = useState<boolean>(false)
    const [rowSelect, setRowSelect] = useState<DataType | undefined>()

    useEffect(()=>{
        setLoadingTableData(true)
        if(dataApi){
            rowData() 
            setLoadingTableData(false)
        }
    },[dataApi])  

    const rowData = (data? : FarmProps[]) =>{
        let filterData: DataType[]= []
        let list: FarmProps[] = data ? data : dataApi

        if(list && list){
          list.map((data, index)=>{            
            filterData.push({
                index: index,
                id: data.id,
                code: data.code,
                description: data.description,
                city_id: data.city.id,
                city_name: data.city.name,
                farm_owner_id: data.farm_owner.id,
                farm_owner_name: data.farm_owner.name,
                is_full_grower_farm: data.is_full_grower_farm ? 'true' : 'false',                
                contract_start_date: data.contract_start_date?  data.contract_start_date : undefined,
                contract_end_date: data.contract_end_date? data.contract_end_date : undefined,  
            })
          })       
        }       
        setDataTable(filterData)
    }

    const handleGetPage = async (page?: number, loadingTable?: boolean) => {
        let values = form_farm.getFieldsValue() 
        let filters: FieldFormFilters = values ? values : undefined 
        let pageNumber: number = page ? page : 1  
        loadingTable && setLoadingTableData(true)
        const data = await getFilteredFarm( 
            callback,         
            filters.id,
            filters.field_code,
            filters.description,
            filters.city_id,
            filters.farm_owner_id,
            pageNumber.toString()
        )        
        if (data) {
            rowData(data);         
        }
    };

    const callback = () =>{
        setLoadingFilters(false)
        setLoadingTableData(false) 
        setShowConfirmDelete(false) 
        form_farm.resetFields() 
        setRowSelect(undefined)   
        setOpenForm(false)       
    }


    const handleRenderer = (text: string, data: DataType) =>{
        return (
            <div className='zone_action_action_render'>
                <EditOutlined 
                    className='icons'
                    onClick={()=>{
                        setOpenForm(true)
                        setRowSelect({  
                            index: data.id,                          
                            id: data.id,
                            code: data.code,
                            description: data.description,
                            city_id: data.city_id,
                            city_name: data.city_name,
                            farm_owner_id: data.farm_owner_id,
                            farm_owner_name: data.farm_owner_name,
                            is_full_grower_farm: data.is_full_grower_farm, 
                            contract_start_date: data.contract_start_date,
                            contract_end_date: data.contract_end_date
                        })
                    }}
                />
                <DeleteOutlined                     
                    className='icons'
                    onClick={()=>{
                        setRowSelect({
                            index: data.id,                          
                            id: data.id,
                            code: data.code,
                            description: data.description,
                            city_id: data.city_id,
                            city_name: data.city_name,
                            farm_owner_id: data.farm_owner_id,
                            farm_owner_name: data.farm_owner_name,
                            is_full_grower_farm: data.is_full_grower_farm,
                            contract_start_date: data.contract_start_date,
                            contract_end_date: data.contract_end_date 
                        })
                        setShowConfirmDelete(true)
                    }}
                    style={{marginLeft:'30px'}} />
            </div>  
        )      
    }

    const ModalConfirmDelete = () => {
        return(
            <Modal
                title="Eliminar"
                open={showConfirmDelete}
                onOk={()=>{
                    if(rowSelect && rowSelect.id){
                        deleteFarm(callback, rowSelect.id)
                    }}}
                onCancel={()=>setShowConfirmDelete(false)}
                okText="Eliminar"
                cancelText="Cancelar"
            >
                <p>¿Estás seguro de que desea eliminar {rowSelect?.code} - {rowSelect?.description}?</p>
            </Modal>
        )
    }

    const renderStartDate =(text: string, record: DataType)=>{
        let start_day: any = record.contract_start_date
         const startDate = start_day && moment(start_day).format('YYYY-MM-DD')
       return(
         <>{startDate}</>
       )
     }
   
     const renderEndDate =(text: string, record: DataType)=>{
        let end_day: any = record.contract_end_date
        const endDate = end_day && moment(end_day).format('YYYY-MM-DD') 
      return(
        <>{endDate}</>
      )
    }
  
    const columns: ColumnsType<DataType> = [
        {
            title: 'Código del Campo',
            dataIndex: 'code',
            key: '1',
        },
        {
            title: 'Descripción',
            dataIndex: 'description',
            key: '2',
            ellipsis: true,
        },
        {
            title: 'Localidad',
            dataIndex: 'city_name',
            key: '2',
            ellipsis: true,
        },
        {
            title: 'Dueño',
            dataIndex: 'farm_owner_name',
            key: '4',
            ellipsis: true,
        },
        {
            title: 'Es full Grower',
            dataIndex: 'is_full_grower_farm',
            render:(value, record)=><div>{value == 'true'? <CheckOutlined style={{color: 'green'}} /> : <CloseOutlined style={{color: 'red'}} />}</div>,
            key: '5',
            ellipsis: true,
        },
        {
            title: 'Inicio del contrato',
            dataIndex: 'contract_start_date',
            key: '6',
            render: renderStartDate,
            ellipsis: true,
        },
        {
            title: 'Fin del contrato',
            dataIndex: 'contract_end_date',
            key: '7',          
            render: renderEndDate,
            ellipsis: true,
        },  
        {
            title: 'Acciones',
            dataIndex: 'id',
            render:  handleRenderer,
            key: 'id',
            width: 110,
            ellipsis: true,            
        },
            
    ];

    return( 
        <>
        <ModalConfirmDelete/>
            {openForm &&<FieldForm
                callback={callback}
                form_farm={form_farm}
                rowSelect = {rowSelect}
                openForm={openForm}
            />}      
            <div className='owner_section_container'>                                  
                <Row
                    className='bodyContainer_title'
                >
                    <h2>Campos</h2>                
                </Row>
                <Row 
                    className='header_row'
                >              
                    <Button
                    type='primary'
                    onClick={()=>{
                        setOpenForm(true)    
                        }}
                    >   Agregar campo
                    </Button>
                </Row>               
                <Row 
                    className='bodyContent_container_table'
                >
                    {
                        loadingTableData
                        ? <SpinerHorizontal/> 
                        : <Table 
                            style={{
                                width: '80%',
                            }}
                            columns={columns} 
                            dataSource={dataTable}  
                            pagination={{
                                pageSize: 50,
                                showSizeChanger: false,
                            }}
                            scroll={{ y: 580 }}
                        />    
                    } 
                </Row>             
            </div>
        </>
        
        
)};

export default FarmsSection;