import React, { useContext, useEffect, useState } from 'react';
import './styles.scss'
import {  Button, Modal, Row, Table,  } from 'antd';
import {
    EditOutlined,
    DeleteOutlined
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { YieldByZoneProps, useGetYieldByZone } from '../../../Services/useGetYieldByZone';
import { useYieldByZone } from '../../../Services/useYieldByZone';
import YieldByZoneForm from '../../Forms/YieldByZoneForm';
import { StylesContext } from '../../../Contexts/UserContext/StylesContext';


export interface DataType {
    index: string;
    id: string;
    hybrid_code: string;
    hybrid_id: string,
    bag_per_hectare: string;
    bag_per_hectare_late: string;
    zone_code: string;
    zone_id: string
}

const YieldByZoneSection: React.FC = () => {

    const {data: yieldByZone} = useGetYieldByZone()    
    const {deleteYieldByZone} = useYieldByZone()
    const {adjustedTableHeigthScroll} = useContext(StylesContext)

    const [form_yield_by_zone] = useForm()
    const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
    const [dataTable, setDataTable] = useState<DataType[]>([])
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [rowSelect, setRowSelect] = useState<DataType | undefined>()
    const [openForm, setOpenform] = useState<boolean>(false)


    useEffect(()=>{
        setLoadingTableData(true)
        if(yieldByZone){
            rowData() 
            setLoadingTableData(false)
        }
    },[yieldByZone])  
    

    const rowData = (data? : YieldByZoneProps[] ) =>{
        let filterData: DataType[]= []
        let list: YieldByZoneProps[]  = data ? data : yieldByZone
      
        if(list && list){
          list.map((data, index)=>{            
            filterData.push({
                index: index.toString(),
                id: data.id.toString(),
                hybrid_code: data.hybrid.code,
                hybrid_id: data.hybrid.id.toString(),
                bag_per_hectare: data.bag_per_hectare.toString(),
                bag_per_hectare_late: data.bag_per_hectare_late?.toString() || '',
                zone_code: data.zone.code,
                zone_id: data.zone.id.toString()         
            })
          })           
        }       
        setDataTable(filterData)
    }


    const callback = () =>{
        setOpenform(false)
        setLoadingTableData(false) 
        setShowConfirmDelete(false)    
        form_yield_by_zone.resetFields()   
        setRowSelect(undefined) 
    }

    const handleRenderer = (text: string, data: DataType) =>{
        return (
            <div className='zone_action_action_render'>
                <EditOutlined 
                    className='icons'
                    onClick={()=>{
                        setOpenform(true)
                        setRowSelect(data)
                    }}
                />
                <DeleteOutlined 
                    className='icons'
                    style={{ marginLeft:'30px'}}
                    onClick={()=>{
                        setRowSelect(data)
                        setShowConfirmDelete(true)
                    }}
                />
            </div>  
        )      
    }

    const ModalConfirmDelete = () => {
        return(
            <Modal
                title="Eliminar"
                open={showConfirmDelete}
                onOk={()=>{
                    if(rowSelect && rowSelect.id){
                        deleteYieldByZone(callback, Number(rowSelect.id))
                    }}}
                onCancel={()=>setShowConfirmDelete(false)}
                okText="Eliminar"
                cancelText="Cancelar"
            >
                <p>¿Estás seguro de que desea eliminar el rendimiento del híbrido {rowSelect?.hybrid_code} en la zona {rowSelect?.zone_code}</p>
            </Modal>
        )
    }
  
    const filters = (type: 'zone' | 'hybrid') => {
        let textAndValue: { text: string, value: string }[] = [];
        let uniqueValues = new Set();
    
        yieldByZone?.forEach((item) => {
            let code = ''
            switch(type){
                case 'zone':
                    code = item.zone.code
                    break;
                case 'hybrid':
                    code = item.hybrid.code;
                    break;
                default:
                    code ='';
                    break               
            }
           
            if (code && !uniqueValues.has(code)) {
                uniqueValues.add(code);
                textAndValue.push({
                    text: code,
                    value: code
                });
            }

        });
        return textAndValue;
      }
    const columns: ColumnsType<DataType> = [
        {
            title: 'Zona',
            dataIndex: 'zone_code',
            key: '1',
            width: 100,
            filters: filters('zone'),
            onFilter: (value, record) => typeof value === 'string' && record.zone_code == value,
            filterSearch: true,
        },
        {
            title: 'Híbrido',
            dataIndex: 'hybrid_code',
            key: '2',
            ellipsis: true,
            width: 100,
            filters: filters('hybrid'),
            onFilter: (value, record) => typeof value === 'string' && record.hybrid_code == value,
            filterSearch: true,
        },
        {
            title: 'Rendimiento',
            dataIndex: 'bag_per_hectare',
            key: '3',
            width: 100
        },
        {
            title: 'Rendimiento tardío',
            dataIndex: 'bag_per_hectare_late',
            key: '4',
            width: 100
        },
        {
            title: 'Acciones',
            dataIndex: 'id',
            render:  handleRenderer,
            key: 'id',
            width: 50,
            ellipsis: true,            
        },      
    ];

    return( 
        <>
            <ModalConfirmDelete/>
            {openForm && <YieldByZoneForm                
                form_yield_by_zone={form_yield_by_zone}
                rowSelect = {rowSelect}
                callback={callback}
                openForm={openForm}
            />  }    
            <div className='yieldByZone_section_container'>                                  
                <Row
                    className='bodyContainer_title'
                >
                    <h2>Rendimiento por zonas</h2>                
                </Row>
                <Row 
                    className='header_row'
                >
                    <Button 
                        type='primary'
                        onClick={()=>{
                            setOpenform(true)
                        }}
                    >
                        Asignar rendimiento
                    </Button>
                 
                </Row>
                <Row 
                    className='bodyContent_container_table'
                >
                    {
                    loadingTableData
                    ? <SpinerHorizontal/> 
                    : <Table 
                        style={{
                            width: '95%',
                        }}
                            columns={columns} 
                            dataSource={dataTable}  
                            pagination={false}
                            scroll={{ y: adjustedTableHeigthScroll }}
                        />    
                    } 
                </Row>             
            </div>
        </>
        
        
)};

export default YieldByZoneSection