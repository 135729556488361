import { axiosBackEndInstance } from '../Axios';
import { END_POINT_LOTS_BY_PRODUCTION_BAG_DISTRIBUTION } from '../Axios/ENDPOINTS';
import { notification } from 'antd';
import {  useGetBagOrder } from './useGetBagOrder';
import { useErrorHandlingReturn400 } from '../hooks/useErrorHandlingReturn400';

export interface BagDistributionValues{
  bag_order?: number,
  zone?: number,
  assigned_percentage?: number
}

export const useBagDistribution= () => {


  const {errorHandling} = useErrorHandlingReturn400()
  const {mutate} = useGetBagOrder()
    
  const createBagDistribution = async (
    callback: Function,
    values: BagDistributionValues
  ): Promise<any | undefined> => {      
    try {
      const  data = await axiosBackEndInstance.post(
        END_POINT_LOTS_BY_PRODUCTION_BAG_DISTRIBUTION, values
      )
      const status =  errorHandling(data).request.status      
      if(status === 201 || status === 200){
        mutate()
        callback()
        notification.success({
          message: 'Éxito', 
          description:'Asinación creada con éxito', 
          placement: 'topRight'
        })
      }
    } catch (newError: any) {
      callback()      
      let status = errorHandling(newError).request.status
      if(status == 400){
        if(newError?.response?.data?.non_field_errors[0].includes('460')){
          notification.warning({
            message: 'Ya existe una asignación creada para ese híbrido en esa zona', 
            placement: 'topRight'
          })
        }else if(newError?.response?.data?.non_field_errors[0].includes('462')){
          notification.error({
            message: 'Error',
            description: 'La suma de los porcentajes asignados para una orden exceden el 100%, asigne otro valor', 
            placement: 'topRight'
          })
        }else{
          notification.error({
            message: 'Error',
            description: 'Hubo un error, comuníquese con el administrador', 
            placement: 'topRight'
          })
        }
      }
      console.log('status: ' + status + ' ' + newError);
    }
  };

  const editBagDistribution = async (
    callback: Function,
    id: number,
    values: BagDistributionValues                           
  ): Promise<string | void> => {            
    try {
      const data  = await axiosBackEndInstance.patch(
        `${END_POINT_LOTS_BY_PRODUCTION_BAG_DISTRIBUTION}/${id}`,values
      );
      callback()   
      if(data.status === 200 || data.status === 201){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Asignación editada con éxito', 
          placement: 'topRight'
        })
        
        return 'ok'
      }    
    } catch (newError: any) {      
      let status = errorHandling(newError).request.status
      if(status == 400){
        console.log(newError)
        if(newError?.response?.data?.non_field_errors[0].includes('462')){
          notification.error({
            message: 'Error',
            description: 'La suma de los porcentajes asignados para una orden exceden el 100%, asigne otro valor', 
            placement: 'topRight'
          })
        }      
        
      }
      console.log('status: ' + status + ' ' + newError);
    }
  };

  const deleteBagDistribution = async (
    callback: Function,
    id: number                            
  ): Promise<void> => {        
    try {
      const data  = await axiosBackEndInstance.delete(
        `${END_POINT_LOTS_BY_PRODUCTION_BAG_DISTRIBUTION}/${id}`, 
      );
      callback()   
      if(data.status === 200){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Asignación eliminada con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      callback()
      let status = errorHandling(newError).request.status
      console.log(newError);
      console.log(status)
    }
  };

  return {
    createBagDistribution,
    deleteBagDistribution,
    editBagDistribution
  };
};
