import React, { useCallback, useContext, useEffect, useState } from 'react';
import './styles.scss'
import { Button, Checkbox, Col, Form,  Row, Select, Table, Tooltip, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import  { ColumnsType } from 'antd/es/table';
import { useGetZone } from '../../../Services/useGetZone';
import {
    ExclamationCircleOutlined,
    LoadingOutlined,
    FilterOutlined,
    LineChartOutlined
  } from '@ant-design/icons';
import { useGetCampaigns } from '../../../Services/useGetCampaigns';
import { DayOfWork,  LotByProductionResults,  SowPlanificationViewProps, useSowPlanificationView } from '../../../Services/useSowPlanificationView';
import moment from 'moment';
import { PlanificationContext } from '../../../Contexts/UserContext/PlanificationContext';
import manual_icon from '../../../assets/icons/sow_planification_view/manual_work.svg';
import manual_work_red from '../../../assets/icons/sow_planification_view/manual_work_red.svg';
import puller from '../../../assets/icons/sow_planification_view/puller.svg'
import cutter from '../../../assets/icons/sow_planification_view/cutter.svg'
import Filters from '../../Filters';
import BoxFilter from './BoxFilters';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import ResourceGraph from './ResourceGraph';
import EditableCell from '../../EditingCell/EditingCell';
import { DataType, Resource, ResourcesProps } from './types';
import { ModifyHumanResourcesForm } from '../../Forms/ModifyHumanResources';
import { FormPopup } from '../../Forms/FormPopup/FormPopup';
import { useGetFarms } from '../../../Services/useGetFarms';
import { useGetLot } from '../../../Services/useGetLot';
import { useGetHybrid } from '../../../Services/useGetHybrid';
import { END_POINT_LOTS_BY_PRODUCTION_SOW_PLANIFICATION_VIEW_EXPORT } from '../../../Axios/ENDPOINTS';
import { useDownloadExcel } from '../../../Services/useDownloadExcel';
import { UserContext } from '../../../Contexts/UserContext/UsersContext';

const defaultCheckedList = ['Puller', 'Cutter', 'Manual work', 'gdus'];

const SowPlanificationViewSection: React.FC = () => {

    const { getSowPlanificationView } = useSowPlanificationView()
    const { data: zones } = useGetZone()
    const { data: campaign } = useGetCampaigns()
    const { data: farms } = useGetFarms()
    const { data: lots } = useGetLot()
    const { data: hybrids } = useGetHybrid()    
    const {downloadEXCEL} = useDownloadExcel()
    const { Option } = Select;
    const {
        zoneAndCampaignSelected,
        setZoneAndCampaignSelected,
        dataTable,
        setDataTable,         
        setResources,
        resources
    } = useContext(PlanificationContext)

    const [ form_sow_planification_view ] = useForm()
    const [ loadingTableData, setLoadingTableData ] = useState<boolean>(false)
    const [ tableStructure, setTableStructure ]  = useState<ColumnsType<DataType> >()
    const [ doRefresh, setDoRefresh] = useState(false)
    const [ checkedList, setCheckedList] = useState<CheckboxValueType[]>(defaultCheckedList);
    const [ loadingFiltersBtn, setLoadingFilterBtn] = useState(false)
    const [ graphicData, setGraphicData ] = useState<DayOfWork[]>()
    const [ showForm, setShowForm ] = useState<boolean>(false)
    const [ rowSelect, setRowSelect ] = useState<DataType | undefined>()
    const [ messageApi, contextHolder ] = message.useMessage();
    const [ dataApi, setDataApi ] = useState<SowPlanificationViewProps>()
    const [ adjustedWidth, setAdjustedWidth ] = useState(0);
    const [ adjustedTableHeigthScroll, setAdjustedTableHeigthScroll ] = useState(window.innerHeight > 900 ? window.innerHeight *0.65 : window.innerHeight *0.60);
    const [loadingExcel, setLoadingExcel] = useState<boolean>(false) 

    const {userData} = useContext(UserContext)
    
    const femaleColor = 'red';//'#fca699';
    const male1Colors = 'yellow';//'#edd75a';
    const male2Color = 'rgb(180, 180, 180)'//'#ffad08';
    const male3Color = 'orange'//'#ffad08';
    const tentativeDateShowColor = '#f8f8ec';
    let totalsBackgroundColor = '#e7e7e7';      

    useEffect(() => {
        console.log('useEffect 1')
        const handleResize = () => {
          const screenHeigth = window.innerHeight > 900 ? window.innerHeight *0.65 : window.innerHeight *0.60;
          const screenWidth = (window.innerWidth - 296) *0.95

          setAdjustedTableHeigthScroll(screenHeigth);
          setAdjustedWidth(screenWidth)
        };
    
        handleResize();
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(()=>{
        console.log('useEffect 2')
        if(zoneAndCampaignSelected && doRefresh){
            let zoneKeys: {
                key: string,
            }[] =[]  
          
            zoneAndCampaignSelected.zone.forEach((item)=>{
                zoneKeys.push({
                    key: item.toString()
                })
            })             

            let values = {
                zone: zoneKeys,
                campaign:{
                    key: zoneAndCampaignSelected.campaign
                },
                is_full_grower: zoneAndCampaignSelected.is_full_grower == 1 ? true  : false
            }

            handleShearch(values, true, true)
            setDoRefresh(false)
        }
    },[doRefresh])   

    const filter = useCallback((objet: any[])=>{
        console.log('filter')
        let textAndValue: { text: string, value: string }[] = [];        
        let uniqueValues = new Set();

        objet?.forEach((item) => {
            let code = item.code   
            if (code && !uniqueValues.has(code)) {
                uniqueValues.add(code);
                textAndValue.push({
                    text: code,
                    value: code
                });
            }
        });
        return textAndValue;
    },[])

    const floweringData =  useCallback((text: string, record: DataType) =>{
        console.log('floweringData')
        let iconError: {txt: string, color: string, marginLeft: string}[]=  []

        if(record.error){
            switch(record.error){
                case '1':                
                    iconError.push({
                        txt: 'No se encontraron gdu para la fecha ' +  ' ' +  `${record.errorDate}`,  
                        color: 'red',
                        marginLeft: '4px'
                    })
                    break;
                case '2':
                    iconError.push({
                        txt: 'No se poseen datos de siembra ni de tentativa de siembra',
                        color: 'red',
                        marginLeft: '4px'
                    })
                    break;
                case '3':
                    iconError.push({
                        txt: 'No se poseen datos de floración para la hembra, por favor ingrese los valores en: Datos de Siembra => Floración de paraesntales.',
                        color: 'orange',
                        marginLeft: '0px'
                    })
                    break;
            }
          
        }       

        return(
            <div className={record.farm_code == 'TOTAL RECURSOS' ? 'cell_backround_color': ''}>
                { text !== 'undefined' && text}
                {
                    iconError.map((data)=>
                        <Tooltip
                            title= {data.txt}
                            placement='right'
                        >
                            <ExclamationCircleOutlined style={{
                                color: data.color,
                                cursor: 'pointer',
                                marginLeft: data.marginLeft
                            }}/>
                        </Tooltip>
                    )
                }                              
            </div>
        )
    },[])
    

    const columns: ColumnsType<DataType>  = [
        {
            title: 'Campo',
            dataIndex: 'farm_code',
            key: '0',
            width: 110,
            fixed: 'left',
            className: 'sizeCell',
            render: (text, record) => <div className={record.farm_code == 'TOTAL RECURSOS' ? 'cell_backround_color': ''}>{text}</div>,
            filters: filter(farms),
            onFilter: (value, record) => typeof value === 'string' && record.farm_code.includes(value),
            filterSearch: true,
        },
        {
            title: 'Lote',
            dataIndex: 'code',
            key: '1',
            width: 100,
            fixed: 'left',
            className: 'sizeCell',
            render: (text, record) => <div className={record.farm_code == 'TOTAL RECURSOS' ? 'cell_backround_color': ''}>{text}</div>,
            filters: filter(lots),
            onFilter: (value, record) => typeof value === 'string' && record.code.includes(value),
            filterSearch: true,
        },
        {
            title: 'Superficie',
            dataIndex: 'hectares',
            key: '2',
            fixed: 'left',
            width: 80,
            className: 'sizeCell',
            render: (text, record) => <div className={record.farm_code == 'TOTAL RECURSOS' ? 'cell_backround_color': ''}>{text}</div>
        },
        {
            title: 'FS H',
            dataIndex: 'fs_h',
            key: '3',
            width: 100,
            fixed: 'left',
            className: 'sizeCell_FS_h',
            render: (text, record)=> record.sow_date !== 'null'
                ? 
                    <Tooltip
                        title='Fecha de siembra'
                    >
                        <div style={{color:'green', fontWeight: '700'}}>{record.sow_date}</div>
                    </Tooltip>
                   
                :   userData && userData.is_staff 
                    ? <EditableCell
                        record={record}
                        mutateTable={mutateTable}
                        /> 
                    : <div style={{height: '18.84px', padding: '0px', margin:'0px', background: record.dateCellColor}}>
                        {record.tentative_sow_date}
                    </div>           
        },
        {
            title: 'Híbrido',
            dataIndex: 'hybrid_code',
            key: '4',
            fixed: 'left',
            width: 110,
            className: 'sizeCell',
            render: (text, record) => <div className={ record.farm_code == 'TOTAL RECURSOS' ? 'cell_backround_color': ''}>{text}</div>,
            filters: filter(hybrids),
            onFilter: (value, record) => typeof value === 'string' && record.hybrid_code.includes(value),
            filterSearch: true,
        },
        {
            title: `Siembra`,
            dataIndex: `sow`,
            key: '5',
            children:[
                {
                    title: (
                        <div style={{ backgroundColor: `${femaleColor}` }}>
                            Hembra
                        </div>
                    ),
                    dataIndex: 'female_sow_gdus',
                    key: '6',
                    width: 80,
                    ellipsis: true,
                    className: 'sizeCell',
                    render: (text, record) => <div className={record.farm_code == 'TOTAL RECURSOS' ? 'cell_backround_color': ''}>{text}</div>
                },
                {
                    title:  (
                        <div style={{ backgroundColor: `${male1Colors}` }}>
                            Macho 1
                        </div>
                    ),
                    dataIndex: 'male_1_sow_gdus',
                    key: '7',
                    width: 80,
                    ellipsis: true,
                    className: 'sizeCell',
                    render: (text, record) => <div className={record.farm_code == 'TOTAL RECURSOS' ? 'cell_backround_color': ''}>{text}</div>
                },
                {
                    title: (
                        <div style={{ backgroundColor: `${male2Color}` }}>
                            Macho 2
                        </div>
                    ),
                    dataIndex: 'male_2_sow_gdus',
                    key: '8',
                    width: 80,
                    ellipsis: true,
                    className: 'sizeCell',
                    render: (text, record) => <div className={record.farm_code == 'TOTAL RECURSOS' ? 'cell_backround_color': ''}>{text}</div>
                },
                {
                    title: (
                        <div style={{ backgroundColor: `${male3Color}` }}>
                            Macho 3
                        </div>
                    ),
                    dataIndex: 'male_3_sow_gdus',
                    key: '8',
                    width: 80,
                    ellipsis: true,
                    className: 'sizeCell',
                    render: (text, record) => <div className={record.farm_code == 'TOTAL RECURSOS' ? 'cell_backround_color': ''}>{text}</div>
                },
                {
                    title:  (
                        <div style={{ backgroundColor: '#00ffff' }}>
                            Floración
                        </div>
                    ),
                    dataIndex: 'flowering',
                    key: '9',
                    width: 80,
                    ellipsis: true,
                    className: 'sizeCell',
                    render: floweringData,
                },
            ]
        },
    ];

    const mutateTable = async (value:{
        campaign: string,
        zone: number[],
        first_date: string,
        last_date: string,
    }) => {
        console.log('mutateTable')
        if (value) {
            console.log(zoneAndCampaignSelected)
            const newData = await getSowPlanificationView(value.campaign, value.zone, zoneAndCampaignSelected?.is_full_grower ? zoneAndCampaignSelected?.is_full_grower : 0  , callback);
            
            if(newData){
                if(newData.first_date < value.first_date || newData.last_date > value.last_date ){
                    setDoRefresh(true)
                }else{
                    rowData(newData)
                }
            }
        }
    }

    const rowData = (list : SowPlanificationViewProps, first_date?: string, last_date?: string) =>{
        console.log('useEffect 2')
        setDataApi(list)
        interface ModifiedDataType extends DataType {
            [key: string]: string;
        }
        getDatesBetweenDates(list.first_date, list.last_date, list.lots_by_production_restults);

        let filterData: ModifiedDataType[]= []

        list.lots_by_production_restults.map((data, index)=>{
            let info: DataType =   {
                index: index.toString(),
                id: data.id.toString(),
                first_date: list.first_date,
                last_date: list.last_date,
                code: data.lot.code, //lote
                farm_code: data.lot.farm.code,
                hectares: data.lot.hectares.toString(),
                fs_h:  '',
                dateCellColor:  tentativeDateShowColor,
                hybrid_code: data.hybrid.code,
                hybrid_id: data.hybrid.id.toString(),
                campaign_id: data.campaign.id.toString(),
                campaign_code: data.campaign.code,
                tentative_sow_date:data.tentative_sow_date !== null ? data.tentative_sow_date : 'Sin datos',
                sow_date: data.sow_date !== null ? data.sow_date : 'null', //FS H por defecto
                harvest_date: data.harvest_date !== null ? data.harvest_date : 'null' ,
                detasseled_date: data.detasseled_date !== null ? data.detasseled_date : 'null' ,
                female_sow_gdus: data.female_sow_gdus !== null ? data.female_sow_gdus.toString() : '',
                male_1_sow_gdus: data.male_1_sow_gdus !== null ? data.male_1_sow_gdus.toString() : '',
                male_2_sow_gdus: data.male_2_sow_gdus !== null ? data.male_2_sow_gdus.toString() : '',
                male_3_sow_gdus: data.male_3_sow_gdus !== null ? data.male_3_sow_gdus.toString() : '',
                flowering: data.flowering_gdus ? data.flowering_gdus.toString() : 'undefined', // hybrid.gdu_to_flower,
                gdusCellColor: typeof data.days === "object" && "error" in data.days && data.days.error == 3 ? 'rgb(230, 230, 230)' : 'white'

            }
            if(typeof data.days === "object" && "error" in data.days ){
                info['days'] = 'noData'
                info['error'] = data.days.error.toString() 
                if( "date" in data.days && data.days.date != undefined){
                    info['errorDate'] = data.days.date
                }             
            }else if( typeof  data.days=== 'string' && data.days.includes('CODE')){
                info['days'] = 'noData' 
            }else if (Array.isArray(data.days) && data.days.every((item) => typeof item === 'object' && item !== null)){
                
                let newResources: { [key: string]: Resource[] } = {};
                data.days.forEach(day => {
                    const dateParts = day.date.split('-'); // Divide la fecha en partes usando el guión como separador
                    const dayOfMonth = dateParts[2]; // Obtiene el día del mes
                    const month = dateParts[1]; // Obtiene el mes
                    const propertyName = `${dayOfMonth}/${month}`
                    info[propertyName] = (checkedList.includes('gdus') && day.accumulated_gdus ) ? `${day.accumulated_gdus.toFixed()}`: '';
                    if( day.female_sow ){info[`${propertyName}_female_sow`] = 'true'};
                    if( day.male_1_sow ){info[`${propertyName}_male_1_sow`] = 'true'};
                    if( day.male_2_sow ){info[`${propertyName}_male_2_sow`] = 'true'}; 
                    if( day.male_3_sow ){info[`${propertyName}_male_3_sow`] = 'true'}; 
                    if( day.flowering_day ){info[`${propertyName}_flowering_day`] = 'true'};
                    if( day.puller ){info[`${propertyName}_puller`] = 'true' };
                    if( day.cutter ){info[`${propertyName}_cutter`] = 'true'};
                    if( day.set_manualy ){info[`${propertyName}_set_manualy`] = 'true'};
                    if( day.resources ){ info[`${propertyName}_resources`] = `${day.resources}`};

                    //hago un objeto con las fechas y la cantidad de recursos para cada producción, así las puedo cargar por defecto en el formulario
                    if (day.resources) {
                        if (!newResources[data.id]) {
                            newResources[data.id] = [];
                        }
                        newResources[data.id].push({
                            date: day.date,
                            quantity: day.resources
                        });
                    }
                })

                //Si se modificaron los recursos, cargo los nuevos recursos asignados
                setResources((prevResources: ResourcesProps) => {
                    const updatedResources = { ...prevResources };
                    for (const key in newResources) {
                        if (newResources.hasOwnProperty(key)) {
                            if (!updatedResources[key]) {
                                updatedResources[key] = [];
                            }
                            updatedResources[key] = [...newResources[key]];
                        }
                    }
                    return updatedResources;
                });
            }
            filterData.push(info)
        })

        let totalRow: DataType = {
            index: ' ',
            id: '',
            first_date: '',
            last_date: '',
            code: ' ',
            farm_code: 'TOTAL RECURSOS',
            hectares: ' ',
            fs_h: ' ',
            dateCellColor: totalsBackgroundColor,
            hybrid_code: '',
            hybrid_id: '',
            campaign_id: '',
            campaign_code: '',
            tentative_sow_date: '',
            sow_date: '',
            harvest_date: '',
            detasseled_date: '',
            female_sow_gdus: '',
            male_1_sow_gdus: '',
            male_2_sow_gdus: '',            
            male_3_sow_gdus: '',
            flowering: '',
            gdusCellColor: '',
        }
        list.days_of_manual_work && Object.values(list.days_of_manual_work).forEach((day)=>{
            if(day.resources !== 0){
                const dateParts = day.date.split('-'); // Divide la fecha en partes usando el guión como separador
                const dayOfMonth = dateParts[2]; // Obtiene el día del mes
                const month = dateParts[1]; // Obtiene el mes
                const propertyName = `${dayOfMonth}/${month}`;
                totalRow[propertyName] = checkedList.includes('Manual work') ? day.resources.toString() : ''
            }
        })

        filterData.push(totalRow)
        if(filterData.length > 0 ){
            let data = Object.entries(list.days_of_manual_work)
            .map(([key, value]) => value) // Convertir a array de valores
            .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()); // Ordenar por fecha
        
            setGraphicData(data);
        
            setGraphicData(data);
            setGraphicData(data)
            setDataTable(filterData)
            setLoadingTableData(false)
            setLoadingFilterBtn(false)
        }else{
            setLoadingTableData(false)
            setLoadingFilterBtn(false)
        }
    }

    const callback = () =>{
       setLoadingTableData(false)
    }

    const handleShearch = async (values: {
        zone:  {
            label?: string,
            value?: string,
            key: string,
        }[],
        campaign:{
            label?: string,
            value?: string,
            key: string,
        }
        is_full_grower: boolean
    },
    loading?: boolean,
    loadingFilters?: boolean
    ) =>{
        let zonesKeys: number[] = [];

        !loading && setLoadingTableData(true)
        loadingFilters && setLoadingFilterBtn(true)
        
        let is_full = values.is_full_grower  ? 1 : 0
        values.zone.forEach(zone => {
            zonesKeys.push(Number(zone.key));
        });

        const data = await getSowPlanificationView(values.campaign.key, zonesKeys, is_full, callback)        

        if(data){
            rowData(data)
            setZoneAndCampaignSelected({
                zone:  zonesKeys,
                campaign: values.campaign.key,
                first_date: data.first_date,
                last_date: data.last_date,
                is_full_grower: values.is_full_grower ? 1 : 0
            })
        }
    }

    const getDatesBetweenDates = (startDate: string, endDate: string, projects: LotByProductionResults[]) => {
        console.log('getDatesBetweenDates')
        const columnsDates: ColumnsType<DataType>  = []
        let finishDate = new Date(endDate)
        let currentDate = new Date(startDate);
        finishDate.setDate(finishDate.getDate() + 1);
        while (currentDate <= finishDate) {
            currentDate.setDate(currentDate.getDate() + 1);
            let propertyName = moment(currentDate).format('DD/MM');
            let date =  moment(currentDate).format('YYYY-MM-DD');
            let cellWidth = calculateWidth(projects, date)
            
            columnsDates.push({
                title: propertyName,
                dataIndex: propertyName, 
                width: cellWidth,
                render: (text, record) => {                    
                    let colorResouceIcon = record[`${propertyName}_set_manualy`] === 'true' ? manual_work_red : manual_icon
                    let putColor = setBacgroundColors(record, propertyName )

                    let icons_cutter = record[`${propertyName}_cutter`] === 'true' ?  <img  style={{height: '20px'}} src= {cutter}/> : ''
                    let icons_puller = record[`${propertyName}_puller`] === 'true' ?  <img  style={{height: '17px'}} src= {puller}/> : ''
                    let icons_manual = record[`${propertyName}_resources`] ? <img  style={{height: '20px'}}src= {colorResouceIcon}/> : ''
                    let gduFieldsValue = ()=>{
                        let value:string = text// text ? text : '0'
                        if(!text && checkedList.includes('gdus') && record.farm_code !== 'TOTAL RECURSOS'){
                            value = '0'
                        }
                        return value
                    }
                    let txtColor = setTxtColor(record, propertyName, text)                  

                    return <div
                        style={{
                            background: checkedList.includes('gdus') || checkedList.includes('Manual work') ? putColor : 'white',
                            color: `${txtColor}`,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height:'19.84px',
                            fontWeight: record.farm_code == 'TOTAL RECURSOS' ? 'bold' : 'normal'
                        }}
                    >
                        {gduFieldsValue()}
                        {checkedList.includes('Cutter') && icons_cutter }
                        {checkedList.includes('Puller') && icons_puller}
                        {checkedList.includes('Manual work') && 
                            <ModifyHumanResourcesForm
                            rowSelect_id={record.id}
                                icons_manual={icons_manual}
                                quantity={record[`${propertyName}_resources`]}
                                mutateTable={mutateTable}
                            />                     
                        }
                    </div>;
                  },
                className: 'sizeCell'
            })
        }
        columns.push({
            title: `Fechas`,
            dataIndex: `fechas`,
            children: columnsDates
        })
        setTableStructure(columns)
    };

    
    const calculateWidth = ( projects: LotByProductionResults[], date: string) =>{
        console.log('calculateWidth')
        //This function calculates the cell width based on the amount of data (gdus, icons)
        let count = 0
        let cellWidth = 60
        projects.map((project) => {
            if (Array.isArray(project.days)) {
                project.days.forEach((day) => {
                if(day.date === date){
                    const propertiesToCheck: Array<'resources' | 'puller' | 'cutter'> = ['resources', 'puller', 'cutter'];
                    propertiesToCheck.forEach((property) => {
                        if (day[property]) {
                        count += 1;
                        }
                    }); 
                }                                 
                });
            }  
        });               
        switch(count){
            case 1:
                cellWidth = 70
                break;
            case 2:
                cellWidth = 100
                break;
            case 3:
                cellWidth = 110
        }   
        return cellWidth
    }

    
    const setBacgroundColors = useCallback((record: DataType, propertyName: string)=>{
        let backgroundColors: string[] = []
         let putColor: string = 'transparent'

        if (record[`${propertyName}_female_sow`] === 'true' && checkedList.includes('gdus')) {
            backgroundColors.push(`${femaleColor}`) //red
        }
        if (record[`${propertyName}_male_1_sow`] === 'true' && checkedList.includes('gdus')) {
            backgroundColors.push(`${male1Colors}`) //yellow
        }
        if (record[`${propertyName}_male_2_sow`] === 'true' && checkedList.includes('gdus') ) {
            backgroundColors.push(`${male2Color}`)
        }
        if (record[`${propertyName}_male_3_sow`] === 'true' && checkedList.includes('gdus') ) {
            backgroundColors.push(`${male3Color}`)
        }
        if (record[`${propertyName}_flowering_day`] === 'true' && checkedList.includes('gdus')) {
            backgroundColors.push('#00ffff')
        }
        if(backgroundColors.length > 1){
            putColor = `linear-gradient(${backgroundColors[0]}, ${backgroundColors[1]})`
        }if(backgroundColors.length == 1){
            putColor =  `${backgroundColors[0]} `
        }
        if(record.farm_code == 'TOTAL RECURSOS' &&  checkedList.includes('Manual work')){
            putColor = totalsBackgroundColor
        }
        return(
            putColor
        )
    },[mutateTable] )

    let setTxtColor = useCallback((record: DataType, propertyName: string, text: string) =>{
        console.log('setTxtColor')
        let color: string= 'black'
        if(record.gdusCellColor === 'rgb(230, 230, 230)'){
            color = 'rgb(230, 230, 230)'
        }
        if(record[`${propertyName}_female_sow`] === 'true') {
            color= 'white'
        }if(record.farm_code == 'TOTAL RECURSOS' && text ){
            color = 'rgb(0, 179, 3)'
        }
        return color
    },[mutateTable])


    return(
        <>
            {contextHolder}
            {showForm && rowSelect && userData && userData.is_staff &&
                <FormPopup
                    rowSelect={rowSelect}
                    mutateTable={mutateTable}
                    showForm={showForm}
                    setShowForm={setShowForm}
                    messageApi={messageApi}
                />
            }
            <Filters
                components={
                    <BoxFilter
                        checkedList={checkedList}
                        setCheckedList={setCheckedList}
                        setDoRefresh={setDoRefresh}
                        doRefresh={doRefresh}
                        loadingFiltersBtn={loadingFiltersBtn}
                    />
                }
                icons={
                    <FilterOutlined
                        style={{
                            color:'#0072ce',
                            fontSize: '15px',
                        }}
                    />
                }
                tooltip= 'Filtros'
                difference='80px'
            />
            <Filters
                components={
                    <ResourceGraph
                        graphicData={graphicData}
                    />
                }
                icons={<LineChartOutlined
                        style={{
                            color:'#0072ce',
                            fontSize: '17px',
                        }}
                    />
                }
                tooltip='Gráfico de recursos'
                difference='140px'
                widthContainer={`${adjustedWidth}px`} 
                marginTopContainer={'76px'}

            />
            <div className='sow_planification_view_section sow_planification_view_section_container'>
                <Row
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        borderBottom: '1px solid #0072ce',
                    }}                    
                >
                    <h2>Planificación de siembra</h2>
                </Row>
                <Row
                     style={{
                        width: '100%',
                        padding:'20px 0px',
                        //margin: '10px 0px',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <Col
                        span={20}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            paddingLeft: '10px',
                        }}
                    >
                        <Form
                            form={form_sow_planification_view}
                            id='form_select_zone'
                            onFinish={handleShearch}
                            layout='inline'
                        >
                            <Form.Item
                                name='zone'
                                rules={[
                                    {
                                        required: true,
                                        message:'Este campo es obligatorio'
                                    }
                                ]}
                            >
                                <Select
                                    labelInValue
                                    mode="multiple"
                                    maxTagCount= 'responsive'
                                    style={{
                                        width: '150px'
                                    }}
                                    loading={!zones || zones.length === 0}
                                    placeholder={!zones || zones.length === 0 ? 'Cargando zonas...' : 'Zona'}      
                                >
                                    {zones && zones.map((item) => (
                                        <Option value={item.code} key={item.id}>
                                            {item.code}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='campaign'
                                rules={[
                                    {
                                        required: true,
                                        message:'Este campo es obligatorio'
                                    }
                                ]}
                            >
                                  <Select
                                    labelInValue
                                    loading={!campaign || campaign.length === 0}
                                    placeholder={!campaign || campaign.length === 0 ? 'Cargando campañas...' : 'Campaña'}   
                                    style={{
                                        width: '130px'
                                    }}
                                >
                                    {campaign && campaign.map((item) => (
                                        <Option value={item.code} key={item.id}>
                                            {item.code}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='is_full_grower'
                                valuePropName='checked'
                            >
                                <Checkbox>Es FullGrower</Checkbox>
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type='primary'
                                    htmlType='submit'
                                >
                                    Buscar
                                </Button>
                                <Tooltip
                                    title= 'Selecciona una zona y una campaña para visualizar la planificación de siembra. Haz click en check si deseas ver los campos que son Full Grower'
                                    placement='right'
                                >
                                    <ExclamationCircleOutlined style={{
                                        color:'orange',
                                        cursor: 'pointer',
                                        marginLeft: '10px'}}/>
                                </Tooltip>

                            </Form.Item>
                        </Form>
                        { loadingTableData &&
                            <>
                                <LoadingOutlined style={{color:'gray'}}/>
                                <p style={{margin: '5px', color:'gray'}}>cargando</p>
                            </>
                        }
                    </Col>
                    <Col
                        style={{marginLeft: '20px'}}
                    >
                        <Button 
                            disabled={loadingExcel || !zoneAndCampaignSelected}
                            loading={loadingExcel}
                            onClick={()=>{
                                setLoadingExcel(true)
                                let zonesSelected = zoneAndCampaignSelected?.zone
                                let nameList = zones?.filter((zone)=> zoneAndCampaignSelected?.zone.includes(zone.id))
                                let zoneNames = nameList.map(zone => zone.code).join('-');
                                let campaignAndFullGrower: string =`${zoneAndCampaignSelected?.campaign}/${zoneAndCampaignSelected?.is_full_grower}`
                                
                                if(zones && zoneAndCampaignSelected){
                                    downloadEXCEL(campaignAndFullGrower, setLoadingExcel, END_POINT_LOTS_BY_PRODUCTION_SOW_PLANIFICATION_VIEW_EXPORT, `Planificación de siembra - ${zoneNames}`, zonesSelected) 
                                }
                            }} 
                            type="primary" 
                        >
                            Exportar excel
                        </Button>                    
                    </Col>
                </Row>
                <Row
                    style={{
                        display:'flex',
                        width: '100%',
                        height: '80%',
                }}>  
                { !loadingTableData && tableStructure &&
                    <Table
                        bordered={true}
                        style={{
                            width:'100%',
                        }}
                        onRow={(record, rowIndex)=>{
                            return{
                                onClick:(event)=>{
                                    const target = event.target as HTMLElement;
                                    if (target.closest) {
                                      const cell = target.closest('td');
                                      if (cell) {
                                        const columnIndex = cell.cellIndex;
                                        const editableColumnIndices = [3];
                                        if (!editableColumnIndices.includes(columnIndex) && resources[record.id]) {
                                          setRowSelect(record);
                                          setShowForm(true);
                                        }
                                      }
                                    }                              
                                }
                            }
                        }}
                        columns={tableStructure}
                        dataSource={dataTable}
                        scroll={{ y: adjustedTableHeigthScroll, x: 1000}}     
                        pagination={false}               
                        // pagination={{
                        //     showSizeChanger: false,
                        //     pageSize: 20
                        // }}
                    />}
                </Row>                
            </div>
        </>
)};

export default SowPlanificationViewSection;

