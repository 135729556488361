import { useAuthSWR } from "./useAuthSWR";
import { KeyedMutator } from "swr";
import { END_POINT_GENERIC_LOT } from '../Axios/ENDPOINTS';

export interface LotProps {
  id: number,
  code: string,
  detailed_code: string,
  farm:{
    id: number,
    code: string,
    description: string, 
    is_full_grower_farm: boolean,
    city:{
      id: number,
      name: string,
      zip_code: string,
      province:{
        id: number,
        name: string
      }
      zone:{
        id: number,
        code: string,
        description: string
      }
    }   
    farm_owner:{
      id: number,
      code: string,
      name: string,
      last_name: string,
      phone: string
    }
  }, 
  hectares: number,
  contract_start_date: string,
  contract_end_date: string
}

export interface ResponseGetBatchs{
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: LotProps[]  
}

export const useGetLot = () => {
    
    const {data, error, mutate }:{
      data: LotProps[], 
      error: any,
      mutate: KeyedMutator<any>
    } = useAuthSWR([END_POINT_GENERIC_LOT])   
  
    return {
      data,
      mutate,
      error
    };
};