import {  useEffect } from 'react';
import  { KeyedMutator } from "swr";
import { useAuthSWR } from './useAuthSWR';
import { END_POINT_GENERIC_MATERIAL_TYPE } from '../Axios/ENDPOINTS';

export interface MaterialTypeProps {
  id: number,
  code: string
}

export interface ResponseGetMaterials{
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: MaterialTypeProps[]  
}

export const useGetMaterialsType = () => {
   
  const {data, error, mutate }:{
    data: MaterialTypeProps[], 
    error: any,
    mutate: KeyedMutator<any>
  } = useAuthSWR([END_POINT_GENERIC_MATERIAL_TYPE])

  
  
  useEffect(() => {
    if (!data && !error) {
      mutate();
    }
  }, [data, mutate]);

  return {
    data,
    mutate
  };
};