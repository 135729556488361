import React, { useEffect, useState } from 'react';
import './styles.scss'
import {  Button, Modal, Row, Table } from 'antd';
import {
    EditOutlined,
    DeleteOutlined
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { CityForm, LocalityFormFilters } from '../../Forms/CityForm';
import { CityProps, useGetCity } from '../../../Services/useGetCity';
import { useCity } from '../../../Services/useCity';


export interface DataType {
    index: number;
    id: number;
    name: string;
    zip_code: string;
    province_name: string;
    province_id: number;
    zone_id: number;
    zone_code: string;
    zone_description: string
}

const CitySection: React.FC = () => {

    const {data: dataApi} = useGetCity()    
    const {getFilteredCity, deleteCity } = useCity()

    const [form_city] = useForm()
    const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
    const [loadingFilters, setLoadingFilters] = useState(false)
    const [dataTable, setDataTable] = useState<DataType[]>([])
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [rowSelect, setRowSelect] = useState<{
        show: boolean,
        id: number | undefined,
        name: string | undefined,
        zip_code: string | undefined,
        province_name: string | undefined,
        province_id: number | undefined,
        zone_id: number | undefined,
        zone_code: string | undefined,
        zone_description: string | undefined,
        type: 'edit' | 'create' | 'delete' | undefined
    }>({
        show: false,
        id: undefined,
        name: undefined,
        zip_code: undefined,
        province_name: undefined,
        province_id: undefined,
        zone_id: undefined,
        zone_code: undefined,
        zone_description: undefined,
        type: undefined
    })

    useEffect(()=>{
        setLoadingTableData(true)
        if(dataApi){
            rowData() 
            setLoadingTableData(false)
        }
    },[dataApi])    

    const rowData = (data? : CityProps[]) =>{
        let filterData: DataType[]= []
        let list: CityProps[] = data ? data : dataApi
        let values: LocalityFormFilters = form_city.getFieldsValue() 
      
        if(list ){
          list.map((data, index)=>{            
            filterData.push({
                index: index,
                id: data.id,
                name: data.name,
                zip_code: data.zip_code,
                province_name: data.province.name,
                province_id: data.province.id,
                zone_id: data.zone.id,
                zone_code: data.zone.code,
                zone_description: data.zone.description
            })
          })             
        }       
        setDataTable(filterData)
    }

    const handleGetPage = async (page?: number, loadingTable?: boolean) => {
        let values = form_city.getFieldsValue() 
        let filters: LocalityFormFilters = values ? values : undefined 
        let pageNumber: number = page ? page : 1  
        loadingTable && setLoadingTableData(true)
        const data = await getFilteredCity( 
            callback,         
            filters.locality_code,
            filters.locality_postal_code,
            filters.locality.key,
            filters.province.key, 
            filters.zone.key,  
            pageNumber.toString()
        )
        
        if (data) {
            rowData(data);         
        }
    };

    const callback = () =>{
      setLoadingFilters(false)
      setLoadingTableData(false) 
      setShowConfirmDelete(false)      
  }

    const handleSeeAll =()=>{
        form_city.resetFields() 
        handleGetPage()        
    }

    const handleCancelForm = ()=>{
      form_city.resetFields() 
      setRowSelect({
        show: false,
        id: undefined,
        name: undefined,
        zip_code: undefined,
        province_name: undefined,
        province_id: undefined,
        zone_id: undefined,
        zone_code: undefined,
        zone_description: undefined,
        type: undefined
      })        
  }

  const handleRenderer = (text: string, data: DataType) =>{
    return (
        <div className='zone_action_action_render'>
            <EditOutlined 
                className='icons'
                onClick={()=>{
                    setRowSelect({
                        show: true,
                        id: data.id,
                        name: data.name,
                        zip_code: data.zip_code,
                        province_name: data.province_name,
                        province_id: data.province_id,
                        zone_id: data.zone_id,
                        zone_code: data.zone_code,
                        zone_description: data.zone_description,
                        type: 'edit'
                    })
                }}
            />
            <DeleteOutlined             
                className='icons'
                onClick={()=>{
                    setRowSelect({
                        show: false,
                        id: data.id,
                        name: data.name,
                        zip_code: data.zip_code,
                        province_name: data.province_name,
                        province_id: data.province_id,
                        zone_id: data.zone_id,
                        zone_code: data.zone_code,
                        zone_description: data.zone_description,
                        type: 'delete'
                    })
                    setShowConfirmDelete(true)
                }}
                style={{marginLeft:'30px'}} />
        </div>  
    )      
  }

  const ModalConfirmDelete = () => {
    return(
        <Modal
            title="Eliminar"
            open={showConfirmDelete}
            onOk={()=>{
                if(rowSelect && rowSelect.id){
                    deleteCity(callback, rowSelect.id)
                }}}
            onCancel={()=>setShowConfirmDelete(false)}
            okText="Eliminar"
            cancelText="Cancelar"
        >
            <p>¿Estás seguro de que desea eliminar {rowSelect?.name}?</p>
        </Modal>
    )
}
 
  
  const columns: ColumnsType<DataType> = [
      {
          title: 'Localidad',
          dataIndex: 'name',
          key: 'id',
          ellipsis: true,
      },
      {
          title: 'Código postal',
          dataIndex: 'zip_code',
          key: 'zip_code',
          ellipsis: true,
      },
      {
          title: 'Provincia',
          dataIndex: 'province_name',
          key: 'province_id',
          ellipsis: true,
      },
      {
          title: 'Zona',
          dataIndex: 'zone_code',
          key: 'zone_code',
          ellipsis: true,
      },
      {
          title: 'Decripción Zona',
          dataIndex: 'zone_description',
          key: 'zone_id',
          ellipsis: true,
      },
      {
        title: 'Acciones',
        dataIndex: 'id',
        render:  handleRenderer,
        key: 'id',
        width: 110,
        ellipsis: true,            
    }, 
    
  ];
  
  return( 
    <>
        <ModalConfirmDelete/>
        {rowSelect.show && <CityForm 
            form_city={form_city}
            rowSelect = {rowSelect}
                    handleCancelForm={handleCancelForm}
        /> }  
        <div className='zone_section_container'>                                  
            <Row
                className='bodyContainer_title'
            >
                <h2>Localidad</h2>                
            </Row>
            <Row 
                className='header_row'
            >              
                <Button
                type='primary'
                onClick={()=>{
                    setRowSelect({
                        show: true,
                        id: undefined,
                        name:undefined,
                        zip_code: undefined,
                        province_name: undefined,
                        province_id: undefined,
                        zone_id: undefined,
                        zone_code: undefined,
                        zone_description: undefined,
                        type: 'create'
                    })
                    }}
                >   Agregar localidad
                </Button>             
            </Row>           
            <Row 
                className='bodyContent_container_table'
            >
                {
                    loadingTableData
                    ? <SpinerHorizontal/> 
                    : <Table 
                        style={{
                            width: '80%',
                        }}
                        columns={columns} 
                        dataSource={dataTable}  
                        pagination={{
                            pageSize: 50,
                            showSizeChanger: false,
                        }}
                        scroll={{ y: 580 }}
                    />    
                } 
            </Row>             
        </div>
    </>        
  )};

export default CitySection;