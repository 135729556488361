import { axiosBackEndInstance } from '../Axios';
import { END_POINT_GENERIC_CITY } from '../Axios/ENDPOINTS';
import { notification } from 'antd';
import { useErrorHandling } from '../hooks/useErrorHandling';
import { CityProps, useGetCity } from './useGetCity';


export const useCity= () => {

    const {errorHandling} = useErrorHandling()
    const {mutate} = useGetCity()
     
    const getFilteredCity = async (
      callback: Function,
      locality_code: string,
      locality_id: number,
      locality_postal_code: number,
      province_id: number, 
      zone_id: number,
      pageValue?: string | undefined,        
      ): Promise<CityProps[] | undefined> => {
      
      var params = new URLSearchParams();
      pageValue && params.append("page", pageValue)
      province_id && params.append("province", province_id.toString())  
      zone_id && params.append("province", zone_id.toString())  
      locality_code && params.append("locality_code", locality_code.toString())  
      locality_id && params.append("locality_id", locality_id.toString())  
      locality_postal_code && params.append("locality_postal_code", locality_postal_code.toString()) 

      try {
        const {
          data,
        }: { data: any } = await axiosBackEndInstance.get(
          END_POINT_GENERIC_CITY,
          { 
            params
          }
        );
        callback()
        return data;
      } catch (newError) {
        console.log(newError);
        callback()
       // throw newError;
      }
    };

    const createCity = async (
      callback: Function,
      newData:{
        name: string,
        zip_code: string,
        province: number, 
        zone: number,          
      }
      
      ): Promise<any> => {      
    try {
      const  data = await axiosBackEndInstance.post(
        END_POINT_GENERIC_CITY, newData
      )
      callback()
      if(data.status === 200 || data.status === 201){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Localidad creada con éxito', 
          placement: 'topRight'
      })
      }
    } catch (newError) {
      let status = errorHandling(newError).request.status
      console.log('status: ' + status + ' ' + newError);
      callback()
    }
  };

  const editCity = async (
    callback: Function,
    id: number,
    values:{
      name: string,
      zip_code: string,
      province: number, 
      zone: number,  
    }
                            
  ): Promise<void> => {
            
    try {
      const data  = await axiosBackEndInstance.put(
        `${END_POINT_GENERIC_CITY}/${id}`,values
      );
      callback()   
      if(data.status === 200){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Localidad editada con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      let status = errorHandling(newError).request.status
      console.log('status: ' + status + ' ' + newError);
      callback()
    }
  };

  
  const deleteCity = async (
    callback: Function,
    id: number                            
  ): Promise<void> => {
      
    try {
      const data  = await axiosBackEndInstance.delete(
        `${END_POINT_GENERIC_CITY}/${id}`, 
      );
      callback()   
      if(data.status === 200){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Localidad eliminada con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      let status = errorHandling(newError).request.status
      console.log('status: ' + status + ' ' + newError);
      callback()
    }
  };

  return {
    getFilteredCity,
    createCity,
    editCity,
    deleteCity
  };
};
