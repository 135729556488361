import React, { useEffect, useState } from "react";
import './styles.scss';
import { Form, Input, Modal, Select } from "antd";
import { FormValues, MaterialsFloweringByGDUsFormProps } from "./types";
import { useMaterialFloweringByMonth } from "../../../Services/useMaterialFloweringByMonth";
import { useGetParental } from "../../../Services/useGetParental";
import { useGetZone } from "../../../Services/useGetZone";



export const MaterialsFloweringByGDUsForm: React.FC<MaterialsFloweringByGDUsFormProps> =({
    openForm,
    form_Material_flowering_by_month,
    rowSelect,
    callback,
})=>{
   
    useEffect(()=>{
        form_Material_flowering_by_month.resetFields()
    },[])
    
    const {createMaterialFloweringbyMonth, editMaterialFloweringbyMonth} = useMaterialFloweringByMonth()
    const {data: materials} = useGetParental()
    const {data: zone} = useGetZone()
    const [loadingBtn, setLoadingBtn] = useState(false)
    const {Option} = Select

    let months = [
        { value: '1', label: 'Enero' },
        { value: '2', label: 'Febrero' },
        { value: '3', label: 'Marzo' },
        { value: '4', label: 'Abril' },
        { value: '5', label: 'Mayo' },
        { value: '6', label: 'Junio' },
        { value: '7', label: 'Julio' },
        { value: '8', label: 'Agosto' },
        { value: '9', label: 'Septiembre' },
        { value: '10', label: 'Octubre' },
        { value: '11', label: 'Noviembre' },
        { value: '12', label: 'Diciembre' }
      ];
 
    const handleCancel =()=>{     
        callback() 
        setLoadingBtn(false)
    }

    const handleSave = async(values: FormValues)=>{
        let newValues={
            month: values.month,
            material: values.material.key,
            zone: values.zone?.key || undefined,
            gdus_until_beginning_of_flowering: values.gdus_until_beginning_of_flowering,
            gdus_until_50_percent_of_flowering: values.gdus_until_50_percent_of_flowering,
            gdus_until_beginning_of_pollen: values.gdus_until_beginning_of_pollen,
            gdus_until_50_percent_of_pollen: values.gdus_until_50_percent_of_pollen,
        }
        if(rowSelect){
            await editMaterialFloweringbyMonth(handleCancel, rowSelect.id, newValues)
        }else{
            await createMaterialFloweringbyMonth(handleCancel, newValues)
        }   
    }    

    return(
        <div>
            <Modal
                title={rowSelect ? 'Editar GDUs de Híbrido' : 'Agregar GDUS de híbrido'}
                open={openForm}
                onCancel={handleCancel}  
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={() => form_Material_flowering_by_month.submit()}
            >
                <Form
                    form={form_Material_flowering_by_month}
                    id='form_Material_flowering_by_month'
                    onFinish={handleSave}
                    layout="vertical"                    
                >
                    <Form.Item
                        label='Mes'
                        name='month'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        initialValue={rowSelect?.month}
                    >
                        <Select
                            style={{ width: 120 }}
                            options={months}
                            placeholder='Mes'
                        />
                    </Form.Item>
                    <Form.Item
                        label='Zona'
                        name='zone'
                        initialValue={{
                            value: rowSelect?.zone_id,
                            label: rowSelect?.zone_code,
                            key: rowSelect?.zone_id
                        } || undefined}
                    >
                        <Select                            
                            labelInValue                            
                            placeholder='Zona'
                        >
                            {zone && zone.map((item) => (
                                <Option value={item.code} key={item.id}>
                                {item.code}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label='Material'
                        name='material'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        initialValue={{
                            value: rowSelect?.material_code,
                            label: rowSelect?.material_code,
                            key: rowSelect?.material_id
                        }}
                    >
                        <Select                            
                            labelInValue                            
                            placeholder='Material'
                            showSearch
                        >
                            {materials && materials.map((item) => (
                                <Option value={item.code} key={item.id}>
                                {item.code}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label='GDUs hasta el principio de floración'
                        name='gdus_until_beginning_of_flowering'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        initialValue={rowSelect?.gdus_until_beginning_of_flowering}
                    >
                        <Input
                            type="number"
                        />
                    </Form.Item>
                    <Form.Item
                        label='Al 50% de floración'
                        name='gdus_until_50_percent_of_flowering'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        initialValue={rowSelect?.gdus_until_50_percent_of_flowering}
                    >
                        <Input
                            type="number"
                        />
                    </Form.Item>
                    <Form.Item
                        label='GDUs hasta el principio de polen'
                        name='gdus_until_beginning_of_pollen'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        initialValue={rowSelect?.gdus_until_beginning_of_pollen}
                    >
                        <Input
                            type="number"
                        />
                    </Form.Item>
                    <Form.Item
                        label='Al 50% del polen'
                        name='gdus_until_50_percent_of_pollen'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        initialValue={rowSelect?.gdus_until_50_percent_of_pollen}
                    >
                        <Input
                            type="number"
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default MaterialsFloweringByGDUsForm;