import { axiosBackEndInstance } from '../Axios';
import { END_POINT_GENERIC_DETASSELED_EQUIPMENT } from '../Axios/ENDPOINTS';
import { notification } from 'antd';
import { useErrorHandling } from '../hooks/useErrorHandling';
import { DetasseledEquipment, useGetDetasseledEquipment } from './useGetDetasseledEquipment';

export const useDetasseledEquipment= () => {

    const {errorHandling} = useErrorHandling()
    const { mutate } = useGetDetasseledEquipment()
     
    const getFilteredDetasseledEquipment = async (
      callback: Function,
      id: number,
      detasseling_equipment_type_code: string,
      ha_of_detasseling_per_hour: number,
      pageValue?: string | undefined,        
      ): Promise<DetasseledEquipment[] | undefined> => {
      
      var params = new URLSearchParams();

      pageValue && params.append("page", pageValue)
      detasseling_equipment_type_code && params.append("detasseling_equipment_type_code", detasseling_equipment_type_code)
      ha_of_detasseling_per_hour && params.append("ha_of_detasseling_per_hour", ha_of_detasseling_per_hour.toString())

      try {
        const {
          data,
        }: { data: any } = await axiosBackEndInstance.get(
          END_POINT_GENERIC_DETASSELED_EQUIPMENT,
          { 
            params
          }
        );
        callback()
        return data;
      } catch (newError) {
        console.log(newError);
        callback()
       // throw newError;
      }
    };

    const createDetasseledEquipment = async (
      callback: Function,
      newData:{
        code: string,
        hectares_of_detasseling_per_hour: number
      }
      
      ): Promise<any> => {      
    try {
      const  data = await axiosBackEndInstance.post(
        END_POINT_GENERIC_DETASSELED_EQUIPMENT, newData
      )
      callback()
      if(data.status === 200 || data.status === 201){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Equipamiento creado con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      callback()
      let status = errorHandling(newError).request.status
      console.log('status: ' + status + ' ' + newError);
    }
  };

  const editDetasseledEquipment = async (
    callback: Function,
    id: number,
    values:{
      code: string,
      hectares_of_detasseling_per_hour: number
    }
                            
  ): Promise<void> => {

    try {
      const data  = await axiosBackEndInstance.put(
        `${END_POINT_GENERIC_DETASSELED_EQUIPMENT}/${id}`,values
      );
      callback()   
      if(data.status === 200 || data.status === 201){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Equipamiento editado con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      callback()
      let status = errorHandling(newError).request.status
      console.log('status: ' + status + ' ' + newError);
    }
  };

  
  const deleteDetasseledEquipment = async (
    callback: Function,
    id: number                            
  ): Promise<void> => {
      
    try {
      const data  = await axiosBackEndInstance.delete(
        `${END_POINT_GENERIC_DETASSELED_EQUIPMENT}/${id}`, 
      );
      callback()   
      if(data.status === 200){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Equipamiento eliminado con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      let status = errorHandling(newError).request.status
      console.log('status: ' + status + ' ' + newError);      
      callback()
    }
  };

  return {
    getFilteredDetasseledEquipment,
    createDetasseledEquipment,
    editDetasseledEquipment,
    deleteDetasseledEquipment
  };
};
