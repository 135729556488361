import React, {
  createContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate  } from "react-router-dom";
import { useIntl } from 'react-intl';
import { useLocalStorageState } from '../../hooks/useLocalStorageState';
import { ProfileProps } from '../../Services/useGetProfile';
import { axiosBackEndInstance } from '../../Axios';
import { END_POINT_ACCOUNT_PROFILE } from '../../Axios/ENDPOINTS';

  class VoidContext implements UserContext {
    get changeAccessPageForm(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setChangeAccesPageForm(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get showAnimated(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setShowAnimated(): Function {
      throw new Error('Cannot consume context outside of provider');
    }
     get loading(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setLoading(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get showError(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setShowError(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get statusRequest():never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setStatusRequest(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get accesToken():never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setAccesToken(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    // get tokenConfirmRequest(): Function {
    //   throw new Error('Cannot consume context outside of provider');
    // }
    get unlog():never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setUnlog(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get editCalendar():never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setEditCalendar(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get collapsed():never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setCollapsed(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get userData(): never {
      throw new Error('Cannot consume context outside of provider');
    }
  }

  interface UserContext {
    changeAccessPageForm: String,
    setChangeAccesPageForm: Function,
    loading: boolean,
    setLoading: Function,
    showError: string,
    setShowError: Function,
    statusRequest: number,
    setStatusRequest: Function,
    accesToken : string | undefined
    setAccesToken: Function,
    unlog: boolean,
    setUnlog: Function
    editCalendar: boolean,
    setEditCalendar: Function,
    collapsed: boolean,
    setCollapsed: Function,
    userData: ProfileProps | undefined
  }

  export interface UserData {
    firstName: string,
    lastName: string,
    userId: number,
  }

  interface propsWithChildren {
    children: React.ReactNode
  }

  export interface accesToken{
    access: string,
    refresh:string
  }
  
  export const UserContext = createContext<UserContext>(new VoidContext());
  export const UserContextProvider= ({ children }: propsWithChildren): JSX.Element => {

    const intl = useIntl();
    const navigate = useNavigate();

    const [changeAccessPageForm, setChangeAccesPageForm]= useState<string>('login')
    const [loading, setLoading]= useState<boolean>(false)
    const [showError, setShowError]= useState<string>('')
    const [statusRequest, setStatusRequest] = useState<number>(0)
    const [unlog, setUnlog] = useState(true)
    const [collapsed, setCollapsed] = useState(true);
    const [accesToken, setAccesToken] = useLocalStorageState<string | undefined>(
      'produccionCorteva-Token', undefined
    );  
    const [editCalendar, setEditCalendar] = useState(false)
    const [userData, setUserData] = useState<ProfileProps>()

    useEffect(()=>{
      getProfile()
      if(!accesToken){
        setUserData(undefined)
      }
    },[accesToken])

    const getProfile = async (): Promise<void> => {
      try {
        const {
          data,
        }: { data: any } = await axiosBackEndInstance.get(
          END_POINT_ACCOUNT_PROFILE
        );
        if(data){
          setUserData(data);
        }
        
        console.log(data.data)
      } catch (newError) {
        console.log(newError)
      }
    };

    // const tokenConfirmRequest= async (token: string): Promise<any>=>{    
    //   try{
    //     const  data  = await axiosBackEndInstance.post(
    //       `${LOGIN}/${token}`);
    //     if(data){
    //       setAccesToken(data.data.access);
    //      // setRefreshToken(data.data.refresh)
    //     }
    //     return(data)
    //   }catch(error){
    //     return('error')
    //   }
    // }
 
    return (
      <UserContext.Provider value={{
        changeAccessPageForm, 
        setChangeAccesPageForm,
        showError,
        setShowError,
        loading,
        setLoading,
        setStatusRequest,
        statusRequest,
        accesToken, 
        setAccesToken,
        unlog, 
        setUnlog,
        editCalendar, 
        setEditCalendar,
        collapsed, 
        setCollapsed,
        userData
      }}>{children}</UserContext.Provider>
    );
  };



  