import { axiosBackEndInstance } from '../Axios';
import { URL } from '../Axios/ENDPOINTS';
import { notification } from 'antd';
import { useErrorHandling } from '../hooks/useErrorHandling';
import { ResponseGetUsers } from './useGetUsers';


export const useUsers= () => {

    const {errorHandling} = useErrorHandling()
     
    const getFilteredUsers = async (
      callback: Function,
      user_id: number,
      user_code: string,
      name: string,
      lastname: string, 
      userType: number,
      pageValue?: string | undefined,        
      ): Promise<ResponseGetUsers| undefined> => {
      
      var params = new URLSearchParams();
      pageValue && params.append("page", pageValue)
      user_id && params.append("user_id", user_id.toString())  
      user_code && params.append("user_code", user_code)  
      name && params.append("locality_code", name)  
      lastname && params.append("locality_id", lastname)  
      userType && params.append("locality_postal_code", userType.toString()) 

      try {
        const {
          data,
        }: { data: any } = await axiosBackEndInstance.get(
          URL,
          { 
            params
          }
        );
        callback()
        return data;
      } catch (newError) {
        console.log(newError);
        callback()
        // throw newError;
      }
    };

    const createUsers = async (
      callback: Function,
      newUser:{
        user_code: string,
        name: string,
        lastname: string, 
        userType:number,
        phone: string
      }
      
      ): Promise<any | undefined> => {      
    try {
      const  data = await axiosBackEndInstance.post(
        URL, newUser
      )
      const status =  errorHandling(data).request.status
      if(status === 200){
        callback()
        notification.success({
          message: 'Éxito', 
          description:'Provincia creada con éxito', 
          placement: 'topRight'
      })
      }
    } catch (newError) {
      console.log(newError);
      callback()
      //throw newError;
    }
  };

  return {
    getFilteredUsers,
    createUsers
  };
};
