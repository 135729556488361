import React, {  useContext, useEffect, useState } from 'react';
import './styles.scss'
import { Row, Table, Tooltip } from 'antd';
import {
    ExclamationCircleOutlined,
    FileTextOutlined,
  } from '@ant-design/icons';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import {  ResponseBagOrder, useGetBagOrder } from '../../../Services/useGetBagOrder';
import { BagDistributionContex } from '../../../Contexts/UserContext/BagDistributionContex';
import EditingCell from './EditingCell';
import { useGetYieldByZoneForDistribution } from '../../../Services/useGetYieldByZoneForDistribution';
import { useGetZoneForDistribution } from '../../../Services/useGetZoneForDistribution';
import { StylesContext } from '../../../Contexts/UserContext/StylesContext';

export interface DataType {
    index: number;
    id: number;
    hybrid_code: string;
    hybrid_id: string;
    total_allocated: string;
    total_planned_bags_percentage : string;
    total_allocated_has: string,
    campaign_code: string;
    campaign_id: string;
    brand_id: string;
    brand_code: string;
    female_code: string;
    female_id: string;
    quantity: number;
    female_availability: string
    female_coment: string,
    male_id: string,
    male_code: string,
    male_availability: string,
    male_coment: string,
    sterility_percentage: string,
    [key: string]: any;   
}

interface rowFixedProps {
    id: number,
    column: string | undefined,
    total:  number | undefined,
    text: string | undefined,
    zone: number | undefined
    zone_code: string | undefined
}

interface ResultItem {
    hasTotal: number;
    bsZnTotal: number;
  }
  
interface Result {
    [id: number]: ResultItem;
}


const BagDistribution: React.FC = () => {

    const {data: dataApi} = useGetBagOrder()   
    const {data: distribution}=useGetYieldByZoneForDistribution()     
    const {data: zonesForDistribution} = useGetZoneForDistribution()
    const {dataTable, setDataTable, tableStructure, setTableStructure} = useContext(BagDistributionContex)
    const {adjustedTableHeigthScroll} = useContext(StylesContext)

    const [checkedList, setCheckedList] = useState<number[]>([0,1,2,3,4,5,6,7,8,9,10]);
    const [changeX, setChangeX] = useState<number>(3300)
    const [doRefreshTotals, setDoRefreshTotals] = useState<boolean>(false)
    const [rowFixed, setRowFixed] = useState<rowFixedProps[]>(()=>{
        let children: rowFixedProps[] = []

        for (let i = 0; i < 11; i += 1) {
            children.push({
              id: i,
              column: i.toString(),
              total: undefined,
              text: i == 0 ? 'TOTALES' : '',
              zone: undefined,
              zone_code: undefined
            });
          }
        return(
            children
        )
    })
    const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
    const [totalsValues, setTotalValues] = useState<{
        assigned_total_quantity: number,
        total_quantity: number,
        diference: number,
        color_diference: string,
        total_planned_bags: number,
        volume_vs_planned_percentage: number
        color_V_vs_P: string
    } | undefined>()
    const numberFormatter = new Intl.NumberFormat('es-ES');

    useEffect(()=>{
        if(dataTable.length == 0){
            setLoadingTableData(true)
        }      
        if(dataApi && dataTable.length == 0 && distribution){
            addColumns()
            rowData()             
        }
        if(dataApi &&  dataTable.length > 0 && distribution){
            rowData()
        }
    },[dataApi, distribution])   

    useEffect(() => {

        setRowFixed(rowFixed);
    }, [doRefreshTotals]);

    const filterToZoneYield = (zone_id: number)=>{
        let textAndValue: { text: string, value: string }[] = [];
        let uniqueValues = new Set();

        dataApi.bag_orders.forEach((bag_orders)=>{            
            let yieldPerHybridPerZone = distribution.filter((x)=> x.hybrid.id === bag_orders.hybrid?.id )
            yieldPerHybridPerZone.map((item)=>{       
                if(item.zone.id == zone_id){
                    let code = item.bag_per_hectare.toString()
                    if (code && !uniqueValues.has(code)) {
                        uniqueValues.add(code);
                        textAndValue.push({
                            text: code,
                            value: code
                        });
                    }
                }                          
            })                              
        })

        textAndValue.sort((a, b) => Number(a.value) - Number(b.value));
        return textAndValue;      
    }

    const filterToZone = (zone_id: number) =>{
        let textAndValue: { text: string, value: string }[] = [];
        let uniqueValues = new Set();
        
        dataApi.bag_orders.forEach((bag_order)=>{    
            bag_order && bag_order.bag_distribution_set && bag_order.bag_distribution_set.forEach((bag_distribution)=>{
                let code = bag_distribution.zone.id == zone_id && Number(bag_distribution.assigned_percentage).toFixed()
                if( code && !uniqueValues.has(code)){
                    uniqueValues.add(code);
                    textAndValue.push({
                        text: code,
                        value: code
                    });
                }
            })
        })
        textAndValue.sort((a, b) => Number(a.value) - Number(b.value));
        return textAndValue;
    }

    const calculateTotals = (updateRow: rowFixedProps[]) => {
        const newTotals = updateRow.map((row) => {
            const total = dataTable.reduce((sum, item) => {
                return sum + (item[row.id] || 0);
            }, undefined);
            return { ...row, total };
        });
        setRowFixed(newTotals);
        setDoRefreshTotals(!doRefreshTotals)
    };

    const addColumns = () =>{
        let zonesColumns: ColumnsType<DataType> = [] as ColumnsType<DataType>;
        let requestZone: ColumnsType<DataType> = [] as ColumnsType<DataType>;
        let addZones: number[] = []
        let addDetail: number[] = []
        let addRowFixedZones: rowFixedProps[] =[] //la uso despúes para cargar los valores en la fila de totales       
        let addRowFixed: rowFixedProps[] =[]

        //genero las keys 
        const total_zones = zonesForDistribution.length
        let zonesKeys = columns.length //Le resto 1 porque los id de columns comienzan con 0
        let zonesDetailKeys = zonesKeys + total_zones 

        zonesForDistribution.map((zone)=>{               
            const background = zone.id % 2 === 0 ? '' : ''; //'#FAFAFA'
            const percentageKey = `assigned_percentage_${zone.id}`;
            const bag_per_hectare = `zoneId_${zone.id}_yield`;
            
            addZones.push(zonesKeys) //Para los filtros
            addDetail.push(zonesDetailKeys, zonesDetailKeys + 1, zonesDetailKeys + 2); //Para fila rowFixed

            addRowFixedZones.push({
                id: zonesKeys,
                text: '',
                total: undefined,
                zone: undefined,
                column: undefined,
                zone_code: undefined
            })

            const details = [
                { id: zonesDetailKeys, column: '%', text:'', total: undefined  },
                { id: zonesDetailKeys + 1, column: 'has',  text:'', total: undefined  },
                { id: zonesDetailKeys + 2, column: 'bs/zn', text:'', total: undefined  },
            ];        

            details.forEach(detail => addRowFixed.push({ ...detail, zone: zone.id, zone_code: zone.code }));                  

            requestZone.push({
                title: `${zone.code}`,
                rowScope:  'row',
                dataIndex: `zoneId_${zone.id}_yield` ,//rinde por hectáreas,
                key: zonesKeys,
                render: (text)=> <p style={{color: 'blue'}}>{text}</p>,
                width: zone.id == 30 ? 100 : 80,
                filters: filterToZoneYield(zone.id),
                onFilter: (value, record) => typeof value === 'string' && record[`zoneId_${zone.id}_yield`] == value,
                filterSearch: true,
            })

            zonesColumns.push({                
                title: `${zone.code}`,
                key: `zone_${zone.id}_id`,
                dataIndex: `zone_${zone.id}_id`,                
                children: [
                    {
                        title: '% Pedido ' ,
                        dataIndex: `assigned_percentage_${zone.id.toFixed()}`,  
                        key:  zonesDetailKeys,
                        width: 110,
                        render:(text, record)=><EditingCell 
                            record={record}
                            dataIndex={text}
                            callback={callback}
                            zoneId={zone.id}                            
                        />,
                       filters: filterToZone(zone.id),
                       onFilter: (value, record) => typeof value === 'string' && record[ `assigned_percentage_${zone.id}`] == value,
                       filterSearch: true,                     
                    },
                    {
                        title: 'has' ,
                        dataIndex: `has_${zone.id}`,
                        key:  zonesDetailKeys + 1,
                        width: 80,
                        render:(text, record)=>{
                            let has: number | undefined = record.quantity ? Number(record.quantity)*(record[percentageKey]/100)/record[bag_per_hectare] : undefined                    
                            return(
                                <>
                                    <div 
                                        style={{background}}>{record[`has_${zone.id}`]}
                                    </div>                                
                                </>                               
                            )                             
                        },                   
                    },
                    {
                        title: 'bs/zn' ,
                        dataIndex: `bs_zn_${zone.id}`,
                        key:  zonesDetailKeys + 2,
                        width: 80,
                        render:(text, record)=>{
                            let has: number | undefined = record.quantity ? Number(record.quantity)*(record[percentageKey]/100) : undefined
                            return(
                               <div style={{color:'green', fontWeight: 500}}>{record[`bs_zn_${zone.id}`]}</div> //Pedido de bolsa * %  
                            )                             
                        } 
                    },
                ]
            })
            zonesKeys = zonesKeys + 1
            zonesDetailKeys = zonesDetailKeys + 3   
        })
        const combinedColumns = [...columns, ...requestZone, ...zonesColumns];
        const allKeys = [...checkedList, ...addZones, ...addDetail]       
        const updateRows = [...rowFixed,...addRowFixedZones,  ...addRowFixed]
        combinedColumns.map((item) => ({
            ...item,
            hidden: !allKeys.includes(item.key as number), 
        }))      

        setCheckedList(allKeys)
        setTableStructure(combinedColumns);
       
        setRowFixed([...updateRows]); 
        calculateTotals(updateRows)
        
    }

    const rowData = () =>{
        let filterData: DataType[]= []
        let list: ResponseBagOrder =  dataApi
        const difference = list.total_planned_bags - list.total_quantity;
        let colorDif_VvsP = 'black';   
        if (difference < -4.6 || difference > 4.6) {
            colorDif_VvsP = 'red';
        } else if (difference >= -4.6 && difference <= 4.6) {
            colorDif_VvsP = 'green';
        }
        setTotalValues({
            assigned_total_quantity: list.assigned_total_quantity,
            total_quantity: list.total_quantity,           
            diference: difference,
            color_diference: colorDif_VvsP,
            total_planned_bags: list.total_planned_bags,
            volume_vs_planned_percentage: list.volume_vs_planned_percentage,
            color_V_vs_P: colorDif_VvsP
        });
        
        if(list){
          list.bag_orders.map((data, index)=>{  
            let yieldPerHybridPerZone = distribution.filter((x)=> x.hybrid.id === data?.hybrid?.id )
            
            let info: DataType =   {
                index: index,
                id: data.id,
                campaign_code: data?.campaign?.code || '',
                campaign_id: data?.campaign?.id.toString() || '',
                hybrid_code: data?.hybrid?.code || '',
                hybrid_id: data?.hybrid?.id.toString() || '',
                total_planned_bags_percentage : data?.total_planned_bags_percentage?.toString() || '', 
                total_allocated: data?.total_allocated_percentage?.toString() || '',                
                total_allocated_has: data?.total_allocated_has?.toString() || '',   
                brand_id: data?.hybrid?.brand?.id?.toString() || '',
                brand_code: data?.hybrid?.brand?.code || '',
                female_id: data?.hybrid?.female_material?.id.toString() || '',
                female_code: data?.hybrid?.female_material?.code || '',
                quantity: data?.quantity || 0,
                female_availability: Number(data?.female_availability).toFixed(),
                female_coment: data?.female_coment || '',
                male_code: data?.hybrid?.male_material?.code || '',
                male_id: data?.hybrid?.male_material?.id.toString() || ' ',
                male_availability: Number(data?.male_availability).toFixed(),
                male_coment: data?.male_coment || '',
                sterility_percentage: Number(data?.sterility_percentage).toFixed()           
            }   
            data?.bag_distribution_set?.forEach((item) => {
                //Formar los nombres de las propiedades con el id de zone
                let bs_zn = data.quantity && item.assigned_percentage && (data.quantity * Number(item.assigned_percentage)) / 100
                let has = (item.yield_by_zone &&  (item.yield_by_zone  != 0) ? bs_zn && (bs_zn / item.yield_by_zone).toFixed() : undefined)
                let zoneId = item.zone.id;                
    
                info[`zone_${zoneId}_id`] = item.zone.id;
                info[`zone_${zoneId}_code`] = item.zone.code;
                info[`zone_${zoneId}_description`] = item.zone.description;
                info[`bag_distribution_${zoneId}`] = item.id;
                info[`bag_order_id_${zoneId}`] = item.bag_order;
                info[`assigned_percentage_${zoneId}`] = Number(item.assigned_percentage).toFixed();
                info[`assigned_percentage_${zoneId}_recordId_${data.id}`] = item.id                     
                info[`has_${zoneId}`] =  (has &&  (item.yield_by_zone  !== 0) )? has: undefined
                info[`bs_zn_${zoneId}`] =  (bs_zn  && (item.yield_by_zone !== 0)) ? bs_zn.toFixed()  : undefined   
            });  

            yieldPerHybridPerZone.map((item)=>{                
                info[`zoneId_${item.zone.id}_yield`]= item.bag_per_hectare
            })                    
            filterData.push(info)
          })       
        }     
        setLoadingTableData(false)
        setDataTable(filterData)
    }


    const callback = () =>{
        setLoadingTableData(false)  
    }

    const filters = (type: 'hybrid' | 'brand' | 'female' | 'male' | 'male_availability' | '%_sterility' | 'quantity' ) => {
        let textAndValue: { text: string, value: string }[] = [];
        let uniqueValues = new Set();
    
        dataApi?.bag_orders?.forEach((item) => {
            let code
            switch(type){
                case 'hybrid':
                    code = item?.hybrid?.code 
                    break;
                case 'brand':
                    code = item?.hybrid?.brand.code
                    break;
                case 'female':
                    code = item?.hybrid?.female_material?.code
                    break
                case 'male':
                    code = item?.hybrid?.male_material?.code
                    break;
                case 'male_availability':
                    code = Number(item?.male_availability).toFixed()
                    break
                case '%_sterility':
                    code = Number(item?.sterility_percentage).toFixed()
                    break
                case 'quantity':
                    code = item?.quantity?.toString()
                    break   
            }
           
            if (code && !uniqueValues.has(code)) {
                uniqueValues.add(code);
                textAndValue.push({
                    text: code,
                    value: code
                });
            }
        });
        return textAndValue;
    }
  
    const columns: ColumnsType<DataType> = [
        {
            title: 'Híbrido',
            dataIndex: 'hybrid_code',
            render: (text, record)=> <p>{record.hybrid_code}</p>,
            key: 0,
            width: 150,
            fixed: 'left',
            filters: filters('hybrid'),
            onFilter: (value, record) => typeof value === 'string' && record.hybrid_code.includes(value),
            filterSearch: true,           
            rowScope:  'row', 
            sorter: (a, b) => a.hybrid_code.localeCompare(b.hybrid_code),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '% Asignado',
            dataIndex: 'total_allocated',
            render: (text, record)=> <p style={{color:'orange',fontWeight: 500 }}>{record.total_allocated}</p>,
            key: 1,
            width: 120,
            fixed: 'left',
            sorter: (a, b) => Number(a.total_allocated) - Number(b.total_allocated),     
            
        },
        {
            title: '% Plan',
            dataIndex: 'total_planned_bags_percentage',
            render: (text, record)=> <p style={{fontWeight: 500 }}>{Number(record.total_planned_bags_percentage).toFixed()}</p>,
            key: 2,
            width: 120,
            fixed: 'left',           
            rowScope:  'row', 
            sorter: (a, b) => Number(Number(a.total_planned_bags_percentage).toFixed()) - Number(Number(b.total_planned_bags_percentage).toFixed()), 
        },
        {
            title: 'Superficie Teórica',
            dataIndex: 'total_allocated_has',
            render: (text, record)=> <p style={{ fontWeight: 500 }}>{record.total_allocated_has}</p>,
            key: 3,
            width: 120,
            sorter: (a, b) => Number(a.total_allocated_has) - Number(b.total_allocated_has), 
        },
        {
            title: 'Marca',
            dataIndex: 'brand_id',
            render: (text, record)=> <p>{record.brand_code}</p>,
            key: 4,
            width: 150,
            filters: filters('brand'),
            onFilter: (value, record) => typeof value === 'string' && record.brand_code.includes(value),
            filterSearch: true,           
            rowScope:  'row',
            sorter: (a, b) => a.brand_id.localeCompare(b.brand_id),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Hembra',
            dataIndex: 'female_code',
            render: (text, record)=> <p>{record.female_code}</p>,
            key: 5,
            width: 150,
            filters: filters('female'),
            onFilter: (value, record) => typeof value === 'string' && record.female_code.includes(value),
            filterSearch: true,
            sorter: (a, b) => a.female_code.localeCompare(b.female_code),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '% D. hembra',
            dataIndex: 'female_availability',
            key: 6,
            width: 150,
            //ellipsis: true,
            render: (text, record)=> 
                
                <Tooltip
                title={record.female_coment}
            >
                <div style={{cursor: 'pointer', display: 'flex', flexDirection: 'row'}}>
                    <p style={{marginRight: '15px'}}>{text}% </p>
                    {record.female_coment && <FileTextOutlined  style={{color:'red', fontSize: '15px'}}/>}
                </div>               
            </Tooltip>,              
            rowScope:  'row',  
            sorter: (a, b) => Number(a.female_availability) - Number(b.female_availability),        
        },
        {
            title: 'Macho',
            dataIndex: 'male_code',
            render: (text, record)=> <p>{record.male_code}</p>,
            key: 7,
            width: 150,
            filters: filters('male'),
            onFilter: (value, record) => typeof value === 'string' && record.male_code.includes(value),
            filterSearch: true,
            sorter: (a, b) => a.male_code.localeCompare(b.male_code),
            sortDirections: ['ascend', 'descend'],
        }, 
        {
            title: '% Disponibilidad macho',
            dataIndex: 'male_availability',
            key: 8,
            width: 170,            
            //ellipsis: true,
            render: (text, record)=> 
                <Tooltip
                   
                    title={record.male_coment}
                >
                    <div style={{cursor: 'pointer', display: 'flex', flexDirection: 'row'}}>
                        <p style={{marginRight: '15px'}}>{text}% </p>
                        {record.male_coment && <FileTextOutlined  style={{color:'red', fontSize: '15px'}}/>}
                    </div>
                   
                </Tooltip>,
            filters: filters('male_availability'),
            onFilter: (value, record) => typeof value === 'string' && record.male_availability == value,
            filterSearch: true,           
            rowScope:  'row', 
            sorter: (a, b) => Number(a.male_availability) - Number(b.male_availability), 
        },
        {
            title: '% De esterilidad (S/F)' ,
            dataIndex: 'sterility_percentage',
            key: 9,
            width: 160,
            render: (text, record)=> <p>{text}% </p>,
            filters: filters('%_sterility'),
            onFilter: (value, record) => typeof value === 'string' && record.sterility_percentage == value,
            filterSearch: true,
            sorter: (a, b) => Number(a.sterility_percentage) - Number(b.sterility_percentage),
        }, 
        {
            title: 'Pedido de bolsas' ,
            dataIndex: 'quantity',
            key: 10,
            width: 150,
            filters: filters('quantity'),
            onFilter: (value, record) => typeof value === 'string' && record.quantity.toString() == value,
            filterSearch: true,           
            rowScope:  'row', 
            sorter: (a, b) => Number(a.quantity) - Number(b.quantity),
        },    
    ];

    //<---ChecksBox---
   
    const [doRefresh, setDoRefresh] = useState(false)
    const [ loadingFiltersBtn, setLoadingFilterBtn] = useState(false)
     
 
    //<---ChecksBox---

    function sumFieldsById(data: readonly DataType[]): Result {
        const result: Result = {};
      
        data.forEach(item => {
            zonesForDistribution.map((zone)=>{
                const id = zone.id;
                if (!result[id]) {
                  result[id] = { hasTotal: 0, bsZnTotal: 0 };
                }
            
                const hasKey = `has_${id}`;
                const bsZnKey = `bs_zn_${id}`;
          
                if (hasKey in item) {
                  result[id].hasTotal += Number(item[hasKey]) || 0;
                }
                if (bsZnKey in item) {
                  result[id].bsZnTotal += Number(item[bsZnKey]) || 0;
                }
            })
          
        });
      
        return result;
      }
      
  
  
    return( 
        <>   
            {/* {
               
                <Filters
                    components={
                        <BoxFilter
                            checkedList={checkedList}
                            setCheckedList={setCheckedList}
                            setDoRefresh={setDoRefresh}
                            doRefresh={doRefresh} 
                            setChangeX={setChangeX}
                        />
                    }
                    icons={<FilterOutlined style={{  
                        color:'#0072ce',
                        fontSize: '15px'
                    }}/>}
                    tooltip='Ocultar columnas'
                    difference='80px'
                />  
            } */}
             
            <div className='bag_distribution_section bag_distribution_section_container'>  
                                                
                <Row
                    className='bodyContainer_title'
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <div
                        style={{
                            display: 'flex'                                
                        }}
                    >
                        <h2>Distribución de Bolsas</h2>
                        <Tooltip
                            title= "Haz click en la celda '% has' que deseas modificar y luego presiona enter para guardar los cambios"
                            placement='right'
                        >
                        <ExclamationCircleOutlined style={{
                            color:'orange',
                            cursor: 'pointer',
                            marginLeft: '10px'}}/>
                    </Tooltip>
                    </div>
                    <div
                        className='bag_distribution_section_col'
                    >                       
                       <h3 className='h3_title'>Volume Plan:</h3>  
                        <h3 className='h3' >{ totalsValues && numberFormatter.format(totalsValues?.total_quantity)}</h3>
                        <h3 className='h3_title'>Total Sol:</h3>  
                        <h3 className='h3'>{ totalsValues && numberFormatter.format(totalsValues?.assigned_total_quantity)}</h3>
                        <h3 className='h3_title'>Total Plan:</h3>  
                        <h3 className='h3'>{ totalsValues && numberFormatter.format(totalsValues?.total_planned_bags)}</h3>
                        <h3 className='h3_title'>VP vs Tot Plan.:</h3> 
                        <h3 style={{
                            color: totalsValues?.color_V_vs_P
                        }}>{ totalsValues && numberFormatter.format(totalsValues?.volume_vs_planned_percentage)}</h3>
                        <h3 className='h3_title'>Diferencia: </h3>      
                        {totalsValues &&
                            <h3 
                                style={{
                                    color: totalsValues.color_diference,
                                    marginRight: '20px'
                                }}
                            > {numberFormatter.format(totalsValues.diference)}
                            </h3>
                        }        
                    </div>                                      
                </Row>
                <Row 
                    className='bodyContent_container_table'
                    style={{
                        marginTop: '5px'
                    }}
                >
                    {loadingTableData
                        ? <SpinerHorizontal/> 
                        : <Table 
                            summary={(pageData) => {
                         
                                const sumsById = sumFieldsById(pageData);
                                
                                if(sumsById){
                                    rowFixed.map((cell)=>{
                                        if(cell.zone && cell.column == 'has'){
                                            cell.total = sumsById[cell.zone].hasTotal
                                        }
                                        if(cell.zone && cell.column == 'bs/zn'){
                                            cell.total = sumsById[cell.zone].bsZnTotal
                                        }
                                    })
                                }
                                return(
                                
                                <Table.Summary fixed='top'>
                                <Table.Summary.Row>
                                {rowFixed.map((item)=>
                                    <Table.Summary.Cell key={item.id} index={item.id}>
                                        {item.total !== undefined ? item.total.toLocaleString('es-ES') : item.text}
                                    </Table.Summary.Cell>
                                    )}
                                </Table.Summary.Row>
                                </Table.Summary>
                            )}}
                            size='small'
                            bordered={true}
                            style={{
                                width:'100%',
                            }}
                                columns={tableStructure} 
                                dataSource={dataTable}                                
                                scroll={{ y: adjustedTableHeigthScroll, x: changeX }} //x era 500
                                pagination={false}
                            />    
                    } 
                </Row>             
            </div>
        </>                
)};

export default BagDistribution;

