import React, {
    createContext,
    useState,
  } from 'react';
import { DataType } from '../../Components/Sections/BagDistribution';
import  { ColumnsType } from 'antd/es/table';

  class VoidContext implements BagDistributionContex {

    get dataTable(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setDataTable(): never {
      throw new Error('Cannot consume context outside of provider');
    }    
    get tableStructure(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setTableStructure(): never {
      throw new Error('Cannot consume context outside of provider');
    }    
  }

  interface BagDistributionContex {
    dataTable: DataType[] ,
    setDataTable: Function,
    tableStructure:ColumnsType<DataType> | undefined,
    setTableStructure: Function
  }

  interface propsWithChildren {
    children: React.ReactNode
  }

  export const BagDistributionContex = createContext<BagDistributionContex>(new VoidContext());
  export const BagDistributionContexProvider= ({ children }: propsWithChildren): JSX.Element => {

    const [ dataTable, setDataTable ] = useState<DataType[]>([])
    const [ tableStructure, setTableStructure ]  = useState<ColumnsType<DataType> >()

 
    return (
      <BagDistributionContex.Provider value={{
        dataTable,
        setDataTable,
        tableStructure, 
        setTableStructure 
      }}>{children}</BagDistributionContex.Provider>
    );
  };



  