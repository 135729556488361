import { axiosBackEndInstance } from '../Axios';
import { END_POINT_LOTS_BY_PRODUCTION } from '../Axios/ENDPOINTS';
import { notification } from 'antd';
import { LotByProductionProps, useGetLotByProduction } from './useGetLotByProduction';
import { useVerifySession } from '../hooks/useVerifySession';

export const useLotByProduction= () => {

    const {errorHandling} = useVerifySession()
    const {mutate} = useGetLotByProduction()
   
    const getLotByProduction =  async(
      campaign: string,
      callback: Function
    ): Promise<LotByProductionProps[] | undefined> =>{
        
      try {
        const  data = await axiosBackEndInstance.get(
          `${END_POINT_LOTS_BY_PRODUCTION}/${campaign}`
        )       
        if(data){    
          return data.data
        }
      } catch (newError) {
        callback()
        const status =  await errorHandling(newError).request.status      
        console.log(newError);
      }
    }    
  

    const createLotByProduction = async (
      callback: Function,
      newData:{
        campaign: number;
        lot: number;
        hybrid: number;       
        female_sow_gdus: number;
        male_1_sow_gdus: number;
        male_2_sow_gdus: number;
        observations: string;
        tentative_sow_date: string | null;
        sow_date: string | null;
        harvest_date: string | null;
        detasseled_date: string | null;
        sow_relation?: number
      }
      
      ): Promise<LotByProductionProps | undefined> => {      
    try {
      const  data = await axiosBackEndInstance.post(
        END_POINT_LOTS_BY_PRODUCTION, newData
      )
      callback()   
      if(data.status === 200 || data.status === 201){      
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Lote por producción creado con éxito', 
          placement: 'topRight'
        })
        return data.data
      }    
    } catch (newError: any) {     
      let status = errorHandling(newError).request.status
      if(status == 400){
        const errorMessage =
        newError.response && newError.response.data
          ? JSON.stringify(newError.response.data)
          : 'Unknown Error';
        if(errorMessage.includes('CODE 463')){
          notification.warning({
            message: 'El lote ya existe en esta campaña', 
            placement: 'topRight'
          })
      }else{
        callback()
        notification.error({
            message: 'Error', 
            description:  'Hubo un error, por favor comuníquese con el administrador', 
            placement: 'topRight'
        })
      }
     
      }
      console.log('status: ' + status + ' ' + newError);
    }
  };

  const editLotByProduction = async (
    callback: Function,
    id: number,
    newData:{
      campaign: number;
      lot: number;
      hybrid: number;
      female_sow_gdus: number;
      male_1_sow_gdus: number;
      male_2_sow_gdus: number;
      observations: string;
      tentative_sow_date: string | null;
      sow_date: string | null;
      harvest_date: string | null;
      detasseled_date: string | null;
      sow_relation?: number
    }
                            
  ): Promise<LotByProductionProps | undefined> => {
      
    try {
      const data  = await axiosBackEndInstance.put(
        `${END_POINT_LOTS_BY_PRODUCTION}/${id}`,newData
      );
      callback()   
      if(data.status === 200 || data.status === 201){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Lote por producción editado con éxito', 
          placement: 'topRight'
        })
        return data.data
      }    
    } catch (newError) {
      callback()
      let status = errorHandling(newError).request.status
      console.log('status: ' + status + ' ' + newError);
    }
  };

  const editLotByProduction_patch = async (
    callback: Function,
    id: number,
    newData:{
      campaign?: number;
      lot?: number;
      hybrid?: number;
      female_sow_gdus?: number;
      male_1_sow_gdus?: number;
      male_2_sow_gdus?: number;
      observations?: string;
      tentative_sow_date?: string;
      sow_date?: string;
      harvest_date?: string;
      detasseled_date?: string;
    }
                            
  ): Promise<string | undefined> => {
      
    try {
      const data  = await axiosBackEndInstance.patch(
        `${END_POINT_LOTS_BY_PRODUCTION}/${id}`,newData
      );
      callback()   
      if(data.status === 200 || data.status === 201){
        return 'update'               
      }    
    } catch (newError) {
      callback()
      let status = errorHandling(newError).request.status
      console.log('status: ' + status + ' ' + newError);
    }
  };

  const deleteLotByProduction = async (
    callback: Function,
    id: number                            
  ): Promise<number | undefined> => {
      
    try {
      const data  = await axiosBackEndInstance.delete(
        `${END_POINT_LOTS_BY_PRODUCTION}/${id}`, 
      );
      callback()   
      if(data.status === 200){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Lote en producción eliminado con éxito', 
          placement: 'topRight'
        })
        return data.status
      }    
    } catch (newError) {
      let status = errorHandling(newError).request.status
      console.log('status: ' + status + ' ' + newError);
      callback()
    }
  };

  return {
    createLotByProduction,
    editLotByProduction,
    deleteLotByProduction,
    editLotByProduction_patch,
    getLotByProduction
  };
};
