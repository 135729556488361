import React, { useEffect, useState } from "react";
import './styles.scss';
import { Form, Input, Modal, Select } from "antd";
import { CityFormProps } from "./types";
import { useCity } from "../../../Services/useCity";
import { FieldsValues } from "../MaterialForm";
import { useGetProvince } from "../../../Services/useGetProvince";
import { useGetZone } from "../../../Services/useGetZone";

export interface FilterValues{
    value: string,
    id: string,
    key: number,
}

export interface LocalityFormFilters {
    locality_code: string,
    locality_postal_code: number,
    locality: FilterValues,
    province: FilterValues, 
    zone: FilterValues,
}

export const CityForm: React.FC<CityFormProps> =({
    form_city,
    rowSelect,
    handleCancelForm,
})=>{

    useEffect(()=>{
        form_city.resetFields()
    },[])
    
    const { createCity, editCity }= useCity()
    const { data: province } = useGetProvince()
    const { data: zone } = useGetZone()
    const { Option } = Select;
    const [loadingBtn, setLoadingBtn] = useState(false)

    const handleCancel =()=>{     
        handleCancelForm() 
        setLoadingBtn(false)
    }

    const handleSave = async(values: {
        name: string,
        zip_code: string,
        province: FieldsValues,
        zone: FieldsValues
    })=>{
        let newData = {
            name: values.name,
            zip_code: values.zip_code,
            province: values.province.key,
            zone: values.zone.key
        }       
        if(rowSelect.type === 'create'){
            await createCity(handleCancel, newData)
        }else if(rowSelect.type === 'edit' && rowSelect.id){
            await editCity(handleCancel, rowSelect.id, newData)
        }        
    }       

    return(
        <div>
            <Modal
                title={rowSelect.type === 'edit' ? 'Editar Localidad' : 'Nueva Localidad'}
                open={rowSelect.show}
                onCancel={handleCancel}  
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar"                 
                onOk={() => form_city.submit()}
            >
                <Form
                    form={form_city}
                    id='form_locality'
                    onFinish={handleSave}
                    layout="vertical"                    
                >
                    <Form.Item
                        label='Nomnbre de Localidad'
                        name='name'
                        initialValue={rowSelect?.name}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Input
                        defaultValue={rowSelect?.name}
                            placeholder="Nombre"
                        />
                    </Form.Item>                        
                    <Form.Item
                        label='Código postal'
                        name='zip_code'
                        initialValue={rowSelect?.zip_code}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Input
                            defaultValue={rowSelect?.zip_code}
                            placeholder="Código postal"
                        />
                    </Form.Item>       
                    <Form.Item
                        label='Provincia'
                        name='province'
                        initialValue={{
                            value: rowSelect?.province_name,
                            label: rowSelect?.province_name,
                            key: rowSelect?.province_id
                        }}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Select
                            defaultValue={{
                                value: rowSelect?.province_name,
                                label: rowSelect?.province_name,
                                key: rowSelect?.province_id
                            }}
                            labelInValue
                            loading={!province || province.length === 0}
                            placeholder={!province || province.length === 0 ? 'Cargando provincias...' : 'Seleciona un provincia'} 
                        >
                            {province && province.map((item) => (
                                <Option value={item.name} key={item.id} placeholder='Selecciona una provincia'>
                                {item.name}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item>   
                    <Form.Item
                        label='Zona'
                        name='zone'
                        initialValue={{
                            value: rowSelect?.zone_code,
                            label: rowSelect?.zone_description,
                            key: rowSelect?.zone_id
                        }}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Select
                            labelInValue
                            defaultValue={{
                                value: rowSelect?.zone_code,
                                label: rowSelect?.zone_description,
                                key: rowSelect?.zone_id
                            }}
                            loading={!province || province.length === 0}
                            placeholder={!province || province.length === 0 ? 'Cargando provincias...' : 'Seleciona un provincia'}
                        >
                            {zone && zone.map((item) => (
                                <Option value={item.code} key={item.id}>
                                {item.code}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item> 
                </Form>
            </Modal>
        </div>
    )
}

export default CityForm;