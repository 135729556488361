import React, { useContext, useEffect, useState } from 'react';
import './styles.scss'
import { Button, Modal, Row, Table } from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    CheckOutlined,
    CloseOutlined
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { MaterialProps,  useGetMaterials } from '../../../Services/useGetMaterials';
import { useMaterials } from '../../../Services/useMaterials';
import MaterialForm from '../../Forms/MaterialForm';
import { StylesContext } from '../../../Contexts/UserContext/StylesContext';


export interface DataType {
    index: number;
    id: number;
    code: string;
    gdus_to_flower: number,
    brand_id: number,
    brand_name: string,
    material_type_id: number ,
    material_type_code: string | undefined,
    female_material_id: number | undefined ,
    female_material_code: string | undefined,
    male_material_id: number | undefined,
    male_material_code: string | undefined,
    suggested_sow_relation_id: number | undefined,
    suggested_sow_relation_code: string | undefined,
    male_1_sow_gdus: number | undefined,
    male_2_sow_gdus: number | undefined,
    male_3_sow_gdus: number | undefined,
    is_sterile: string
}

const MaterialsSection: React.FC = () => {

    const {data: dataApi} = useGetMaterials()    
    const {deleteMaterial} = useMaterials()
    const {adjustedTableHeigthScroll} = useContext(StylesContext)

    const [form_material] = useForm()
    const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
    const [loadingFilters, setLoadingFilters] = useState(false)
    const [dataTable, setDataTable] = useState<DataType[]>([])
    const [rowSelect, setRowSelect] = useState<DataType | undefined>(undefined)
    const [openForm, setOpenForm] = useState<boolean>()
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)

    useEffect(()=>{
        setLoadingTableData(true)
        if(dataApi){
            rowData() 
            setLoadingTableData(false)
        }
    },[dataApi])   

    const rowData = (data? : MaterialProps[]) =>{
        let filterData: DataType[]= []
        let list: MaterialProps[] = data ? data : dataApi
      
        if(list){
          list.map((data, index)=>{            
            filterData.push({
                index: index,
                id: data.id,
                code: data.code,
                gdus_to_flower: data.gdus_to_flower,
                brand_id: data.brand.id,
                brand_name: data.brand.code,
                material_type_id: data.material_type.id ,
                material_type_code: data.material_type.code,
                female_material_code: data.female_material?.code,
                female_material_id: data.female_material?.id,
                male_material_code: data.male_material?.code,
                male_material_id: data.male_material?.id,
                suggested_sow_relation_code: data.suggested_sow_relation?.code,
                suggested_sow_relation_id: data.suggested_sow_relation?.id,
                male_1_sow_gdus: data?.male_1_sow_gdus,
                male_2_sow_gdus: data?.male_2_sow_gdus,
                male_3_sow_gdus: data?.male_3_sow_gdus,
                is_sterile: data?.is_sterile  ? 'true' : 'boolean'
            })
          })            
        }       
        setDataTable(filterData)
    }

    const callback = () =>{
        setLoadingFilters(false)
        setLoadingTableData(false)
        setShowConfirmDelete(false) 
        setOpenForm(false)    
    }
 

    const ModalConfirmDelete = () => {
        return(
            <Modal
                title="Eliminar"
                open={showConfirmDelete}
                onOk={()=>{
                    if(rowSelect && rowSelect.id){
                        deleteMaterial(callback, rowSelect.id)
                    }}}
                onCancel={()=>setShowConfirmDelete(false)}
                okText="Eliminar"
                cancelText="Cancelar"
            >
                <p>¿Estás seguro de que desea eliminar {rowSelect?.code}?</p>
            </Modal>
        )
    }

    const handleRenderer = (text: string, data: DataType) =>{
        return (
            <div className='zone_action_action_render'>
                <EditOutlined 
                    className='icons'
                    onClick={()=>{
                        setRowSelect(data)
                        setOpenForm(true)
                    }}
                />
                <DeleteOutlined 
                    onClick={()=>{
                        setRowSelect(data)
                        setShowConfirmDelete(true)
                    }}
                    style={{marginLeft:'30px'}}
                    className='icons'
                    />
            </div>  
        )      
    }

    const filters = (type: 'hybrid' ) => {
        let textAndValue: { text: string, value: string }[] = [];
        let uniqueValues = new Set();
    
        dataApi?.forEach((item) => {
            let code
            switch(type){
                case 'hybrid':
                    code = item?.code 
                    break;
                       
            }
           
            if (code && !uniqueValues.has(code)) {
                uniqueValues.add(code);
                textAndValue.push({
                    text: code,
                    value: code
                });
            }

        });
        return textAndValue;
    }
  
  
    const columns: ColumnsType<DataType> = [
        {
            title: 'Código de Material',
            dataIndex: 'code',
            render: (text, record)=> <p>{record.code}</p>,
            key: '1',
            width: 150,
            ellipsis: true,
            fixed: 'left',
            filters: filters('hybrid'),
            onFilter: (value, record) => typeof value === 'string' && record.code.includes(value),
            filterSearch: true,    
            rowScope:  'row',
        },
        {
            title: 'GDUs a floración',
            dataIndex: 'gdus_to_flower',
            render: (text)=> <p>{text}</p>,
            key: '2',
            width: 150,
            ellipsis: true,
        },
        {
            title: 'Marca',
            dataIndex: 'brand_name',
            key: '3',
            width: 110,
            ellipsis: true,
            rowScope:  'row',
        },
        {
            title: 'Tipo de material',
            dataIndex: 'material_type_code',
            key: '4',
            width: 150,
            ellipsis: true,
        },    
        {
            title: 'Hembra',
            dataIndex: 'female_material_code',
            key: '5',
            width: 150,
            ellipsis: true,
            rowScope:  'row',
        },       
        {
            title: 'Macho',
            dataIndex: 'male_material_code',
            key: '6',
            width: 150,
            ellipsis: true,
        }, 
        {
            title: 'Relación de siembra sugerida',
            dataIndex: 'suggested_sow_relation_code',
            key: '7',
            width: 200,
            rowScope:  'row',
        },   
        {
            title: 'Es esteril',
            dataIndex: 'is_sterile',
            render: (text)=><div>{text == 'true' ? <CheckOutlined style={{color: 'green'}} /> : <CloseOutlined style={{color: 'red'}} />}</div>,
            key: '8',
            width: 100,
        }, 
        {
            title: 'Gdus de siembra macho 1',
            dataIndex: 'male_1_sow_gdus',
            key: '9',
            width: 180,
            rowScope:  'row',
        },   
        {
            title: 'Gdus de siembra macho 2',
            dataIndex: 'male_2_sow_gdus',
            key: '10',
            width: 180,
        },   
        {
            title: 'Gdus de siembra macho 3',
            dataIndex: 'male_3_sow_gdus',
            key: '11',
            width: 180,
            rowScope:  'row',
        },
        {
            title: 'Acciones',
            dataIndex: 'id',
            render:  handleRenderer,
            key: '12',
            width: 110,
            ellipsis: true,     
            fixed: 'right'       
        },     
    ];

    const handleCancelForm = ()=>{
        form_material.resetFields() 
        setRowSelect(undefined) 
        setOpenForm(false) 
        setLoadingFilters(false)
        setLoadingTableData(false)
        setShowConfirmDelete(false) 
        setOpenForm(false) 
    }
    
    return( 
        <>
            <ModalConfirmDelete/>
            {openForm  && <MaterialForm
                openForm={openForm} 
                handleCancelForm={handleCancelForm}
                form_material={form_material}
                rowSelect={rowSelect}
                materials= {dataApi}
            /> }     
            <div className='material_section_container'>                                  
                <Row
                    className='bodyContainer_title'
                >
                    <h2>Materiales</h2>                
                </Row>
                <Row 
                   className='header_row'
                >              
                    <Button
                    type='primary'
                    onClick={()=>{
                            setOpenForm(true)
                        }}
                    >   Agregar materiales
                    </Button>          
                </Row>               
                <Row 
                    className='bodyContent_container_table'
                >
                    {
                        loadingTableData
                        ? <SpinerHorizontal/> 
                        : <Table 
                            style={{
                                width:'95%',
                            }}
                            columns={columns} 
                            dataSource={dataTable}                                      
                            scroll={{ y: adjustedTableHeigthScroll }}
                            pagination={false}
                        />    
                    } 
                </Row>             
            </div>
        </>
        
        
)};

export default MaterialsSection;