import  { KeyedMutator } from "swr";
import { useAuthSWR } from './useAuthSWR';
import { END_POINT_GENERIC_HYBRID } from '../Axios/ENDPOINTS';

type Material = {
  id: number;
  code: string;
};

type Brand = {
  id: number;
  code: string;
};

type SuggestedSowRelation = {
  id: number;
  code: string;
  female_sow_relationship: number;
  male_sow_relationship: number;
};

type MaterialProps = {
  id: number;
  code: string;
  gdus_to_flower: number;
  brand: Brand;
  material_type: Material;
  female_material: {
    id: number;
    code: string;
    gdus_to_flower: number;
    brand: number;
    material_type: Material;
  };
  male_material: {
    id: number;
    code: string;
    gdus_to_flower: number;
    brand: number;
    material_type: Material;
  };
  suggested_sow_relation: SuggestedSowRelation;
  female_sow_gdus: number;
  male_1_sow_gdus: number;
  male_2_sow_gdus: number;
  male_3_sow_gdus: number;
};


export interface ResponseGetMaterials{
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: MaterialProps[]  
}

export const useGetHybrid = () => {
   
  const {data, error, mutate }:{
    data: MaterialProps[], 
    error: any,
    mutate: KeyedMutator<any>
  } = useAuthSWR([END_POINT_GENERIC_HYBRID])

  
  return {
    data,
    mutate,
    error
  };
};