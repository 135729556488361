import { axiosBackEndInstance } from '../Axios';
import { END_POINT_LOTS_BY_PRODUCTION_DAY_OF_MANUAL_DETASSELING_BY_LOT } from '../Axios/ENDPOINTS';
import { useVerifySession } from '../hooks/useVerifySession';
import { FormItemProps } from '../Components/Forms/ModifyHumanResources/types';

interface ResponseDates {
  date: string,
  quantity: number,
}
export const useDaysOfManualDetasselingByLot= () => {

  const {errorHandling} = useVerifySession()   

  const editDaysOfManualDatasseling = async (
    callback: Function,
    id: string,
    newData: FormItemProps[]
                            
  ): Promise<ResponseDates[] | void> => {
      let dates ={dates: newData}
    try {
      const data  = await axiosBackEndInstance.post(
        `${END_POINT_LOTS_BY_PRODUCTION_DAY_OF_MANUAL_DETASSELING_BY_LOT}/${id}`,
        dates
      );
      callback() 
      if(data.data)   {
        return data.data
      }
    } catch (newError) {
      callback()
      let status = errorHandling(newError).request.status
      console.log('status: ' + status + ' ' + newError);
    }
  }; 

  return {    
    editDaysOfManualDatasseling,
    
  };
};
