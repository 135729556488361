import { axiosBackEndInstance } from '../Axios';
import { END_POINT_GENERIC_CAMPAIGN } from '../Axios/ENDPOINTS';
import { notification } from 'antd';
import { useErrorHandling } from '../hooks/useErrorHandling';
import { CampaignProps, useGetCampaigns } from './useGetCampaigns';


export const useCampaigns= () => {

    const {errorHandling} = useErrorHandling()
    const {mutate} = useGetCampaigns()
     
    const getFilteredCampaigns = async (
      callback: Function,
      code: string,
      pageValue?: string | undefined,        
      ): Promise<CampaignProps[] | undefined> => {
      
      var params = new URLSearchParams();

      // let day: any = contract_start_date
      // let end_day: any = contract_end_date
      // const startDate = day && moment(day[0].$d).format('YYYY-MM-DD')
      // const endDate = end_day && moment(end_day[0].$d).format('YYYY-MM-DD')
      // startDate && params.append("startDate", startDate)
      // endDate && params.append("endDate", endDate)

      pageValue && params.append("page", pageValue) 
      code && params.append("campaign_code", code)  

      try {
        const {
          data,
        }: { data: any } = await axiosBackEndInstance.get(
          END_POINT_GENERIC_CAMPAIGN,
          { 
            params
          }
        );
        callback()
        return data;
      } catch (newError) {
        console.log(newError);
        callback()
        //throw newError;
      }
    };

    const createCampaigns = async (
      callback: Function,
      newData:{
        code: string,
        start_date: string,
        end_date: string
      }
      
      ): Promise<any> => {      
    try {
      const  data = await axiosBackEndInstance.post(
        END_POINT_GENERIC_CAMPAIGN, newData
      )
      callback()   
      if(data.status === 200 || data.status === 201){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Campaña creada con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      callback()
      let status = errorHandling(newError).request.status
      console.log('status: ' + status + ' ' + newError);
    }
  };

  const editCampaign = async (
    callback: Function,
    id: number,
    newData:{
      code: string,
      start_date: string,
      end_date: string
    }
                            
  ): Promise<void> => {
      
    try {
      const data  = await axiosBackEndInstance.put(
        `${END_POINT_GENERIC_CAMPAIGN}/${id}`,newData
      );
      callback()   
      if(data.status === 200 || data.status === 201){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Zona editada con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      callback()
      let status = errorHandling(newError).request.status
      console.log('status: ' + status + ' ' + newError);
    }
  };

  const deleteCampaign = async (
    callback: Function,
    id: number                            
  ): Promise<void> => {
      
    try {
      const data  = await axiosBackEndInstance.delete(
        `${END_POINT_GENERIC_CAMPAIGN}/${id}`, 
      );
      callback()   
      if(data.status === 200){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Campaña eliminada con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      let status = errorHandling(newError).request.status
      console.log('status: ' + status + ' ' + newError);
      callback()
    }
  };

  return {
    getFilteredCampaigns,
    createCampaigns,
    editCampaign,
    deleteCampaign
  };
};
