import React, { useEffect, useState } from 'react';
import './styles.scss'
import {  Button,  Modal, Row, Table,  } from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    CloseOutlined, CheckOutlined
  } from '@ant-design/icons';
import ZoneForm, { ZoneFormFilters } from '../../Forms/ZoneForm';
import {  ZoneProps, useGetZone } from '../../../Services/useGetZone';
import { useForm } from 'antd/es/form/Form';
import { useZone } from '../../../Services/useZone';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';

export interface DataType {
    index: number;
    id: number;
    code: string;
    has_late_sow: string;
    description: string
}

const ZoneSection: React.FC = () => {

    const {data: zonesList} = useGetZone()
    const {getFilteredZone, deleteZone} = useZone()

    const [form_zone] = useForm()
    const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
    const [loadingFilters, setLoadingFilters] = useState(false)
    const [dataTable, setDataTable] = useState<DataType[]>([])
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [rowSelect, setRowSelect] = useState<{
        show: boolean ;
        id: number | undefined;
        code: string  | undefined;
        description: string  | undefined;
        has_late_sow: string | undefined;
        type: 'edit' | 'create' | 'delete' | undefined
    }>({
        show: false,
        id: undefined,
        code:  undefined,
        description: undefined,
        has_late_sow: undefined,
        type: undefined
    })

    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 50, // Número de elementos por página
        total: 0, // Número total de elementos
    });
    

    useEffect(()=>{
        setLoadingTableData(true)
        if(zonesList){
            rowData() 
            setLoadingTableData(false)
        }
    },[zonesList])  
    
    const rowData = (data? : ZoneProps[] ) =>{
        let filterData: DataType[]= []
        let list: ZoneProps[]  = data ? data : zonesList
        let values: ZoneFormFilters = form_zone.getFieldsValue() 
      
        if(list && list){
          list.map((data, index)=>{            
            filterData.push({
                index: index,
                id: data.id,
                code: data.code,
                has_late_sow: data.has_late_sow ? 'true' : ' false',
                description: data.description                      
            })
          }) 
        }       
        setDataTable(filterData)
    }

    const handleGetPage = async (page?: number, loadingTable?: boolean) => {
        let values = form_zone.getFieldsValue() 
        let filters: ZoneFormFilters = values ? values : undefined 
        let pageNumber: number = page ? page : 1  
        loadingTable && setLoadingTableData(true)
        const data = await getFilteredZone( 
            callback,         
            filters.description,           
            filters.zone_code,
            pageNumber.toString()
        )
        
        if (data) {
            rowData(data);         
        }
    };

    const callback = () =>{
        setLoadingFilters(false)
        setLoadingTableData(false) 
        setShowConfirmDelete(false)      
    }

    const handleCancelForm = ()=>{
        form_zone.resetFields() 
        setRowSelect({
            show: false,
            id: undefined,
            code:  undefined,
            description: undefined,
            has_late_sow: undefined,
            type: undefined
        })        
    }    

    const handleSeeAll =()=>{
        form_zone.resetFields() 
        handleGetPage()        
    }
    
    const handleRenderer = (text: string, data: DataType) =>{
        return (
            <div className='zone_action_action_render'>
                <EditOutlined 
                    className='icons'
                    onClick={()=>{
                        setRowSelect({
                            show: true,
                            id: data.id,
                            code: data.code,
                            description: data.description,
                            has_late_sow: data.has_late_sow,
                            type: 'edit'
                        })
                    }}
                />
                <DeleteOutlined 
                    className='icons'
                    style={{ marginLeft:'30px'}}
                    onClick={()=>{
                        setRowSelect({
                            show: false,
                            id: data.id,
                            code: data.code,
                            description: data.description,
                            has_late_sow: data.has_late_sow,
                            type: 'delete'
                        })
                        setShowConfirmDelete(true)
                    }}
                />
            </div>  
        )      
    }

    const ModalConfirmDelete = () => {
        return(
            <Modal
                title="Eliminar"
                open={showConfirmDelete}
                onOk={()=>{
                    if(rowSelect && rowSelect.id){
                        deleteZone(callback, rowSelect.id)
                    }}}
                onCancel={()=>setShowConfirmDelete(false)}
                okText="Eliminar"
                cancelText="Cancelar"
            >
                <p>¿Estás seguro de que desea eliminar {rowSelect?.code} - {rowSelect?.description}?</p>
            </Modal>
        )
    }

    const hasLateSow = (text: string, record: DataType) =>{
        return(
            <div>{record.has_late_sow == 'true' ? <CheckOutlined  style={{color:'green'}}/> : <CloseOutlined style={{color:'red'}}/>}</div>
        )
    }
  
    const columns: ColumnsType<DataType> = [
        {
            title: 'Código de Zona',
            dataIndex: 'code',
            key: 'id',
            ellipsis: true,
        },
        {
            title: 'Descripción',
            dataIndex: 'description',
            key: 'id',
            ellipsis: true,
        },
        {
            title: 'Siembra tardía',
            dataIndex: 'has_late_sow',
            render: hasLateSow,
            key: 'id',
            ellipsis: true,
        },
        {
            title: 'Acciones',
            dataIndex: 'id',
            render:  handleRenderer,
            key: 'id',
            width: 110,
            ellipsis: true,            
        },      
    ];
    

    return( 
        <>
            <ModalConfirmDelete/>
            {rowSelect.show && <ZoneForm                 
                form_zone={form_zone}
                rowSelect = {rowSelect}
                handleCancelForm={handleCancelForm}
            />  }    
            <div className='zone_section_container'>                                  
                <Row
                    className='bodyContainer_title'
                >
                    <h2>Zonas</h2>                
                </Row>
                <Row 
                    className='header_row'
                >
                    <Button 
                        type='primary'
                        onClick={()=>{
                            setRowSelect({
                                show: true,
                                id: undefined,
                                code:undefined,
                                description: undefined,
                                has_late_sow: undefined,
                                type: 'create'
                            })
                        }}
                    >
                        Agregar Zona
                    </Button>
                 
                </Row>
                <Row 
                    className='bodyContent_container_table'
                >
                    {
                    loadingTableData
                    ? <SpinerHorizontal/> 
                    : <Table 
                        style={{
                            width: '80%',
                        }}
                            columns={columns} 
                            dataSource={dataTable}
                            pagination={{
                                pageSize: 50,
                                showSizeChanger: false,
                            }}
                            scroll={{ y: 580 }}
                        />    
                    } 
                </Row>             
            </div>
        </>
        
        
)};

export default ZoneSection;