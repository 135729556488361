import React from 'react';
import './styles.scss'
import Menu from '../../Components/Menu';
import Navbar from '../../Components/Navbar';
import BodyContent from '../../Components/BodyContent';

export const Dashboard: React.FC =()=>{
         
  return(
    <div className="dashboard_container">
      <Navbar/>
      <div className='dashboard_body'>
        <Menu/>
        <BodyContent/>
      </div>       
    </div>
  )
}