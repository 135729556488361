import { axiosBackEndInstance } from '../Axios';
import { END_POINT_GENERIC_SOW_RELATION } from '../Axios/ENDPOINTS';
import { notification } from 'antd';
import { useErrorHandling } from '../hooks/useErrorHandling';
import { MaterialProps, ResponseGetMaterials, useGetMaterials } from './useGetMaterials';
import { useGetSowRelation } from './useGetSowRelation';

export interface SowRelationValues{
  code: string,
  female_sow_relationship: number,
  male_sow_relationship: number,
}

export const useSowRelation= () => {

  const {errorHandling} = useErrorHandling()
  const {mutate} = useGetSowRelation()
    
  const getFilteredSowRelation = async (
    callback: Function,
    material_code: string,
    gdus_to_flowering: number,
    brand_id: number,
    material_type: number,
    pageValue?: string | undefined,        
    ): Promise<SowRelationValues[] | undefined> => {
    
    var params = new URLSearchParams();

    pageValue && params.append("page", pageValue)
    material_code && params.append("material_code", material_code)
    material_type && params.append("material_type", material_type.toString())
    gdus_to_flowering && params.append("gdus_to_flowering", gdus_to_flowering.toString())  
    brand_id && params.append("brand_id", brand_id.toString())  

    try {
      const {
        data,
      }: { data: any } = await axiosBackEndInstance.get(
        END_POINT_GENERIC_SOW_RELATION,
        { 
          params
        }
      );
      callback()
      return data;
    } catch (newError) {
      console.log(newError);
      callback()
      // throw newError;
    }
  };

  const createSowRelation = async (
    callback: Function,
    values: SowRelationValues
  ): Promise<any | undefined> => {      
    try {
      const  data = await axiosBackEndInstance.post(
        END_POINT_GENERIC_SOW_RELATION, values
      )
      const status =  errorHandling(data).request.status
      if(status === 201){
        mutate()
        callback()
        notification.success({
          message: 'Éxito', 
          description:'Relación creada con éxito', 
          placement: 'topRight'
      })
      }
    } catch (newError) {
      console.log(newError);
      callback()
      // throw newError;
    }
  };

  const editSowRelation = async (
    callback: Function,
    id: number,
    values: SowRelationValues                           
  ): Promise<void> => {            
    try {
      const data  = await axiosBackEndInstance.patch(
        `${END_POINT_GENERIC_SOW_RELATION}/${id}`,values
      );
      callback()   
      if(data.status === 200 || data.status === 201){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Relación editada con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {      
      callback()
      let status = errorHandling(newError).request.status
      console.log(newError);
      console.log(status)
    }
  };

  const deleteSowRelation = async (
    callback: Function,
    id: number                            
  ): Promise<void> => {        
    try {
      const data  = await axiosBackEndInstance.delete(
        `${END_POINT_GENERIC_SOW_RELATION}/${id}`, 
      );
      callback()   
      if(data.status === 200){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Relación eliminada con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      callback()
      let status = errorHandling(newError).request.status
      console.log(newError);
      console.log(status)
    }
  };

  return {
    getFilteredSowRelation,
    createSowRelation,
    editSowRelation,
    deleteSowRelation
  };
};
