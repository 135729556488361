import { axiosBackEndInstance } from '../Axios';
import { END_POINT_LOTS_BY_PRODUCTION_BAG_ORDER } from '../Axios/ENDPOINTS';
import { notification } from 'antd';
import { BagOrderProps, useGetBagOrder } from './useGetBagOrder';
import { useErrorHandlingReturn400 } from '../hooks/useErrorHandlingReturn400';

export interface BagOrderValues{
  hybrid?: number,
  campaign?: number,
  quantity?: number,
  female_availability?: string,
  female_coment?: string,
  male_availability?: string,
  male_coment?: string,
  sterility_percentage?: string
} 

export const useBagOrder= () => {

  
  const {errorHandling} = useErrorHandlingReturn400()
  const {mutate} = useGetBagOrder()
    
  const getFilteredMaterial = async (
    callback: Function,
    material_code: string,
    gdus_to_flowering: number,
    brand_id: number,
    material_type: number,
    pageValue?: string | undefined,        
    ): Promise<BagOrderProps[] | undefined> => {
    
    var params = new URLSearchParams();

    pageValue && params.append("page", pageValue)
    material_code && params.append("material_code", material_code)
    material_type && params.append("material_type", material_type.toString())
    gdus_to_flowering && params.append("gdus_to_flowering", gdus_to_flowering.toString())  
    brand_id && params.append("brand_id", brand_id.toString())  

    try {
      const {
        data,
      }: { data: any } = await axiosBackEndInstance.get(
        END_POINT_LOTS_BY_PRODUCTION_BAG_ORDER,
        { 
          params
        }
      );
      callback()
      return data;
    } catch (newError) {
      console.log(newError);
      callback()
    }
  };

  const createBagOrder = async (
    callback: Function,
    values: BagOrderValues
  ): Promise<any | undefined> => {      
    try {
      const  data = await axiosBackEndInstance.post(
        END_POINT_LOTS_BY_PRODUCTION_BAG_ORDER, values
      )
      const status =  errorHandling(data).request.status      
      if(status === 201 || status === 200){
        mutate()
        callback()
        notification.success({
          message: 'Éxito', 
          description:'Orden de Bolsas creado con éxito', 
          placement: 'topRight'
       })
      }
    } catch (newError: any) {
      callback()      
      let status = errorHandling(newError).request.status
      if(status == 400){
        const errorMessage =
        newError.response && newError.response.data &&  newError.response.data.non_field_errors
          ? JSON.stringify(newError.response.data)
          : 'Unknown Error';
        if(errorMessage.includes('CODE 460')){
          notification.warning({
            message: 'Ya existe un pedido de bolsa para esa campaña y ese híbrido', 
            placement: 'topRight'
          })
        }else{
          notification.error({
            message: 'Error',
            description: 'Hubo un error, comuníquese con el administrador', 
            placement: 'topRight'
          })
        }
      }
      console.log('status: ' + status + ' ' + newError);
    }
  };

  const editBagOrder = async (
    callback: Function,
    id: number,
    values: BagOrderValues                           
  ): Promise< void> => {            
    try {
      const data  = await axiosBackEndInstance.patch(
        `${END_POINT_LOTS_BY_PRODUCTION_BAG_ORDER}/${id}`,values
      );
      callback()   
      if(data.status === 200 || data.status === 201){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Orden de bolsas editado con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {      
      let status = errorHandling(newError).request.status
      if(status == 400){
        
        notification.error({
          message: 'Error',
          description: 'Hubo un error, comuníquese con el administrador', 
          placement: 'topRight'
        })
        
      }
      console.log('status: ' + status + ' ' + newError);
    }
  };

  const deleteBagOrder = async (
    callback: Function,
    id: number                            
  ): Promise<void> => {        
    try {
      const data  = await axiosBackEndInstance.delete(
        `${END_POINT_LOTS_BY_PRODUCTION_BAG_ORDER}/${id}`, 
      );
      callback()   
      if(data.status === 200){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Orden de bolsas eliminado con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      callback()
      let status = errorHandling(newError).request.status
      console.log(newError);
      console.log(status)
    }
  };

  return {
    getFilteredMaterial,
    createBagOrder,
    deleteBagOrder,
    editBagOrder
  };
};
