import React, { useEffect, useState } from "react";
import './styles.scss';
import { Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import { FarmFormProps } from "./types";
import { useGetOwners } from "../../../Services/useGetOwners";
import { useFarms } from "../../../Services/useFarms";
import { FieldsValues } from "../MaterialForm";
import { useGetCity } from "../../../Services/useGetCity";
import esES from 'antd/es/date-picker/locale/es_ES';
import moment from "moment";
import dayjs from 'dayjs';

export interface FilterValues{
    value: string,
    id: string,
    key: number,
  }
export interface FieldFormFilters {
    id: number
    field_code: string,
    description: string,
    city_id: number,
    farm_owner_id: number
}

export interface FormFieldsField{
    field_code: string,
    description: string,
    city_id: number,
    farm_owner_id: number,    
}

export const FarmForm: React.FC<FarmFormProps> =({
    callback,
    form_farm,
    rowSelect,   
    openForm 
})=>{

    
    useEffect(()=>{
        form_farm.resetFields()
    },[])

    const { Option } = Select;
    const { data: city } = useGetCity()
    const { data: owner } = useGetOwners()
    const { createFarm, editFarm} = useFarms()
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [checked, setChecked] = useState(false);

    const dateFormat = 'YYYY/MM/DD';

    useEffect(()=>{
        if(rowSelect && rowSelect.is_full_grower_farm == 'true'){
            setChecked(true)
        }
    },[])

    const handleCancel =()=>{
        callback() 
        setLoadingBtn(false)
    }

    const handleSave = async(values: {
        code: string,
        description: string,
        city: FieldsValues,
        farm_owner: FieldsValues,
        contract_start_date: Date,
        contract_end_date: Date
    })=>{
        let newData = {
            code: values.code,
            description: values.description,
            city: values.city.key,
            farm_owner: values.farm_owner.key,
            is_full_grower_farm: form_farm.getFieldValue('is_full_grower_farm'),
            contract_start_date: values.contract_start_date,
            contract_end_date: values.contract_end_date
        }

        if(rowSelect){
            await editFarm(handleCancel, rowSelect.id, newData)
        }else{
            await createFarm(handleCancel, newData)
        }     
    }   

    const onChangeCheck=(e: boolean)=>{
        setChecked(e)
        form_farm.setFieldValue('is_full_grower_farm', e)
    }

    return(
        <div>
            <Modal
               title={rowSelect ? 'Editar Campo' : 'Nuevo Campo'}
               open={openForm}
                onCancel={handleCancel}    
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar"                 
                onOk={() => form_farm.submit()}
            >
                <Form
                    form={form_farm}
                    id='form_field'
                    onFinish={handleSave}
                    layout="vertical"
                    
                >
                    <Form.Item
                        label='Código de Campo'
                        name='code'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        initialValue={rowSelect?.code}
                    >
                            <Input
                                defaultValue={rowSelect?.code}
                                placeholder="Código"
                            />

                    </Form.Item>    
                    <Form.Item
                        label='Descripción'
                        name='description'
                        initialValue={rowSelect?.description}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Input
                            defaultValue={rowSelect?.description}
                            placeholder="Descripción"
                        />
                    </Form.Item>       
                    <Form.Item
                        label='Localidad'
                        name='city'                        
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        initialValue={{
                            value: rowSelect?.city_name,
                            key: rowSelect?.city_id,
                            name: rowSelect?.city_name    
                        }}  
                    >
                        <Select
                            labelInValue
                            placeholder="Localidad"     
                            defaultValue={{
                                value: rowSelect?.city_name,
                                key: rowSelect?.city_id,
                                name: rowSelect?.city_name    
                            }}                       
                        >
                            {city && city.map((item) => (
                                <Option value={item.name} key={item.id}>
                                {item.name}
                                </Option>
                            ))}            
                        </Select>
                    </Form.Item>  
                    <Form.Item
                        label='Dueño'
                        name='farm_owner'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        initialValue={{
                            value: rowSelect?.farm_owner_name,
                            key: rowSelect?.farm_owner_id,
                            name: rowSelect?.farm_owner_name    
                        }}  
                    >
                        <Select
                            placeholder="Dueño"
                            labelInValue
                            defaultValue={{
                                value: rowSelect?.farm_owner_name,
                                key: rowSelect?.farm_owner_id,
                                name: rowSelect?.farm_owner_name    
                            }}
                        >
                             {owner && owner.map((item) => (
                                <Option value={item.name} key={item.id}>
                                {item.name}
                                </Option>
                            ))}     

                        </Select>
                    </Form.Item>   
                    <Form.Item
                        name='is_full_grower_farm'
                        initialValue={rowSelect?.is_full_grower_farm == 'true' ? true : false}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Checkbox
                            checked={checked}
                            onChange={(e) => onChangeCheck(e.target.checked)}
                        >
                            Es Full Grower
                        </Checkbox>
                    </Form.Item>  
                    <Form.Item
                        label='Fecha inicio del contrato'
                        name='contract_start_date'
                        initialValue={rowSelect?.contract_start_date ? dayjs(rowSelect.contract_start_date, dateFormat) : null}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <DatePicker
                            defaultValue={rowSelect?.contract_start_date ? dayjs(rowSelect.contract_start_date, dateFormat) : null}
                            locale={esES} 
                        />
                    </Form.Item>
                    <Form.Item
                        label='Fecha fin del contrato'
                        name='contract_end_date'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        initialValue={rowSelect?.contract_end_date ? dayjs(rowSelect.contract_end_date, dateFormat) : null}
                    >
                        <DatePicker
                             defaultValue={rowSelect?.contract_end_date ? dayjs(rowSelect.contract_end_date, dateFormat) : null}
                            locale={esES} 
                        />
                    </Form.Item>                     
                </Form>
            </Modal>
        </div>
    )
}

export default FarmForm;