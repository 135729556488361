import  { KeyedMutator } from "swr";
import { useAuthSWR } from './useAuthSWR';
import { END_POINT_GENERIC_DETASSELED_EQUIPMENT } from '../Axios/ENDPOINTS';

export interface DetasseledEquipment {
  id: number,
  code: string,
  hectares_of_detasseling_per_hour: number
}

export interface ResponseGetTypeOfDetasseling{ 
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: DetasseledEquipment[]  
}

export const useGetDetasseledEquipment = () => {
    
  const {data, error, mutate }:{
    data: DetasseledEquipment[], 
    error: any,
    mutate: KeyedMutator<any>
  } = useAuthSWR([END_POINT_GENERIC_DETASSELED_EQUIPMENT])


  return {
    data,
    mutate,
    error
  };
};