import React, { useEffect, useState } from "react";
import './styles.scss';
import { Form, Input, Modal, Select } from "antd";
import { ProfileFormProps } from "./types";
import { useCity } from "../../../Services/useCity";
import { FieldsValues } from "../MaterialForm";
import { useGetProvince } from "../../../Services/useGetProvince";
import { useGetZone } from "../../../Services/useGetZone";

export interface FilterValues{
    value: string,
    id: string,
    key: number,
}



export const ProfileForm: React.FC<ProfileFormProps> =({
    form_profile,
    showForm,
    data,
    handleCancelForm,
})=>{

    useEffect(()=>{
        form_profile.resetFields()
    },[])
    
    const { createCity, editCity }= useCity()
    const { Option } = Select;
    const [loadingBtn, setLoadingBtn] = useState(false)

    const handleCancel =()=>{     
        handleCancelForm() 
        setLoadingBtn(false)
    }

    const handleSave = async(values: {
        name: string,
        zip_code: string,
        province: FieldsValues,
        zone: FieldsValues
    })=>{
        let newData = {
            name: values.name,
            zip_code: values.zip_code,
            province: values.province.key,
            zone: values.zone.key
        }       
        // if(rowSelect.type === 'create'){
        //     await createCity(handleCancel, newData)
        // }else if(rowSelect.type === 'edit' && rowSelect.id){
        //     await editCity(handleCancel, rowSelect.id, newData)
        // }        
    }       

    return(
        <div>
            <Modal
                title='Editar datos de usuario'
                open={showForm}
                onCancel={handleCancel}  
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar"                 
                onOk={() => form_profile.submit()}
            >
                <Form
                    form={form_profile}
                    id='form_profile'
                    onFinish={handleSave}
                    layout="vertical"                    
                >
                    <Form.Item
                        label='Nombre'
                        name='name'
                        initialValue={data?.first_name}
                    >
                        <Input
                            defaultValue={data?.first_name}
                            placeholder="Nombres"
                        />
                    </Form.Item>                        
                    <Form.Item
                        label='Apellido'
                        name='last_name'
                        initialValue={data?.last_name}
                    >
                        <Input
                            defaultValue={data?.last_name}
                            placeholder="Apellidos"
                        />
                    </Form.Item>                                     
                </Form>
            </Modal>
        </div>
    )
}

export default ProfileForm;