import React, {
    createContext,
    useEffect,
    useState,
  } from 'react';
  class VoidContext implements StylesContext {
   
    get adjustedTableHeigthScroll(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get dashboard_body(): never {
      throw new Error('Cannot consume context outside of provider');
    }
  }

  interface StylesContext {
    adjustedTableHeigthScroll: number,
    dashboard_body: number
  }



  interface propsWithChildren {
    children: React.ReactNode
  }

  
  export const StylesContext = createContext<StylesContext>(new VoidContext());
  export const StylesContextProvider= ({ children }: propsWithChildren): JSX.Element => {

    const [adjustedTableHeigthScroll, setAdjustedTableHeigthScroll] = useState<number>(window.innerHeight > 900 ? window.innerHeight *0.70 : window.innerHeight *0.64);
    const [adjustedBodyBorder, setAdjustedBodyBorder] = useState<string>('90');
    const [dashboard_body, setDashboard_body] = useState<number>(window.innerHeight - (window.innerHeight*0.076) -(window.innerHeight > 900 ? 16 : 50))

    useEffect(() => {
      const handleResize = () => {
        const screenWidth = window.innerHeight > 900 ? window.innerHeight *0.70 : window.innerHeight *0.64;
        const navbar = window.innerHeight*0.076;
        const bodyHeight = window.innerHeight - navbar -16
        
        setAdjustedTableHeigthScroll(screenWidth);
        setDashboard_body(bodyHeight)
      };
  
      handleResize();
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

 
    return (
      <StylesContext.Provider value={{
        adjustedTableHeigthScroll,
        dashboard_body
      }}>{children}</StylesContext.Provider>
    );
  };



  