import React, { useEffect, useState } from "react";
import './styles.scss';
import { DatePicker, Form, Input, Modal, Select } from "antd";
import esES from 'antd/es/date-picker/locale/es_ES'
import { BrandFormProps } from "./types";
import { useBrands } from "../../../Services/useBrands";

export interface FieldsValues{
    value: string,
    id: string,
    key: number,
  }
export interface BrandsFormFilters { 
    brand_code: string  
}

export interface BrandFormFields{
    brand_code: string,
}

export const BrandForm: React.FC<BrandFormProps> =({
    showModal,
    rowSelect,
    handleCancelForm,
    form_brand
})=>{
    useEffect(()=>{
        form_brand.resetFields()
    },[])
    
    const [loadingBtn, setLoadingBtn] = useState(false)
    const { createBrands, editBrand} = useBrands()

    const handleCancel =()=>{
        handleCancelForm()
        setLoadingBtn(false)
    }

    const handleSave = async (newData: {
        code: string
    })=>{
        if(rowSelect){ //Edit
            await editBrand(handleCancel, rowSelect.id, newData)           
        }else {
            await createBrands(handleCancel, newData)
        }  
    }    

    return(
        <div>
            <Modal
                title={rowSelect ? 'Editar Marca': 'Agregar Marca'}
                open={showModal}
                onCancel={handleCancel}  
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={() => form_brand.submit()}
            >
                <Form
                    form={form_brand}
                    id='form_brand'
                    onFinish={handleSave}
                    layout="vertical"
                    
                >
                    <Form.Item
                        label='Código de Marca'
                        name='code'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        initialValue={rowSelect?.code}
                    >
                        <Input
                            placeholder="Nombre de marca"
                        />
                    </Form.Item>                       
                </Form>
            </Modal>
        </div>
    )
}

export default BrandForm;