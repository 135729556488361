import React, { useEffect, useState } from 'react';
import './styles.scss'
import {  Button, Modal, Row, Table } from 'antd';
import {
    EditOutlined,
    DeleteOutlined
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { OwnersSectionProps } from './types';
import { OwnersProps, useGetOwners } from '../../../Services/useGetOwners';
import  { UserFormFilters } from '../../Forms/UserForm';
import { useOwners } from '../../../Services/useOwners';
import OwnerForm from '../../Forms/OwnerForm';

export interface DataType {
    index: number;
    id: number;
    code: string,
    name: string,
    last_name: string, 
    phone: string
}

const OwnersSection: React.FC<OwnersSectionProps> = () => {

    const {data: dataApi} = useGetOwners()    
    const {getFilteredOwner, deleteOwner} = useOwners()

    const [form_owner] = useForm()
    const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
    const [loadingFilters, setLoadingFilters] = useState(false)
    const [dataTable, setDataTable] = useState<DataType[]>([])
    const [rowSelect, setRowSelect] = useState<{
        show: boolean ;
        id: number | undefined;
        code: string | undefined,
        name: string | undefined,
        last_name: string | undefined, 
        phone: string| undefined,
        type: 'edit' | 'create' | 'delete' | undefined
    }>({
        show: false,
        id: undefined,
        code: undefined,
        name: undefined,
        last_name: undefined, 
        phone: undefined,
        type: undefined
    })
    
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)

    useEffect(()=>{
        setLoadingTableData(true)
        if(dataApi){
            rowData() 
            setLoadingTableData(false)
        }
    },[dataApi])      

    const rowData = (data? : OwnersProps[]) =>{
        let filterData: DataType[]= []
        let list: OwnersProps[] = data ? data : dataApi
        let values: UserFormFilters = form_owner.getFieldsValue() 
      
        if(list && list){
          list.map((data, index)=>{            
            filterData.push({
                index: index,
                id: data.id,
                code: data.code,
                name: data.name,
                last_name: data.last_name, 
                phone: data.phone
            })
        })      
        }       
        setDataTable(filterData)
    }

    const handleGetPage = async (page?: number, loadingTable?: boolean) => {
        let values = form_owner.getFieldsValue() 
        let filters: UserFormFilters = values ? values : undefined 
        let pageNumber: number = page ? page : 1  
        loadingTable && setLoadingTableData(true)
        const data = await getFilteredOwner( 
            callback,         
            filters.id,
            filters.user_code,
            filters.name,
            filters.lastname,
            pageNumber.toString()
        )
        
        if (data) {
            rowData(data);         
        }
    };

    const callback = () =>{
        setLoadingFilters(false)
        setLoadingTableData(false) 
        setShowConfirmDelete(false)      
    }
    
    const handleCancelForm = ()=>{
        form_owner.resetFields() 
        setRowSelect({
            show: false,
            id: undefined,
            code: undefined,
            name: undefined,
            last_name: undefined, 
            phone: undefined,
            type: undefined
        })        
    }

    const handleRenderer = (text: string, data: DataType) =>{
        return (
            <div className='zone_action_action_render'>
                <EditOutlined 
                    className='icons'
                    onClick={()=>{
                        setRowSelect({
                            show: true,
                            id: data.id,
                            code: data.code,
                            name: data.name,
                            last_name: data.last_name, 
                            phone: data.phone,
                            type: 'edit'
                        })
                    }}
                />
                <DeleteOutlined 
                    className='icons'
                    onClick={()=>{
                        setRowSelect({
                            show: false,
                            id: data.id,
                            code: data.code,
                            name: data.name,
                            last_name: data.last_name, 
                            phone: data.phone,
                            type: 'delete'
                        })
                        setShowConfirmDelete(true)
                    }}
                    style={{marginLeft:'30px'}} />
            </div>  
        )      
    }
  
  
    const columns: ColumnsType<DataType> = [
        {
            title: 'Código',
            dataIndex: 'code',
            key: 'id',
            ellipsis: true,
     
        },
        {
            title: 'Nombres',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
        },
        {
            title: 'Apellido',
            dataIndex: 'last_name',
            key: 'last_name',
            ellipsis: true,
        },
        {
            title: 'teléfono',
            dataIndex: 'phone',
            key: 'phone',
            ellipsis: true,
        },
        {
            title: 'Acciones',
            dataIndex: 'id',
            render:  handleRenderer,
            key: 'id',
            width: 110,
            ellipsis: true,            
        }, 
            
    ];


    const ModalConfirmDelete = () => {
        return(
            <Modal
                title="Eliminar"
                open={showConfirmDelete}
                onOk={()=>{
                    if(rowSelect && rowSelect.id){
                        deleteOwner(callback, rowSelect.id)
                    }}}
                onCancel={()=>setShowConfirmDelete(false)}
                okText="Eliminar"
                cancelText="Cancelar"
            >
                <p>¿Estás seguro de que desea eliminar: {rowSelect?.code} - { rowSelect?.name} { rowSelect?.last_name} ?</p>
            </Modal>
        )
    }

    return( 
        <>
            <ModalConfirmDelete/>
            {rowSelect.show && <OwnerForm
                form_owner={form_owner}
                rowSelect = {rowSelect}
                handleCancelForm={handleCancelForm}
            />}      
            <div className='owner_section_container'>                                  
                <Row
                    className='bodyContainer_title'
                >
                    <h2>Dueños de campo</h2>                
                </Row>
                <Row 
                    className='header_row'
                >              
                    <Button
                    type='primary'
                    onClick={()=>{
                        setRowSelect({
                            show: true,
                            id: undefined,
                            code: undefined,
                            name: undefined,
                            last_name: undefined, 
                            phone: undefined,
                            type: 'create'
                        })}}
                    >   Nuevo dueño
                    </Button>             
                </Row>               
                <Row 
                    className='bodyContent_container_table'
                >
                    {
                        loadingTableData
                        ? <SpinerHorizontal/> 
                        : <Table 
                            style={{
                                width: '80%',
                            }}
                            columns={columns} 
                            dataSource={dataTable}  
                            pagination={{
                                pageSize: 50,
                                showSizeChanger: false,
                            }}
                            scroll={{ y: 580 }}
                        />    
                    } 
                </Row>             
            </div>
        </>
        
        
)};

export default OwnersSection;