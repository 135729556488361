import { notification } from 'antd';
import { axiosBackEndInstance } from '../Axios';
import { END_POINT_LOTS_BY_PRODUCTION_PROGRESS } from '../Axios/ENDPOINTS';
import { useErrorLoguin } from '../hooks/useErrorLoguin';
import { RootObject } from './useGetSowingProgress';


export const useSowingProgress = () => {

  const {errorHandling} = useErrorLoguin()

  const getSowingProgress =  async(
    campaign: string,
    callback: Function
  ): Promise<RootObject | undefined> =>{

    var params = new URLSearchParams();
    campaign && params.append("campaign", campaign.toString()) 

    try {
      const  data = await axiosBackEndInstance.get(
        `${END_POINT_LOTS_BY_PRODUCTION_PROGRESS}/${campaign}`,
      )
      if(data && data.request.status === 200){        
        callback()
        return data.data
      }else{
        notification.error({
          message: 'Error', 
          description:'Hubo un error, por favor comuníquese con el administrador', 
          placement: 'topRight'
        })
      }
    } catch (newError) {
      const status =  await errorHandling(newError).request.status      
      console.log(newError);      
      callback()
    }
  }    
     
    return {
      getSowingProgress,
    };
};