import  { KeyedMutator } from "swr";
import { useAuthSWR } from './useAuthSWR';
import { END_POINT_GENERIC_MATERIAL } from '../Axios/ENDPOINTS';

export interface MaterialProps {
  id: number,
  code: string,
  gdus_to_flower: number,
  is_sterile : boolean,
  brand: {
    id: number,
    code: string
  },
  material_type: {
    id: number,
    code: string
  },
  female_material: {    
    id: number,
    code: string,
    gdus_to_flower: number,
    brand: number,
    material_type: {
      id: number,
      code: string
    },
  },
  male_material: {    
    id: number,
    code: string,
    gdus_to_flower: number,
    brand: number,
    material_type: {
      id: number,
      code: string
    },
  },
  suggested_sow_relation: {
    id: number,
    code: string,
    female_sow_relationship: number,
    male_sow_relationship: number
  },
  female_sow_gdus: any,
  male_1_sow_gdus: number,
  male_2_sow_gdus: number,
  male_3_sow_gdus: number
}


export interface ResponseGetMaterials{
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: MaterialProps[]  
}

export const useGetMaterials = () => {
   
  const {data, error, mutate }:{
    data: MaterialProps[], 
    error: any,
    mutate: KeyedMutator<any>
  } = useAuthSWR([END_POINT_GENERIC_MATERIAL])
  
  return {
    data,
    mutate,
    error
  };
};