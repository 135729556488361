
export interface UsersProps {
  id: number,
  user_code: string,
  name: string,
  lastname: string, 
  userType:{
    name: string,
    id: number,
  },
  phone: string
}


export interface ResponseGetUsers{
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: UsersProps[]  
}

export const useGetUsers = () => {

  let data = {
    count: 1,
    current_page: 1,
    next: '2',
    previous: 'ooo',
    results:[ 
        {
          id: 1,
          user_code: 'email@gmail.com',
          name: 'Pedro',
          lastname: 'Alfonso', 
          userType: {
            name: 'Administrador',
            id: 1,
          },
          phone: '341-2523688'
        }, 
        {
          id: 2,
          user_code: 'email@gmail.com',
          name: 'Alejandra',
          lastname: 'Gómez', 
          userType: {
            name: 'Coordinador',
            id: 1,
          },
          phone: '341-2523688'
        }, 
    ]
}
    
    // const {data, error, mutate }:{
    //   data: ResponseGetZone, 
    //   error: any,
    //   mutate: KeyedMutator<any>
    // } = useAuthSWR([URL])

    
    
    // useEffect(() => {
    //   if (!data && !error) {
    //     mutate();
    //   }
    // }, [data, mutate]);
  
    return {
      data,
    };
};