import { axiosBackEndInstance } from '../Axios';
import { END_POINT_GENERIC_BRAND } from '../Axios/ENDPOINTS';
import { notification } from 'antd';
import { useErrorHandling } from '../hooks/useErrorHandling';
import { BrandProps,  useGetBrands } from './useGetBrands';


export const useBrands= () => {

    const {errorHandling} = useErrorHandling()
    const { mutate } = useGetBrands()
     
    const getFilteredBrands = async (
      callback: Function,
      brand_code: string,
      pageValue?: string | undefined,        
      ): Promise<BrandProps[] | undefined> => {
      
      var params = new URLSearchParams();

      // pageValue && params.append("page", pageValue) 
      // brand_code && params.append("brand_code", brand_code)  

      try {
        const {
          data,
        }: { data: any } = await axiosBackEndInstance.get(
          END_POINT_GENERIC_BRAND,
          { 
            params
          }
        );
        callback()
        return data;
      } catch (newError) {
        console.log(newError);
        callback()
      }
    };

    const createBrands = async (
      callback: Function,
      newBrand:{
        code: string,
      }
      
      ): Promise<any> => {      
    try {
      const  data = await axiosBackEndInstance.post(
        END_POINT_GENERIC_BRAND, newBrand
      )
      const status =  errorHandling(data).request.status
      if( status === 201){
        mutate()
        callback()
        notification.success({
          message: 'Éxito', 
          description:'Marca creada con éxito', 
          placement: 'topRight'
      })
      }
    } catch (newError) {
      console.log(newError);
      callback()
    }
  };

  
  const editBrand = async (
    callback: Function,
    id: number,
    newData:{
      code: string,
    }
                            
  ): Promise<void> => {
      
    try {
      const data  = await axiosBackEndInstance.put(
        `${END_POINT_GENERIC_BRAND}/${id}`,newData
      );
      callback()   
      if(data.status === 200 || data.status === 201){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Marca editada con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      callback()
      let status = errorHandling(newError).request.status
      console.log('status: ' + status + ' ' + newError);
    }
  };

  const deleteBrand = async (
    callback: Function,
    id: number                            
  ): Promise<void> => {
      
    try {
      const data  = await axiosBackEndInstance.delete(
        `${END_POINT_GENERIC_BRAND}/${id}`, 
      );
      callback()   
      if(data.status === 200){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Marca eliminada con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      let status = errorHandling(newError).request.status
      console.log('status: ' + status + ' ' + newError);
      callback()
    }
  };

  return {
    getFilteredBrands,
    createBrands,
    editBrand,
    deleteBrand
  };
};
