import { notification } from 'antd';
import { axiosBackEndInstance } from '../Axios';
import { URL, END_POINT_GENERIC_ZONE } from '../Axios/ENDPOINTS';
import {  useGetZone } from './useGetZone';
import { useErrorHandling } from '../hooks/useErrorHandling';

export interface FilterValues{
  value: string,
  id: string,
  key: number,
}
export const useZone = () => {
     
  const { mutate } = useGetZone()
  const {errorHandling} = useErrorHandling()

  const getFilteredZone = async (
      callback: Function,
      description: string,           
      zone_code: string,
      pageValue?: string | undefined,        
      ) => {
      
      var params = new URLSearchParams();
      
      pageValue && params.append("page", pageValue)
      description && params.append("description", description)
      zone_code && params.append("endDate", zone_code)       
      
    try {
      const {
        data,
      }: { data: any } = await axiosBackEndInstance.get(
        URL,
        { 
          params
        }
      );
      callback()
      return data;
    } catch (newError) {
      console.log(newError);
      callback()
    }
  };

  const createZone = async (
    callback: Function,
    values:{
      code: string, 
      description: string,
      has_late_sow: boolean
    }
                            
  ): Promise<void> => {
      
    let newData ={
      code: values.code,
      description: values.description
    }
      
    try {
      const data  = await axiosBackEndInstance.post(
        END_POINT_GENERIC_ZONE, values
      );
      callback()   
      if(data.status === 200 || data.status === 201){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Zona creada con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      
      callback()
      let status = errorHandling(newError).request.status
      console.log('status: ' + status + ' ' + newError);
    }
  };

  const editZone = async (
    callback: Function,
    id: number,
    values:{
        code: string;
        description: string;
        has_late_sow: boolean
    }
                            
  ): Promise<void> => {
      
    let newData ={
      code: values.code,
      description: values.description,
    }
      
    try {
      const data  = await axiosBackEndInstance.put(
        `${END_POINT_GENERIC_ZONE}/${id}`,values
      );
      callback()   
      if(data.status === 200 || data.status === 201){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Zona editada con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      callback()
      let status = errorHandling(newError).request.status
      console.log('status: ' + status + ' ' + newError);
    }
  };

  const deleteZone = async (
    callback: Function,
    id: number                            
  ): Promise<void> => {
      
    try {
      const data  = await axiosBackEndInstance.delete(
        `${END_POINT_GENERIC_ZONE}/${id}`, 
      );
      callback()   
      if(data.status === 200){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Zona eliminada con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      let status = errorHandling(newError).request.status
      console.log('status: ' + status + ' ' + newError);
      callback()
    }
  };

 
  return {
    getFilteredZone,
    createZone,
    deleteZone,
    editZone
  };
};
