import  { KeyedMutator } from "swr";
import { useAuthSWR } from './useAuthSWR';
import { END_POINT_GENERIC_SOW_RELATION } from '../Axios/ENDPOINTS';

export interface SowRelationProps {
  id: number,
  code: string,
  female_sow_relationship: number,
  male_sow_relationship: number
}

export interface ResponseGetGetSowRelation{
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: SowRelationProps[]  
}

export const useGetSowRelation = () => {
   
  const {data, error, mutate }:{
    data: SowRelationProps[], 
    error: any,
    mutate: KeyedMutator<any>
  } = useAuthSWR([END_POINT_GENERIC_SOW_RELATION])

  return {
    data,
    mutate,
    error
  };
};