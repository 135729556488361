import React, { useEffect, useState } from 'react';
import './styles.scss'
import { Button, Modal, Row, Table } from 'antd';
import {
    EditOutlined,
    DeleteOutlined
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { BrandProps, useGetBrands } from '../../../Services/useGetBrands';
import { useBrands } from '../../../Services/useBrands';
import BrandForm from '../../Forms/BrandForm';


export interface DataType {
    index: number;
    id: number;
    code: string
}

const BrandsSection: React.FC = () => {

    const {data: dataApi} = useGetBrands()
    const { deleteBrand } = useBrands()
    
    const [form_brand] = useForm()
    const [showModal, setShowModal] = useState<boolean>(false)
    const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
    const [dataTable, setDataTable] = useState<DataType[]>([])
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [rowSelect, setRowSelect] = useState<{
        id: number ;
        code: string;
    } | undefined>(undefined)

    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 50, // Número de elementos por página
        total: 0, // Número total de elementos
    });
    
    useEffect(()=>{
        setLoadingTableData(true)
        if(dataApi){
            rowData() 
            setLoadingTableData(false)
        }
    },[dataApi])  
    
    const rowData = (data? : BrandProps[]) =>{
        let filterData: DataType[]= []
        let list: BrandProps[] = data ? data : dataApi
      
        if(list && list){
          list.map((data, index)=>{            
            filterData.push({
                index: index,
                id: data.id,
                code: data.code
            })
          })          
        }       
        setDataTable(filterData)
    }
 
    const callback = () =>{
        form_brand.resetFields() 
        setLoadingTableData(false)
        setShowConfirmDelete(false)
        setRowSelect(undefined)  
        setShowModal(false)   
    }

    const handleRenderer = (text: string, data: DataType) =>{
        return (
            <div className='zone_action_action_render'>
                <EditOutlined 
                    className='icons'
                    onClick={()=>{
                        setRowSelect({
                            id: data.id,
                            code: data.code,
                        })
                        setShowModal(true)
                    }}
                />
                <DeleteOutlined 
                    className='icons'
                    onClick={()=>{
                        setRowSelect({
                            id: data.id,
                            code: data.code,
                        })
                        setShowConfirmDelete(true)
                    }}
                    style={{marginLeft:'30px'}} />
            </div>  
        )      
    }

    
    const ModalConfirmDelete = () => {
        return(
            <Modal
                title="Eliminar"
                open={showConfirmDelete}
                onOk={()=>{
                    if(rowSelect && rowSelect.id){
                        deleteBrand(callback, rowSelect.id)
                    }}}
                onCancel={()=>setShowConfirmDelete(false)}
                okText="Eliminar"
                cancelText="Cancelar"
            >
                <p>¿Estás seguro de que desea eliminar {rowSelect?.code}?</p>
            </Modal>
        )
    }
    
    const columns: ColumnsType<DataType> = [
        {
          title: 'Nombre de marca',
          dataIndex: 'code',
          key: 'id',
        }, 
        {
            title: 'Acciones',
            dataIndex: 'id',
            render:  handleRenderer,
            key: 'id',
            width: 110,
            ellipsis: true,            
        },      
    ];
    
    return( 
        <>
            <ModalConfirmDelete/>
            {showModal && <BrandForm
                showModal={showModal} 
                handleCancelForm={callback}
                rowSelect={rowSelect}
                form_brand={form_brand}
            />}      
            <div className='zone_section_container'>                                  
                <Row
                    className='bodyContainer_title'
                >
                    <h2>Marcas</h2>                
                </Row>
                <Row 
                   className='header_row'
                >              
                    <Button
                    type='primary'
                    onClick={()=>{
                        setShowModal(true)
                        }}
                    >   Agregar marca
                    </Button>
                    
                </Row>                
                <Row 
                    className='bodyContent_container_table'
                >{
                    loadingTableData
                    ? <SpinerHorizontal/> 
                    : <Table 
                        style={{
                            width: '60%',
                        }}
                            columns={columns} 
                            dataSource={dataTable} 
                            scroll={{ y: 580 }} 
                            pagination={{
                                pageSize: 100,
                                showSizeChanger: false,                                      
                            }}
                        />    
                    } 
                </Row>             
            </div>
        </>
        
        
)};

export default BrandsSection;