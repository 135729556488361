import React, { useEffect, useState } from 'react';
import {
    ExclamationCircleOutlined
  } from '@ant-design/icons';
import { DayOfWork, DaysOfWork } from '../../../Services/useSowPlanificationView';
import { Area, CartesianGrid, Tooltip, ComposedChart, Legend, Line, ResponsiveContainer, XAxis, YAxis, Label } from 'recharts';


interface ResourceGraphProps{
  graphicData: DayOfWork[] | undefined
}

const ResourceGraph: React.FC<ResourceGraphProps> = ({
  graphicData
}) => {  

  useEffect(()=>{

  },[])
  
  return (
    <div className='box_filter'>
      <div style={{display:'flex'}}>
          <p>Gráfico de recursos</p>
          {/* <Tooltip
              style={{cursor:'pointer'}}
              title='Selecciona los datos que deseas visualizar en la tabla'
          >
              <ExclamationCircleOutlined 
                  style={{
                      color: '#fff384c5',
                      marginLeft: '10px'
                  }}
              />
          </Tooltip> */}
      </div>  
      <div className="sentinelGraph_chartContainers">
        <ResponsiveContainer width="80%" height="70%">
          <ComposedChart
            width={1000}
            height={450}
            data={graphicData}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            
            <Tooltip/>
            <CartesianGrid stroke="#4b4b4b" strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              angle={-45}
              tick={{ fontSize: 12 }}
              tickMargin={15}
              textAnchor="end"
            />
            <YAxis>
              <Label
                value="Cantidad de recursos"
                position="left"
                angle={-90}
                style={{ textAnchor: 'middle' }}
              />
            </YAxis>
            <Line
                name='Recursos'
                legendType="plainline"
                type="monotone"
                dataKey='resources'
                stroke= '#ffe600' //'#7429ff'
            />
            <Legend/>
          </ComposedChart>
        </ResponsiveContainer>
      </div>             
    </div>
  );
};

export default ResourceGraph;
  