import React, { useEffect, useState } from 'react';
import './styles.scss'
import { Col, Row, Table } from 'antd';
import {
    PlusCircleOutlined,
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import type {  TableProps } from 'antd/es/table';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { ResponseGetPerformanceByZones, useGetPerformanceByZone } from '../../../Services/useGetPerformanceByZone';
import { usePerformancebyZone } from '../../../Services/usePerformancebyZone';
import PerformanceByZoneForm, { PerformanceByZoneFormFilters } from '../../Forms/PerformanceByZoneForm';

export interface DataType {
    index: number;
    id: number;
    hybrid_id: number,
    hybrid_name: string,
    zone_id: number,
    zone_name: string,
    bags_per_hectare: number
}


const PerformanceByZoneSectionSection: React.FC = () => {

    const {data: infoHarcod} = useGetPerformanceByZone()
    
    const {getFilteredPerformancebyZone} = usePerformancebyZone()
    const [form_performance_by_Zone] = useForm()
    const [form_performance_by_Zone_filters] = useForm()
    const [showModal, setShowModal] = useState<boolean>(false)
    const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
    const [loadingFilters, setLoadingFilters] = useState(false)
    const [dataTable, setDataTable] = useState<DataType[]>([])

    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 50, // Número de elementos por página
        total: 0, // Número total de elementos
    });

    useEffect(()=>{
        setLoadingTableData(true)
        if(infoHarcod){
            rowData() 
            setLoadingTableData(false)
        }
    },[])    
    //},[infoHarcod])

    const rowData = (data? : ResponseGetPerformanceByZones) =>{
        let filterData: DataType[]= []
        let list: ResponseGetPerformanceByZones = data ? data : infoHarcod
      
        if(list && list.results){
          list.results.map((data, index)=>{            
            filterData.push({
                index: index,
                id: data.id,
                hybrid_id: data.hybrid.id,
                hybrid_name: data.hybrid.name,
                zone_id: data.zone.id,
                zone_name: data.zone.zone_code,
                bags_per_hectare: data.bags_per_hectare
            })
          })        
          setPagination({
            ...pagination,
            page: list.current_page,
            pageSize: 50,
            total: list.count,
          });       
        }       
        setDataTable(filterData)
    }

    const handlePageChange: TableProps<DataType>['onChange'] = (
        pagination,
        filters,
        sorter,
        extra
      ) => {
        // Actualizar la página actual y la cantidad de elementos por página
        let page = pagination.current;
        let pageSize = pagination.pageSize;
        if (page && pageSize) {
          setPagination({
            ...pagination,
            page: page,
            pageSize: pageSize,
            total: 0,
          });
        }
      };


    const handleGetPage = async (page?: number, loadingTable?: boolean) => {
        let values = form_performance_by_Zone_filters.getFieldsValue() 
        let filters: PerformanceByZoneFormFilters = values ? values : undefined 
        let pageNumber: number = page ? page : 1  
        loadingTable && setLoadingTableData(true)
        const data = await getFilteredPerformancebyZone( 
            callback,         
            filters.id,
            filters.hybrid.key,
            filters.zone.key,
            filters.bags_per_hectare,
            pageNumber.toString()
        )
        
        if (data) {
            rowData(data);         
        }
    };

    const callback = () =>{
        setLoadingFilters(false)
        setLoadingTableData(false)
    }

    const handleSeeAll =()=>{
        form_performance_by_Zone_filters.resetFields() 
        handleGetPage()        
    }
  
  
    const columns: ColumnsType<DataType> = [
        {
            title: 'Híbrido',
            dataIndex: 'hybrid_name',
            key: 'hybrid_id',
            ellipsis: true,
            onCell: (record, rowIndex) => {
                return {
                  onClick: () => {
                    onClickRow(record)
                  }
                };
              }
        },
        {
            title: 'Zona',
            dataIndex: 'zone_name',
            key: 'zone_id',
            ellipsis: true,
            onCell: (record, rowIndex) => {
                return {
                  onClick: () => {
                    onClickRow(record)
                  }
                };
              }
        },
        {
            title: 'Bolsas por hectáreas',
            dataIndex: 'bags_per_hectare',
            key: 'bags_per_hectare',
            ellipsis: true,
            onCell: (record, rowIndex) => {
                return {
                  onClick: () => {
                    onClickRow(record)
                  }
                };
              }
        },    
    ];
    
    const onClickRow = (record: DataType) =>{         
        // setSelectedAlertNotification(record)
        //setOpenAlertModal(true) 
    }  

    return( 
        <>
            <PerformanceByZoneForm
                showModal={showModal} 
                setShowModal={setShowModal}
                form_performance_by_Zone={form_performance_by_Zone}
            />      
            <div className='zone_section_container'>                                  
                <Row
                    style={{                    
                        display: 'flex', 
                        alignItems: 'center',
                        width: '100%',
                        borderBottom: '1px solid white'
                    }}
                >
                    <h2>Rendimiento por zona</h2>                
                </Row>
                <Row 
                    style={{
                        width: '100%',
                        marginTop: '20px',
                        paddingLeft:'30px',
                        marginLeft: '5px',
                    }}
                >
                    <Col 
                        span={2}
                    >
                        <h4
                        >Agregar rendimiento por zona</h4>
                    </Col>
                    <Col 
                        span={1} 
                        style={{
                            display: 'flex', 
                            alignItems: 'center',
                        }}>
                            <PlusCircleOutlined 
                                onClick={()=>{setShowModal(true)}}
                                style={{color:'#0072ce', fontSize: '15px'}}/>
                    </Col>
                    <Col span={19}>
                    </Col>
                </Row>
                <Row 
                    style={{
                        display:'flex',
                        justifyContent: 'center',
                        width: '100%',
                        height: '70%',
                        padding: '0px 30px',
                        
                        border: '1px solid  #1e2957'
                    }}>
                         {
                            loadingTableData
                            ? <SpinerHorizontal/> 
                            : <Table 
                                style={{
                                    width: '80%',
                                    marginTop: '3%'
                                }}
                                    columns={columns} 
                                    dataSource={dataTable}  
                                    pagination={{
                                        current: pagination.page,
                                        pageSize: 50,
                                        total: pagination.total,
                                        onChange: (e)=>{handleGetPage(e, true)},
                                        showSizeChanger: false,
                                    }}
                                    onChange={handlePageChange}
                                    scroll={{ y: 550 }}
                                />    
                            } 
                </Row>             
            </div>
        </>
        
        
)};

export default PerformanceByZoneSectionSection;