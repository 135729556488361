import moment from 'moment';
import { axiosBackEndInstance } from '../Axios';
import { END_POINT_GENERIC_LOT } from '../Axios/ENDPOINTS';
import { notification } from 'antd';
import { useErrorHandling } from '../hooks/useErrorHandling';
import { LotProps, useGetLot } from './useGetLot';

export const useLot= () => {

    const { errorHandling } = useErrorHandling()
    const { mutate } = useGetLot()    
     
    const getFilteredLots = async (
      callback: Function,
      Lot_code: string,
      field_id: number, 
      ha: number,
      contract_start_date: Date,
      contract_end_date: Date,
      pageValue?: string | undefined,        
      ): Promise<LotProps[] | undefined> => {
      
      var params = new URLSearchParams();

      let day: any = contract_start_date
      let end_day: any = contract_end_date
      const startDate = day && moment(day[0].$d).format('YYYY-MM-DD')
      const endDate = end_day && moment(end_day[0].$d).format('YYYY-MM-DD')

      pageValue && params.append("page", pageValue)
      startDate && params.append("startDate", startDate)
      endDate && params.append("endDate", endDate)
      field_id && params.append("field_id", field_id.toString())  
      Lot_code && params.append("Lot_code", Lot_code)  
      ha && params.append("ha", ha.toString())  

      try {
        const {
          data,
        }: { data: any } = await axiosBackEndInstance.get(
          END_POINT_GENERIC_LOT,
          { 
            params
          }
        );
        callback()
        return data;
      } catch (newError) {
        console.log(newError);
        callback()
      }
    };

    const createLot = async (
      callback: Function,
      newLots:{
        code: string,
        farm: number, 
        hectares: number,
        //contract_start_date: Date,
        //contract_end_date: Date
      }
      
      ): Promise<any> => {      
    try {
      const  data = await axiosBackEndInstance.post(
        END_POINT_GENERIC_LOT, newLots
      )
      callback()
      if(data.status === 200 || data.status === 201){   
        mutate()   
        notification.success({
          message: 'Éxito', 
          description:'Lote creado con éxito', 
          placement: 'topRight'
      })
      }
    } catch (newError) {
      callback()
      let status = errorHandling(newError).request.status
      console.log('Status ' + status);
      console.log(newError);
    }
  };

  const editLot = async (
    callback: Function,
    id: number,
    values:{
      code: string,
      farm: number,
      hectares: number,
      //contract_start_date: Date,
      //contract_end_date: Date
    }                            
  ): Promise<void> => {
              
    try {
      const data  = await axiosBackEndInstance.put(
        `${END_POINT_GENERIC_LOT}/${id}`, values
      );
      callback()   
      if(data.status === 200 || data.status === 201){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Lote editado con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {    
      callback()
      let status = errorHandling(newError).request.status
      console.log('Status ' + status);
      console.log(newError);
    }
  };

  const deleteLot = async (
    callback: Function,
    id: number                            
  ): Promise<void> => {
      
    try {
      const data  = await axiosBackEndInstance.delete(
        `${END_POINT_GENERIC_LOT}/${id}`, 
      );
      callback()   
      if(data.status === 200){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Lote eliminado con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      console.log(newError);
      callback()
      let status = errorHandling(newError).request.status
    }
  };

  return {
    getFilteredLots,
    createLot,
    editLot,
    deleteLot
  };
};
