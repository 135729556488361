import React, { useEffect, useState } from "react";
import './styles.scss';
import {  Form, Input, Modal, Select } from "antd";
import {  YieldByZoneFormProps } from "./types";
import { useGetHybrid } from "../../../Services/useGetHybrid";
import { useYieldByZone } from "../../../Services/useYieldByZone";
import { useGetZone } from "../../../Services/useGetZone";

export interface SelectValues{
    value: string,
    id: string,
    key: number,
}

export interface YieldByZoneFormFields{
    hybrid: SelectValues,
    zone: SelectValues, 
    bag_per_hectare: number,
    bag_per_hectare_late: number
}

export const YieldByZoneForm: React.FC< YieldByZoneFormProps> =({
    form_yield_by_zone,
    rowSelect,
    openForm,
    callback,    
})=>{

    useEffect(()=>{
        form_yield_by_zone.resetFields()
    },[])    

    const {data: hybrid} = useGetHybrid()
    const { data: zones } = useGetZone()
    const {createYieldByZone, editYieldByZone} = useYieldByZone()
    const { Option } = Select;
    
    const [loadingBtn, setLoadingBtn] = useState(false)  
     
    const handleCancel =()=>{  
        setLoadingBtn(false)
        callback()
    }

    const handleSave = async(values: YieldByZoneFormFields)=>{
             
        let newData = {
            hybrid: Number(values.hybrid.key),
            zone: Number(values.zone.key),
            bag_per_hectare: values.bag_per_hectare,
            bag_per_hectare_late: values.bag_per_hectare_late
        }
        if(rowSelect && rowSelect.id){
            await editYieldByZone(handleCancel, Number(rowSelect.id), newData)            
        }else{
            await createYieldByZone(handleCancel, newData)
        }
                       
    }    
    

    return(
        <div>
            <Modal
                title={rowSelect ? 'Editar Rendimiento de Zona' : 'Asignar rendimiento de zona'}
                open={openForm}
                onCancel={handleCancel} 
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={() => form_yield_by_zone.submit()}
            >
                <Form
                    form={form_yield_by_zone}
                    id='form_yield_by_zone'
                    onFinish={handleSave}
                    layout="vertical"                    
                >
                    <Form.Item
                        label='Zona'
                        name='zone'
                        initialValue={rowSelect ? {
                            value: rowSelect?.zone_code,
                            label: rowSelect?.zone_code ,
                            key: rowSelect?.zone_id
                        }: null}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        validateTrigger={['onChange', 'onBlur']}                        
                    >
                        <Select                           
                            labelInValue         
                            loading={!zones || zones.length === 0}
                            placeholder={!zones || zones.length === 0 ? 'Cargando zonas...' : 'Seleciona una zona'}                
                        >
                            {zones && zones.map((item) => (
                                <Option value={item.code} key={item.id} placeholder='Zona'>
                                {item.code}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item>                        
                    <Form.Item
                        label='Híbrido'
                        name='hybrid'
                        initialValue={rowSelect ? {
                            value: rowSelect?.hybrid_code,
                            label: rowSelect?.hybrid_code,
                            key: rowSelect?.hybrid_id
                        } : null}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                         <Select                        
                            labelInValue
                            loading={!hybrid || hybrid.length === 0}
                            placeholder={!hybrid || hybrid.length === 0 ? 'Cargando híbridos...' : 'Seleciona un híbrido'}                            
                        >
                            {hybrid && hybrid.map((item) => (
                                <Option value={item.code} key={item.id} placeholder='híbrido'>
                                {item.code}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item>                    
                    <Form.Item
                        label='Bolsas por hectárea'
                        name='bag_per_hectare'
                        initialValue={rowSelect?.bag_per_hectare}   
                         rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}                     
                       
                    >
                        <Input      
                            type="number"        
                            placeholder="Cantidad"
                        />
                    </Form.Item>
                    <Form.Item
                        label='Bolsas por hectárea (tardío)'
                        name='bag_per_hectare_late'
                        initialValue={rowSelect?.bag_per_hectare_late}   
                         rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}                     
                       
                    >
                        <Input      
                            type="number"        
                            placeholder="Cantidad"
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default YieldByZoneForm;