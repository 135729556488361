import React, { useContext, useEffect, useState } from 'react';
import {
  ContainerOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ReconciliationOutlined,
  PercentageOutlined
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import {   Menu as MenuAntd} from 'antd';
import './styles.scss'
import { useNavigate } from 'react-router';
import iconLotByProduction from '../../assets/icons/1.png';
import iconLotByProductionWhite from '../../assets/icons/2.png';
import iconBagOrder from '../../assets/icons/menu/icon_bag_order.svg';
import  iconBagDistribution from '../../assets/icons/menu/icon_bag_distribution.svg';
import  iconPlant from '../../assets/icons/menu/plant.svg';
import iconBagOrderWhite from '../../assets/icons/menu/icon_bag_order_white.svg';
import  iconBagDistributionWhite from '../../assets/icons/menu/icon_bag_distribution_white.svg';
import  iconPlantWhite from '../../assets/icons/menu/plant_white.svg';

import { UserContext } from '../../Contexts/UserContext/UsersContext';

type MenuItem = Required<MenuProps>['items'][number];

const Menu: React.FC = () => {

  const {setAccesToken, collapsed, setCollapsed, userData  } = useContext(UserContext); 
 
  const navigate = useNavigate();
  const [navbarHeight, setNavbarHeight] = useState<number>(window.innerHeight*0.913)  

  useEffect(() => {
    const handleResize = () => {
      setNavbarHeight(window.innerHeight*0.913)
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const [colorsIcons, setColorsIcons] = useState({
  sow_planification: false,
  bagDistribution: false,
  bagOrder:false,
  plant: false
})

const itemsStaff: MenuItem[] = [
  getItem('Menu', 'collapsed', collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />),//<PieChartOutlined style={{fontSize: ' 15px'}} />
  getItem('Ordenes de Bolsas', 'bag_order', <img src={colorsIcons.bagOrder ? iconBagOrderWhite : iconBagOrder } style={{marginLeft:'-3px', height: '20px', width: '20px'}} />),
  getItem('Distribución de Bolsas', 'bag_distribution', <img src={colorsIcons.bagDistribution? iconBagDistributionWhite: iconBagDistribution} style={{marginLeft:'-3px', height: '20px', width: '20px'}} />),
  getItem('Planificación de siembra', 'sow_planification_view', <img src={colorsIcons.sow_planification ? iconLotByProductionWhite : iconLotByProduction} style={{marginLeft:'-3px', height: '17px'}} />),
  getItem('Lotes en producción', 'lotByProduction',<img src={colorsIcons.plant ? iconPlantWhite : iconPlant} style={{marginLeft:'-3px', height: '17px'}} />  ),
  getItem('Reporte de avances', 'sowing_Progress', <PercentageOutlined />),

  getItem('Datos de siembra', 'sow_data',<ReconciliationOutlined />, [
    getItem('Rendimiento por zona', 'yield_by_zone'), 
    getItem('GDUs por zona', 'GDUbyZone'),
    getItem('GDUs Histórico', 'HistoricalGDUbyZone'),
    getItem('Floración de parentales', 'material_flowering_month'),
  ]),

  getItem('Administración', 'admin', <ContainerOutlined />, [
    getItem('Zona', 'zone'),
    getItem('Provincia', 'province'),
    getItem('Localidad', 'locality'),
    getItem('Dueños', 'owners'),
    getItem('Campos', 'farms'),
    getItem('Lotes', 'lot'),           
    getItem('Campañas', 'campaigns'),     
    getItem('Marcas', 'brands'),  
    getItem('Relación de siembra', 'sow_relation'),  
    getItem('Materiales', 'materials'),      
    getItem('Equipo de despanojado', 'detasseling-equipment'), 
  ]),
];

const itemsnotStaff: MenuItem[] = [
  getItem('Menu', 'collapsed', collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />),
  getItem('Ordenes de Bolsas', 'bag_order', <img src={colorsIcons.bagOrder ? iconBagOrderWhite : iconBagOrder } style={{marginLeft:'-3px', height: '20px', width: '20px'}} />),
  getItem('Distribución de Bolsas', 'bag_distribution', <img src={colorsIcons.bagDistribution? iconBagDistributionWhite: iconBagDistribution} style={{marginLeft:'-3px', height: '20px', width: '20px'}} />),
  getItem('Planificación de siembra', 'sow_planification_view', <img src={colorsIcons.sow_planification ? iconLotByProductionWhite : iconLotByProduction} style={{marginLeft:'-3px', height: '17px'}} />),
  getItem('Lotes en producción', 'lotByProduction',<img src={colorsIcons.plant ? iconPlantWhite : iconPlant} style={{marginLeft:'-3px', height: '17px'}} />  ),
  getItem('Reporte de avances', 'sowing_Progress', <PercentageOutlined />),  
];

const onClick: MenuProps['onClick'] = (e) => {
  const updateIcons = (activeKey: string) => {
    setColorsIcons({
      sow_planification: activeKey === 'sow_planification_view',
      bagDistribution: activeKey === 'bag_distribution',
      bagOrder: activeKey === 'bag_order',
      plant: activeKey === 'lotByProduction'
    });
  };
    switch(e.key){
      case 'collapsed':  
        toggleCollapsed();
        break;
      case 'bag_order':  
        navigate('/dashboard/bag_order');
        updateIcons(e.key);
        break;
      case 'zone':       
        navigate('/dashboard/zone');
        break;
      case 'GDUsHistory':
          navigate('/dashboard/GDUperZone');
          updateIcons(e.key);
          break;
      case 'history-GDUs':
          navigate('/dashboard/history-GDUs');
          break;    
      case 'province':
          navigate('/dashboard/province');
          break;
      case 'locality':
          navigate('/dashboard/locality');
          break;
      case 'users':
          navigate('/dashboard/users');
          break;
      case 'owners':
          navigate('/dashboard/owners');
          break;
      case 'farms':
          navigate('/dashboard/farms');
          break;
      case 'lot':
          navigate('/dashboard/lot');
          break;
      case 'campaigns':
          navigate('/dashboard/campaigns');
          break;
      case 'brands':
          navigate('/dashboard/brands');
          break;
      case 'materials':
          navigate('/dashboard/materials');
          break;
      case 'performanceByZone':
          navigate('/dashboard/performanceByZone');
          break;
      case 'detasseling-equipment':
          navigate('/dashboard/detasseling-equipment');
          break;
      case 'objet':
          navigate('/dashboard/objet');
          break;
      case 'plain':
          navigate('/dashboard/plain');
          break;
      case 'planification':
          navigate('/dashboard/planification');
          break;
      case 'sow':
          navigate('/dashboard/sow');
          break;
      case 'sow_relation':
          navigate('/dashboard/sow_relation');
          break;    
      case 'GDUbyZone':
            navigate('/dashboard/GDUbyZone');
            break;  
      case 'HistoricalGDUbyZone':
            navigate('/dashboard/HistoricalGDUbyZone');
            break;    
      case 'sow_planification_view':
            navigate('/dashboard/sow_planification_view');
            updateIcons(e.key);
            break;   
      case 'lotByProduction':
            navigate('/dashboard/lotByProduction');
            updateIcons(e.key);
            break;   
      case 'material_flowering_month':
            navigate('/dashboard/material_flowering_month');
            break;   
      case 'bag_distribution':
            navigate('/dashboard/bag_distribution');
            updateIcons(e.key);
            break;          
      case 'yield_by_zone':
            navigate('/dashboard/yield_by_zone');
            break;   
      case 'sowing_Progress':
            navigate('/dashboard/sowing_Progress');
            break;                                   
      case 'close':
          setAccesToken(undefined);
          navigate('/')
          break;              
      default:
      break;
    }
  };



  return (
    <div  
      style={{
         height: `${navbarHeight}px`
      }}
      className='menu_container'
    >
      <MenuAntd
        style={{
            zIndex: 200,
            borderRadius: '7px',
            width: collapsed ? '72px' : '256px'
        }}
        onClick={onClick}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={[]}
        mode="inline"
        theme="dark"
        inlineCollapsed={collapsed}
        items={userData?.is_staff ? itemsStaff : itemsnotStaff}
      />            
    </div>
  );
};

export default Menu;