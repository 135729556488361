import  { KeyedMutator } from "swr";
import { useAuthSWR } from './useAuthSWR';
import { END_POINT_GENERIC_FARM } from '../Axios/ENDPOINTS';
import { CityProps } from './useGetCity';
import { OwnersProps } from './useGetOwners';

export interface FarmProps {
  id: number,
  code: string,
  description: string,
  city: CityProps, 
  farm_owner: OwnersProps
  is_full_grower_farm: boolean,  
  contract_start_date: string,
  contract_end_date: string 
}


export interface ResponseGetFarms{
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: FarmProps[]  
}

export const useGetFarms = () => {
   
    const {data, error, mutate }:{
      data: FarmProps[], 
      error: any,
      mutate: KeyedMutator<any>
    } = useAuthSWR([END_POINT_GENERIC_FARM])
  
    return {
      data,
      mutate,
      error
    };
};