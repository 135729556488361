import React, { useContext, useState } from 'react';
import './styles.scss'
import { Col, Row, Table, Form, Select, Button, Tooltip } from 'antd';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { RootObject } from '../../../Services/useGetSowingProgress';
import { useForm } from 'antd/es/form/Form';
import { useGetZone } from '../../../Services/useGetZone';
import { useGetCampaigns } from '../../../Services/useGetCampaigns';
import {
    ExclamationCircleOutlined,
  } from '@ant-design/icons';
import { useSowingProgress } from '../../../Services/useSowingProgress';
import { StylesContext } from '../../../Contexts/UserContext/StylesContext';
import { END_POINT_LOTS_BY_PRODUCTION_PROGRESS_EXPORT } from '../../../Axios/ENDPOINTS';
import { useDownloadExcel } from '../../../Services/useDownloadExcel';



export interface DataType {
    index: string;
    project: string;
    required_to_show: string,
    planned_to_show: string,
    required_hectares: string;
    planned_hectares: string;
    planted_female: string;
    planted_male_1: number;
    planted_male_2: number;
    detasseleds_percentage: number;
    harvests_percentage: number;
    covered_percentage: string;
    required_bags: string;    
    required_has:string;
    planned_bags: string;
    planned_has: string;
    hectares_diference: string;
    bags_diference: number
}

const SowingProgress: React.FC = () => {

    //const {data: dataApi, error, mutate} = useGetShowingProgres()
    const {getSowingProgress} = useSowingProgress()
    const [ form_seeker ] = useForm()
    const { data: zones } = useGetZone()
    const { data: campaign } = useGetCampaigns()
    const { Option } = Select;
    const {adjustedTableHeigthScroll} = useContext(StylesContext)

    const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
    const {downloadEXCEL} = useDownloadExcel()
    const [loadingFiltersBtn, setLoadingFilterBtn] = useState(false)
    const [dataTable, setDataTable] = useState<DataType[]>([])
    const [campaignSelected, setCampaignSelected ] = useState<{
        value: string,
        label: string,
        key: number
    }>()  
    const [loadingExcel, setLoadingExcel] = useState<boolean>(false)   
    const [dataToFilters, setDataToFilsters] = useState<RootObject>()
    const [selectedZone, setSelectedZone] = useState<string>()
    
    const rowData = (data: RootObject, zone?: string) =>{
        let filterData: DataType[]= []    
        setDataToFilsters(data)
        let list = zone ? data.results_by_zone[zone] : data.results_by_hybrid 
       
        if(list){
            Object.keys(list).forEach((hybridCode, index) => {
                const hybridData = list[hybridCode];
    
                filterData.push({
                    index: index.toString(),
                    project: hybridCode,   
                    required_to_show: hybridData.required_to_show,
                    planned_to_show: hybridData.planned_to_show,
                    required_hectares: hybridData.required_hectares.toString(),  
                    planned_hectares: hybridData.planned_hectares.toString(), 
                    planted_female: hybridData.female_sows.toString(),
                    planted_male_1: hybridData.male_1_sows,
                    planted_male_2: hybridData.male_2_sows, 
                    detasseleds_percentage: hybridData.detasseleds_percentage,
                    harvests_percentage: hybridData.harvests_percentage,
                    covered_percentage: hybridData.covered_percentage.toString(),
                    required_bags: hybridData.required_bags,                    
                    required_has:hybridData.required_hectares.toFixed(),
                    planned_bags: hybridData.planned_bags,                    
                    planned_has: hybridData.planned_hectares.toString(),
                    hectares_diference:( Number(hybridData.planned_hectares) - Number(hybridData.required_hectares)).toFixed(2), 
                    bags_diference:  Number(hybridData.planned_bags) -Number(hybridData.required_bags) 
                });
            });
        }                   
        setDataTable(filterData)
    }
    
    
    const filter = ( type: 'planed' | 'hybrid') =>{
        let textAndValue: { text: string, value: string }[] = [];
        let uniqueValues = new Set();
        let list = selectedZone ? dataToFilters?.results_by_zone[selectedZone] : dataToFilters?.results_by_hybrid 

        if(list){
            Object.keys(list).forEach((hybridCode, index)=>{
                const hybridData = list && list[hybridCode] ;
                let code
                switch(type){
                    case 'hybrid':
                        code = hybridData && hybridData.hybrid_code
                        break;
                    case 'planed':
                        code =  hybridData && hybridData.covered_percentage.toFixed()
                        break;                   
                }
                                
                if( code && !uniqueValues.has(code)){
                    uniqueValues.add(code);
                    textAndValue.push({
                        text: code,
                        value: code
                    });
                }
            })
        }        
      
        textAndValue.sort((a, b) => Number(a.value) - Number(b.value));
        return textAndValue;
    }
 
    const columns: ColumnsType<DataType> = [
         
        {
            title: 'Híbrido',
            dataIndex: 'project',
            key: '1',
            width: 145,
            ellipsis: true,
            fixed: true,
            className: 'cursor_pointer',
            filters: filter('hybrid'),
            onFilter: (value, record) => typeof value === 'string' && record.project == value,
            filterSearch: true,
            sorter: (a, b) => a.project.localeCompare(b.project),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '% Planificado ',
            dataIndex: 'covered_percentage',
            key: '2',            
            width: 170,            
            rowScope:  'row', 
            render: (text)=> <>{Number(text).toFixed()}</>,
            filters: filter('planed'),
            onFilter: (value, record) => typeof value === 'string' && Number(record.covered_percentage).toFixed() == value,
            filterSearch: true,
            defaultSortOrder: 'descend',
            sorter: (a, b) => Number(a.covered_percentage) - Number(b.covered_percentage),       
        },
        {
            title: 'Bolsas',
            key: '3',
            width: 220,
            ellipsis: true,
            fixed: true,
            className: 'cursor_pointer',
            children:[
                {
                    title: 'Solicitado',
                    dataIndex: 'required_bags', 
                    key: '4',
                    width: 110,   
                    rowScope: 'row',   
                    sorter: (a, b) => Number(a.required_bags) - Number(b.required_bags),                                
                },
                {
                    title: 'Planificado',
                    dataIndex: 'planned_bags',
                    key: '5',
                    width: 110,                      
                    rowScope:  'row',  
                    sorter: (a, b) => Number(a.planned_bags) - Number(b.planned_bags),                         
                },
                {
                    title: 'Diferencia',
                    dataIndex: 'bags_diference',
                    key: '6',
                    width: 110, 
                    sorter: (a, b) => Number(a.bags_diference) - Number(b.bags_diference), 
                    render:(text, record)=>{
                        let dif = text
                        return(
                            <div
                                style={{color: dif < 0 ? 'red' : 'green'}}
                            >
                                {dif}
                            </div>
                        )
                    },                  
                }
            ]
        },  
        {
            title: 'Hectáreas',
            key: '7',
            width: 220,
            ellipsis: true,
            fixed: true,
            className: 'cursor_pointer',
            children:[
                {
                    title: 'Solicitado',
                    dataIndex: 'required_has',
                    key: '8',
                    width: 110,  
                    rowScope:  'row',  
                    sorter: (a, b) => Number(a.required_has) - Number(b.required_has),                  
                },
                {
                    title: 'Planificado',
                    dataIndex: 'planned_has',
                    key: '9',
                    width: 100,  
                    rowScope:  'row',
                    sorter: (a, b) => Number(a.planned_has) - Number(b.planned_has),                    
                },
                {
                    title: 'Diferencia',
                    dataIndex: 'hectares_diference',
                    key: '10',
                    sorter: (a, b) => Number(a.hectares_diference) - Number(b.hectares_diference),
                    render:(text, record)=>{
                        let dif = Number(text) 
                        return(
                            <div
                                style={{color: dif < 0 ? 'red' : 'green'}}
                            >
                                {dif}
                            </div>
                        )
                    },
                    width: 100,                    
                }
            ]
        },             
        {
            title: 'Sembrado',
            key: '11',
            children:[
                {
                    title:'Hembra',
                    dataIndex:'planted_female',
                    key: '12',
                    rowScope:  'row',
                    sorter: (a, b) => Number(a.planted_female) - Number(b.planted_female),
                },
                {
                    title:'Macho 1',
                    dataIndex:'planted_male_1',
                    key: '13',
                    sorter: (a, b) => Number(a.planted_male_1) - Number(b.planted_male_1),
                },
                {
                    title:'Macho 2',
                    dataIndex:'planted_male_2',
                    key: '14',
                    rowScope:  'row',
                    sorter: (a, b) => Number(a.planted_male_2) - Number(b.planted_male_2),
                }
            ]
        },
        {
            title: '% Desp',
            dataIndex: 'detasseleds_percentage',
            key: '15',
            sorter: (a, b) => Number(a.detasseleds_percentage) - Number(b.detasseleds_percentage),
        },    
        {
            title: '% Cos',
            dataIndex: 'harvests_percentage',
            key: '16',
            rowScope:  'row',
            sorter: (a, b) => Number(a.harvests_percentage) - Number(b.harvests_percentage),
        },         
    ];
  
 
  const callback = ()=>{
    setLoadingTableData(false)
    if(loadingTableData){
        setLoadingTableData(false)
    }
  }

  const handleShearch = async (values: {
        campaign:{
            label?: string,
            value?: string,
            key: string,
        },
        zone?:  {
            label?: string,
            value?: string,
            key: string,
        },
    },
    loading?: boolean,
    loadingFilters?: boolean
    ) =>{
        !loading && setLoadingTableData(true)
        loadingFilters && setLoadingFilterBtn(true)
        const data = await getSowingProgress(values.campaign.key, callback)
        if(data){
            if(values.zone){
                rowData(data, values.zone.label)
            }else{
                rowData(data)
            }
            // setZoneAndCampaignSelected({
            //     zone:  values.zone.key,
            //     campaign: values.campaign.key,
            //     first_date: data.first_date,
            //     last_date: data.last_date
            // })
        }
    }

  return( 
    <> 
        <div className='sowing_progress_section_container'>                                  
            <Row
                className='bodyContainer_title'
            >
                <h2>Reporte de avances</h2>                
            </Row>
                <Row
                    style={{
                        width: '100%',
                        padding:'20px 0px',
                        display: 'flex',
                        justifyContent: 'space-between'

                    }}
                >
                    <Col
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            paddingLeft: '10px',
                        }}
                    >
                        <Form
                            form={form_seeker}
                            id='form_seeker'
                            onFinish={handleShearch}
                            layout='inline'
                        >
                             <Form.Item
                                name='campaign'
                                rules={[
                                    {
                                        required: true,
                                        message:'Este campo es obligatorio'
                                    }
                                ]}
                            >
                                  <Select
                                    labelInValue
                                    loading={!campaign || campaign.length === 0}
                                    placeholder={!campaign || campaign.length === 0 ? 'Cargando campañas...' : 'Campaña'} 
                                    onChange={(values:{
                                        value: string,
                                        label: string,
                                        key: number
                                    })=>setCampaignSelected(values)}
                                >
                                    {campaign && campaign.map((item) => (
                                        <Option value={item.code} key={item.id}>
                                            {item.code}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='zone'
                            >
                                <Select
                                    labelInValue
                                    allowClear
                                    loading={!zones || zones.length === 0}
                                    placeholder={!zones || zones.length === 0 ? 'Cargando zonas...' : 'Zonas'} 
                                    onChange={(values:{value: string})=>{
                                        console.log(values)
                                        if(values && values.value){
                                            setSelectedZone(values.value)
                                        }else{
                                            setSelectedZone(undefined)
                                        }
                                      
                                    }}
                                >
                                    {zones && zones.map((item) => (
                                        <Option value={item.code} key={item.id}>
                                            {item.code}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                           
                            <Form.Item>
                                <Button
                                    type='primary'
                                    htmlType='submit'
                                    
                                >
                                    Buscar
                                </Button>
                                <Tooltip
                                    title= 'Puedes seleccionar una campaña para ver todas las zonas o filtrar por zona para ver una en específico.'
                                    placement='right'
                                >
                                    <ExclamationCircleOutlined style={{
                                        color:'orange',
                                        cursor: 'pointer',
                                        marginLeft: '10px'}}/>
                                </Tooltip>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col
                        style={{
                            marginTop: '-10px'
                        }}
                    >
                        <Button 
                            disabled={loadingExcel || !campaignSelected}
                            loading={loadingExcel}
                            onClick={()=>{
                                setLoadingExcel(true)
                                if(campaignSelected){
                                    downloadEXCEL(campaignSelected.key.toString(), setLoadingExcel, END_POINT_LOTS_BY_PRODUCTION_PROGRESS_EXPORT, `Reporte de avances - ${campaignSelected.label}`)
                                }
                            }} 
                            type="primary" 
                        >
                            Exportar excel
                        </Button>
                    </Col>
                </Row>     
            <Row 
                className='bodyContent_container_table'
            >
                {
                    loadingTableData
                    ? <SpinerHorizontal/> 
                    : <Table 
                        style={{
                            width: '95%',
                        }}
                        bordered
                        columns={columns} 
                        dataSource={dataTable}  
                        pagination={false}
                        scroll={{ y: adjustedTableHeigthScroll, x: 1500 }}
                    />    
                } 
            </Row>             
        </div>
    </>        
  )};

export default SowingProgress;