import React, { useContext } from "react";
import './styles.scss'
import { BodyContentProps } from "./types";
import { Route, Routes } from "react-router";
import Home from "../Model";
import ZoneSection from "../Sections/ZoneSection";
import GDUperZoneSection from "../Sections/GDUperZoneSection";
import GDUsHistorySection from "../Sections/GDUs_HistoricalSection";
import ProvinceSection from "../Sections/ProvinceSection";
import UserSection from "../Sections/UsersSection";
import OwnersSection from "../Sections/OwnersSection";
import FarmsSection from "../Sections/FarmsSection";
import CampaignsSection from "../Sections/CampaignsSection";
import BrandsSection from "../Sections/BrandSection";
import MaterialsSection from "../Sections/MaterialsSection";
import PerformanceByZoneSection from "../Sections/PerformanceByZoneSection";
import DetasseledEquipmentSection from "../Sections/DetasseledEquipmentSection";
import CitySection from "../Sections/CitySection";
import LotsSection from "../Sections/LotsSection";
import ProfileSections from "../ConfigurationsSections/ProfileSection";
import SowRelationSection from "../Sections/SowRelationSection";
import BagOrderSection from "../Sections/BagOrderSection";
import GDUs_HistoricalSection from "../Sections/GDUs_HistoricalSection";
import SowPlanificationViewSection from "../Sections/SowPlanificationViewSection";
import LotByProduction from "../Sections/LotByProduction";
import MaterialsFloweringByMonth from "../Sections/MaterialsFloweringByMonth";
import BagDistribution from "../Sections/BagDistribution";
import YieldByZoneSection from "../Sections/YieldByZone";
import { UserContext } from "../../Contexts/UserContext/UsersContext";
import SowingProgress from "../Sections/SowingProgress";

export const BodyContent: React.FC<BodyContentProps> = ()=>{
    const {collapsed} = useContext(UserContext)
    return(
        <div className="bodyContent_container" style={{  width: collapsed ? 'calc(100% - 112px)' :'calc(100% - 296px)'}}>
            <Routes>
                <Route path='home' element={<Home/>}/> 
                <Route path='zone' element={<ZoneSection/>}/> 
                <Route path='GDUperZone' element={<GDUperZoneSection/>}/> 
                <Route path='history-GDUs' element={<GDUsHistorySection/>}/> 
                <Route path='province' element={<ProvinceSection/>}/>
                <Route path='locality' element={<CitySection/>}/> 
                <Route path='users' element={<UserSection/>}/> 
                <Route path='owners' element={<OwnersSection/>}/> 
                <Route path='farms' element={<FarmsSection/>}/> 
                <Route path='lot' element={<LotsSection/>}/> 
                <Route path='campaigns' element={<CampaignsSection/>}/> 
                <Route path='brands' element={<BrandsSection/>}/> 
                <Route path='materials' element={<MaterialsSection/>}/> 
                <Route path='performanceByZone' element={<PerformanceByZoneSection/>}/> 
                <Route path='detasseling-equipment' element={<DetasseledEquipmentSection/>}/> 
                <Route path='profile' element={<ProfileSections/>}/> 
                <Route path='sow_relation' element={<SowRelationSection/>}/> 
                <Route path='bag_order' element={<BagOrderSection/>}/>
                <Route path='yield_by_zone' element={<YieldByZoneSection/>}/>
                <Route path='GDUbyZone' element={<GDUperZoneSection/>}/>
                <Route path='HistoricalGDUbyZone' element={<GDUs_HistoricalSection/>}/>
                <Route path='sow_planification_view' element={<SowPlanificationViewSection/>}/>
                <Route path='lotByProduction' element={<LotByProduction/>}/>
                <Route path='material_flowering_month' element={<MaterialsFloweringByMonth/>}/>
                <Route path='bag_distribution' element={<BagDistribution/>}/>
                <Route path='sowing_progress' element={<SowingProgress/>}/>
            </Routes>
        </div>
    )

}

export default BodyContent

