import React, { useEffect, useState } from 'react';
import './styles.scss'
import {  Button, Modal, Row, Table } from 'antd';
import {
    EditOutlined,
    DeleteOutlined
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import type {  TableProps } from 'antd/es/table';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { ProvinceProps, useGetProvince } from '../../../Services/useGetProvince';
import { useProvince } from '../../../Services/useProvince';
import ProvinceForm, { ProvinceFormFilters } from '../../Forms/ProvinceForm';


export interface DataType {
    index: number;
    id: number;
    name: string;
}

const ProvinceSection: React.FC = () => {

    const {data: dataApi} = useGetProvince()
    
    const {
        getFilteredProvince,
        deleteProvince,
    } = useProvince()
    const [form_province] = useForm()
    const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
    const [loadingFilters, setLoadingFilters] = useState(false)
    const [dataTable, setDataTable] = useState<DataType[]>([])
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [rowSelect, setRowSelect] = useState<{
        show: boolean ;
        id: number | undefined;
        name: string  | undefined;
        type: 'edit' | 'create' | 'delete' | undefined
    }>({
        show: false,
        id: undefined,
        name: undefined,
        type: undefined
    })

    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 50, // Número de elementos por página
        total: 0, // Número total de elementos
    });

    useEffect(()=>{
        setLoadingTableData(true)
        if(dataApi){
            rowData() 
            setLoadingTableData(false)
        }
    },[dataApi])       
    

    const rowData = (data? : ProvinceProps[]) =>{
        let filterData: DataType[]= []
        let list: ProvinceProps[] = data ? data : dataApi
        let values: ProvinceFormFilters = form_province.getFieldsValue() 
      
        if(list && list){
          list.map((data, index)=>{            
            filterData.push({
                index: index,
                id: data.id,
                name: data.name,

            })
          })            
        }       
        setDataTable(filterData)
    }

    const handlePageChange: TableProps<DataType>['onChange'] = (
        pagination,
        filters,
        sorter,
        extra
      ) => {
        // Actualizar la página actual y la cantidad de elementos por página
        let page = pagination.current;
        let pageSize = pagination.pageSize;
        if (page && pageSize) {
          setPagination({
            ...pagination,
            page: page,
            pageSize: pageSize,
            total: 0,
          });
        }
      };


    const handleGetPage = async (page?: number, loadingTable?: boolean) => {
        let values = form_province.getFieldsValue() 
        let filters: ProvinceFormFilters = values ? values : undefined 
        let pageNumber: number = page ? page : 1  
        loadingTable && setLoadingTableData(true)
        const data = await getFilteredProvince( 
            callback,         
            filters.name,     
            pageNumber.toString()
        )
        
        if (data) {
            rowData(data);         
        }
    };

    const callback = () =>{
        setLoadingFilters(false)
        setLoadingTableData(false) 
        setShowConfirmDelete(false)      
    }

    const handleSeeAll =()=>{
        form_province.resetFields() 
        handleGetPage()        
    }

    
    const handleCancelForm = ()=>{
        form_province.resetFields() 
        setRowSelect({
            show: false,
            id: undefined,
            name: undefined,
            type: undefined
        })        
    }

    const handleRenderer = (text: string, data: DataType) =>{
        return (
            <div className='zone_action_action_render'>
                <EditOutlined 
                    className='icons'
                    onClick={()=>{                        
                        setRowSelect({
                            show: true,
                            id: data.id,
                            name: data.name,
                            type: 'edit'
                        })
                    }}
                />
                <DeleteOutlined 
                    className='icons'
                    onClick={()=>{
                        setRowSelect({
                            show: false,
                            id: data.id,
                            name: data.name,
                            type: 'delete'
                        })
                        setShowConfirmDelete(true)
                    }}
                    style={{marginLeft:'30px'}} />
            </div>  
        )      
    }

    const ModalConfirmDelete = () => {
        return(
            <Modal
                title="Eliminar"
                open={showConfirmDelete}
                onOk={()=>{if(rowSelect && rowSelect.id){deleteProvince(callback, rowSelect.id)}}}
                onCancel={()=>setShowConfirmDelete(false)}
                okText="Eliminar"
                cancelText="Cancelar"
            >
                <p>¿Estás seguro de que desea eliminar la provincia: {rowSelect?.name}?</p>
            </Modal>
        )
    }
  
    const columns: ColumnsType<DataType> = [
        {
            title: 'Provincia',
            dataIndex: 'name',
            key: 'id',
            ellipsis: true,
            onCell: (record, rowIndex) => {
                return {
                  onClick: () => {
                    onClickRow(record)
                  }
                };
              }
        },
        {
            title: 'Acciones',
            dataIndex: 'id',
            render:  handleRenderer,
            key: 'id',
            width: 110,
            ellipsis: true,            
        },
      
    ];
    
    const onClickRow = (record: DataType) =>{         
        // setSelectedAlertNotification(record)
        //setOpenAlertModal(true) 
    }  

    return( 
        <>
            <ModalConfirmDelete/>
            {rowSelect.show && <ProvinceForm 
                form_province={form_province}
                rowSelect = {rowSelect} 
                handleCancelForm={handleCancelForm}
            /> }
            <div className='zone_section_container'>                                  
                <Row
                   className='bodyContainer_title'
                >
                    <h2>Provincias</h2>                
                </Row>
                <Row 
                   className='header_row'
                >              
                    <Button
                    type='primary'
                    onClick={()=>{
                            setRowSelect({
                                show: true,
                                id: undefined,
                                name: undefined,
                                type: 'create'
                            })
                        }}
                    >   Agregar Provincia
                    </Button>             
                </Row>                
                <Row 
                    className='bodyContent_container_table'
                >
                    {
                        loadingTableData
                        ? <SpinerHorizontal/> 
                        : <Table 
                            style={{
                                width: '80%',
                            }}
                            columns={columns} 
                            dataSource={dataTable}  
                            pagination={{
                                pageSize: 50,
                                showSizeChanger: false,
                                
                            }}
                            scroll={{ y: 580 }}
                        />    
                    } 
                </Row>             
            </div>
        </>
        
        
)};

export default ProvinceSection;