import { useContext } from 'react';
import { KeyedMutator } from "swr";
import { useAuthSWR } from './useAuthSWR';
import { END_POINT_GENERIC_ZONE } from '../Axios/ENDPOINTS';
import { UserContext } from '../Contexts/UserContext/UsersContext';

export interface ZoneProps {
  id: number;
  code: string,
  has_late_sow: boolean;
  description: string
}

export interface ResponseGetZone{
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: ZoneProps[]  
}

export const useGetZone = () => {

    const {setAccesToken} = useContext(UserContext)
       
    const {data, error, mutate }:{
      data: ZoneProps[] , 
      error: any,
      mutate: KeyedMutator<any>
    } = useAuthSWR([END_POINT_GENERIC_ZONE])    
  
    return {
      data,
      mutate, 
      error
    };
};