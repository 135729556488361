import React, { useEffect, useState } from 'react';
import './styles.scss'
import {  Col, Row, Table } from 'antd';
import {
    PlusCircleOutlined,
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import type {  TableProps } from 'antd/es/table';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { UsersSectionProps } from './types';
import { ResponseGetUsers, useGetUsers } from '../../../Services/useGetUsers';
import { useUsers } from '../../../Services/useUsers';
import UserForm, { UserFormFilters } from '../../Forms/UserForm';


export interface DataType {
    index: number;
    id: number;
    user_code: string,
    name: string,
    lastname: string, 
    userType_name:string,
    userType_id: number,
    phone: string
}


const UserSection: React.FC<UsersSectionProps> = () => {

  const {data: infoHarcod} = useGetUsers()    
  const {getFilteredUsers} = useUsers()

  const [form_user] = useForm()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
  const [loadingFilters, setLoadingFilters] = useState(false)
  const [dataTable, setDataTable] = useState<DataType[]>([])

  const [pagination, setPagination] = useState({
      page: 1,
      pageSize: 50, // Número de elementos por página
      total: 0, // Número total de elementos
  });

  useEffect(()=>{
      setLoadingTableData(true)
      if(infoHarcod){
          rowData() 
          setLoadingTableData(false)
      }
  },[])    
  //},[infoHarcod])

  const rowData = (data? : ResponseGetUsers) =>{
    let filterData: DataType[]= []
    let list: ResponseGetUsers = data ? data : infoHarcod
    let values: UserFormFilters = form_user.getFieldsValue() 
    
    if(list && list.results){
      list.results.map((data, index)=>{            
        filterData.push({
            index: index,
            id: data.id,
            user_code: data.user_code,
            name: data.name,
            lastname: data.lastname, 
            userType_name: data.userType.name,
            userType_id: data.userType.id,
            phone: data.phone
        })
      })        
      setPagination({
        ...pagination,
        page: list.current_page,
        pageSize: 50,
        total: list.count,
      });       
    }       
    setDataTable(filterData)
  }

  const handlePageChange: TableProps<DataType>['onChange'] = (
      pagination,
      filters,
      sorter,
      extra
  ) => {
    // Actualizar la página actual y la cantidad de elementos por página
    let page = pagination.current;
    let pageSize = pagination.pageSize;
    if (page && pageSize) {
      setPagination({
        ...pagination,
        page: page,
        pageSize: pageSize,
        total: 0,
      });
    }
  };


  const handleGetPage = async (page?: number, loadingTable?: boolean) => {
      let values = form_user.getFieldsValue() 
      let filters: UserFormFilters = values ? values : undefined 
      let pageNumber: number = page ? page : 1  
      loadingTable && setLoadingTableData(true)
      const data = await getFilteredUsers( 
          callback,         
          filters.id,
          filters.user_code,
          filters.name,
          filters.lastname,
          filters.userType.key,
          pageNumber.toString()
      )
      
      if (data) {
          rowData(data);         
      }
  };

  const callback = () =>{
      setLoadingFilters(false)
      setLoadingTableData(false)
  }

  const handleSeeAll =()=>{
      form_user.resetFields() 
      handleGetPage()        
  }
  
  
  const columns: ColumnsType<DataType> = [
      {
          title: 'Usuario',
          dataIndex: 'user_code',
          key: 'id',
          ellipsis: true,
          onCell: (record, rowIndex) => {
              return {
                onClick: () => {
                  onClickRow(record)
                }
              };
            }
      },
      {
          title: 'Código de localidadNombre',
          dataIndex: 'name',
          key: 'name',
          ellipsis: true,
          onCell: (record, rowIndex) => {
              return {
                onClick: () => {
                  onClickRow(record)
                }
              };
            }
      },
      {
          title: 'Apellido',
          dataIndex: 'lastname',
          key: 'lastname',
          ellipsis: true,
          onCell: (record, rowIndex) => {
              return {
                onClick: () => {
                  onClickRow(record)
                }
              };
            }
      },
      {
          title: 'Tipo de usuario',
          dataIndex: 'userType_name',
          key: 'userType_id',
          ellipsis: true,
          onCell: (record, rowIndex) => {
              return {
                onClick: () => {
                  onClickRow(record)
                }
              };
            }
      },
      {
          title: 'Teléfono',
          dataIndex: 'phone',
          key: 'phone',
          ellipsis: true,
          onCell: (record, rowIndex) => {
              return {
                onClick: () => {
                  onClickRow(record)
                }
              };
            }
      },    
  ];
    
  const onClickRow = (record: DataType) =>{         
      // setSelectedAlertNotification(record)
      //setOpenAlertModal(true) 
  }  

  return( 
    <>
      <UserForm
          showModal={showModal} 
          setShowModal={setShowModal}
          form_user={form_user}
      />      
      <div className='zone_section_container'>                                  
          <Row
            className='bodyContainer_title'
          >
              <h2>Usuarios</h2>                
          </Row>
          <Row 
              style={{
                  width: '100%',
                  marginTop: '20px',
                  paddingLeft:'30px',
                  marginLeft: '5px',
              }}
          >
              <Col 
                  span={2}
              >
                  <h4
                  >Nuevo Usuario</h4>
              </Col>
              <Col 
                  span={1} 
                  style={{
                      display: 'flex', 
                      alignItems: 'center',
                  }}>
                    <PlusCircleOutlined 
                      onClick={()=>{setShowModal(true)}}
                      style={{color:'#0072ce', fontSize: '15px'}}
                    />
              </Col>
              <Col span={19}>
              </Col>
          </Row>
          <Row 
            className='bodyContent_container_table'
          >
            {
              loadingTableData
              ? <SpinerHorizontal/> 
              : <Table 
                  style={{
                      width: '80%',
                      marginTop: '3%'
                  }}
                  columns={columns} 
                  dataSource={dataTable}  
                  pagination={{
                      current: pagination.page,
                      pageSize: 50,
                      total: pagination.total,
                      onChange: (e)=>{handleGetPage(e, true)},
                      showSizeChanger: false,
                  }}
                  onChange={handlePageChange}
                  scroll={{ y: 550 }}
                />    
              } 
          </Row>             
      </div>
    </>                
  )
};

export default UserSection;