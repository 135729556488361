import React, { useEffect, useState } from "react";
import './styles.scss';
import { Form, Input, Modal, Select } from "antd";
import { SowRelationFormProps } from "./types";
import { useOwners } from "../../../Services/useOwners";
import { SowRelationValues, useSowRelation } from "../../../Services/useSowRelation";

export interface FilterValues{
    value: string,
    id: string,
    key: number,
  }

export const SowRelationForm: React.FC<SowRelationFormProps> =({
    form_sow_relation,
    rowSelect,
    handleCancelForm,
    showModal
})=>{

    useEffect(()=>{
        form_sow_relation.resetFields()
    },[])
    
    const [loadingBtn, setLoadingBtn] = useState(false)
    const {createSowRelation, editSowRelation} = useSowRelation()
  
    const handleCancel =()=>{     
        handleCancelForm() 
        setLoadingBtn(false)
    }

    const handleSave = async(values: SowRelationValues)=>{
        if(showModal.type === 'create'){
            await createSowRelation(handleCancel, values)
        }else if(showModal.type === 'edit' &&  rowSelect && rowSelect.id){
            await editSowRelation(handleCancel, rowSelect.id, values)
        }        
    }    
 

    return(
        <div>
            <Modal
                title={showModal.type === 'edit' ? 'Editar Relación' : 'Nueva Relación'}
                open={showModal.show}
                onCancel={handleCancel}   
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={() => form_sow_relation.submit()}
            >
                <Form
                    form={form_sow_relation}
                    id='form_user'
                    onFinish={handleSave}
                    layout="vertical"
                    
                >
                    <Form.Item
                        label='Código de Relación de siembra'
                        name='code'
                        initialValue={rowSelect?.code}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                            <Input
                                placeholder="Código"
                            />

                    </Form.Item>    
                    <Form.Item
                        label='Relación de siembra hembra'
                        name='female_sow_relationship'
                        initialValue={rowSelect?.female_sow_relationship}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Input
                            placeholder="Unidad"
                        />
                    </Form.Item>       
                    <Form.Item
                        label='Relación de siembra macho'
                        name='male_sow_relationship'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        initialValue={rowSelect?.male_sow_relationship}
                    >
                        <Input
                            placeholder="Apellido"
                        />
                    </Form.Item>                                  
                </Form>
            </Modal>
        </div>
    )
}

export default SowRelationForm;