import React, {
    createContext,
    useState,
  } from 'react';
import { DataType } from '../../Components/Sections/SowPlanificationViewSection/types'; 

  class VoidContext implements PlanificationContext {
    get zoneAndCampaignSelected(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setZoneAndCampaignSelected(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get dataTable(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setDataTable(): never {
      throw new Error('Cannot consume context outside of provider');
    }    
    get resources(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setResources(): never {
      throw new Error('Cannot consume context outside of provider');
    } 
  }

  interface PlanificationContext {
    zoneAndCampaignSelected:{
      zone: number[],
      campaign: string,
      first_date: string,
      last_date: string,
      is_full_grower: 0 | 1
    } | undefined
    setZoneAndCampaignSelected: Function,
    dataTable: DataType[] | undefined,
    setDataTable: Function,
    resources: ResourcesProps, 
    setResources: Function
  }

  interface propsWithChildren {
    children: React.ReactNode
  }

  // export interface ResourcesProps {
  //   id:number,
  //   data:{
  //     date: string,
  //     quantity: number
  //   }
  // }

  
export interface Resource {
  date: string,
  quantity: number
}


  export interface ResourcesProps {
    [key: string]: Resource[];
}

  export const PlanificationContext = createContext<PlanificationContext>(new VoidContext());
  export const PlanificationContextProvider= ({ children }: propsWithChildren): JSX.Element => {

    const [ dataTable, setDataTable ] = useState<DataType[] | undefined>([])
    const [resources, setResources] = useState<ResourcesProps>({});
    const [zoneAndCampaignSelected, setZoneAndCampaignSelected] = useState<{
      zone: number[],
      campaign: string,
      first_date: string,
      last_date: string,
      is_full_grower: 0 | 1
  } | undefined>()

 
    return (
      <PlanificationContext.Provider value={{
        zoneAndCampaignSelected,
        setZoneAndCampaignSelected,
        dataTable,
        setDataTable,
        resources, 
        setResources
      }}>{children}</PlanificationContext.Provider>
    );
  };



  