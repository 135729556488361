import React from 'react';
import { Button, Checkbox,  Tooltip } from 'antd';
import type { CheckboxProps, GetProp } from 'antd';
import {
    ExclamationCircleOutlined
  } from '@ant-design/icons';
  
type CheckboxValueType = GetProp<typeof Checkbox.Group, 'value'>[number];

const CheckboxGroup = Checkbox.Group;

interface BoxFilterProps{
    checkedList: CheckboxValueType[],
    setCheckedList: Function,    
    setDoRefresh: Function,
    doRefresh: boolean,
    loadingFiltersBtn: boolean
}

const BoxFilter: React.FC<BoxFilterProps> = ({
    checkedList,
    setCheckedList,
    setDoRefresh,
    doRefresh,
    loadingFiltersBtn
}) => {  

  const plainOptions = ['Puller', 'Cutter', 'Manual work', 'gdus'];
  const checkAll = plainOptions.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;

  const onChange = (list: CheckboxValueType[]) => {
    console.log('onChange')
    setCheckedList(list);   
  };

  const filter =()=>{
    console.log('filter')
    setDoRefresh(!doRefresh  )
  }

  const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
    console.log('onCheckAllChange')
    setCheckedList(e.target.checked ? plainOptions : []);
  };

  return (
    <div className='box_filter'>
         <div style={{display:'flex'}}>
                <p>Vista de floración</p>
                <Tooltip
                    style={{cursor:'pointer'}}
                    title='Selecciona los datos que deseas visualizar en la tabla'
                >
                    <ExclamationCircleOutlined 
                        style={{
                            color: '#fff384c5',
                            marginLeft: '10px'
                        }}
                    />
                </Tooltip>
            </div>      
      <Checkbox  style={{marginTop:'10px'}}  indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
        SELECCIONAR TODOS
      </Checkbox>      
      <CheckboxGroup 
        className='box_filter_group'
        options={plainOptions} 
        value={checkedList} 
        onChange={onChange} />
        <Button
            style={{    
                marginTop: '20px',
            }}
            type='primary'
            onClick={filter}
            loading={loadingFiltersBtn}
        >
            Filtrar
        </Button>
    </div>
  );
};

export default BoxFilter;
  