import { KeyedMutator } from "swr";
import { useAuthSWR } from './useAuthSWR';
import { END_POINT_GENERIC_CITY } from '../Axios/ENDPOINTS';
import { ZoneProps } from './useGetZone';

export interface CityProps {
  id: number;
  name: string,
  zip_code: string, 
  province:{
    id: number,
    name: string
  },
  zone: ZoneProps 
}

export interface ResponseGetLocality{
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: CityProps[]  
}

export const useGetCity = () => {
    
    const { data, error, mutate }:{
      data: CityProps[], 
      error: any,
      mutate: KeyedMutator<any>
    } = useAuthSWR([END_POINT_GENERIC_CITY])

  
    return {
      data,
      mutate,
      error
    };
};