import React, { useEffect, useState } from "react";
import './styles.scss';
import {  Form, Input, Modal, Select } from "antd";
import { LotsFormProps } from "./types";
import { useLot } from "../../../Services/usetLot";
import { useGetFarms } from "../../../Services/useGetFarms";
import { FieldsValues } from "../MaterialForm";

export interface LotValues{
    value: string,
    id: string,
    key: number,
  }
export interface LotsFormFilters {
    id: number
    code: string,
    field_id: number, 
    ha: number,
    contract_start_date: Date,
    contract_end_date: Date
}

export interface LotFormFields{
    code: string,
    field_id: number, 
    ha: number,
    contract_start_date: Date,
    contract_end_date: Date
}

export const LotsForm: React.FC<LotsFormProps> =({
    form_lot,
    rowSelect,
    handleCancelForm
})=>{

    useEffect(()=>{
        form_lot.resetFields()
    },[])
    
    const [loadingBtn, setLoadingBtn] = useState(false)

    const {createLot, editLot} = useLot()
    const { data: farms } = useGetFarms()
    
    const { Option } = Select;
     
    const handleCancel =()=>{     
        handleCancelForm() 
        setLoadingBtn(false)
    }

    const handleSave = async(values: {
        code: string,
        farm: FieldsValues, 
        hectares: number,
    })=>{
        let newData = {
            code: values.code,
            farm: values.farm.key, 
            hectares: values.hectares,
        }
        if(rowSelect.type === 'create'){
            await createLot(handleCancel, newData)
        }else if(rowSelect.type === 'edit' && rowSelect.id){
            await editLot(handleCancel, rowSelect.id, newData)
        }        
    }     
    

    return(
        <div>
            <Modal
                title={rowSelect.type === 'edit' ? 'Editar Lote' : 'Nuevo Lote'}
                open={rowSelect.show}
                onCancel={handleCancel} 
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={() => form_lot.submit()}
            >
                <Form
                    form={form_lot}
                    id='form_Lot'
                    onFinish={handleSave}
                    layout="vertical"
                    
                >
                    <Form.Item
                        label='Nombre de Lote'
                        name='code'
                        initialValue={rowSelect?.code}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                            <Input                            
                                defaultValue={rowSelect?.code}
                                placeholder="Código"
                            />

                    </Form.Item>   
                    <Form.Item
                        label='Campo'
                        name='farm'
                        initialValue={{
                            value: rowSelect?.farm_description,
                            label: rowSelect?.farm_description,
                            key: rowSelect?.farm_id
                        }}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                         <Select
                            showSearch
                            defaultValue={{
                                value: rowSelect?.farm_description,
                                label: rowSelect?.farm_description,
                                key: rowSelect?.farm_id
                            }}
                            labelInValue  
                            loading={!farms || farms.length === 0}
                            placeholder={!farms || farms.length === 0 ? 'Cargando campo...' : 'Seleciona un campo'}                           
                        >
                            {farms && farms.map((item) => (
                                <Option value={item.code} key={item.id} placeholder='Campo'>
                                {item.code}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item>   
                    <Form.Item
                        label='Hectáreas'
                        name='hectares'
                        initialValue={Number(rowSelect?.hectares)}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Input
                            defaultValue={Number(rowSelect?.hectares)}
                            type="number"
                            placeholder="ha"
                        />
                    </Form.Item>                                         
                </Form>
            </Modal>
        </div>
    )
}

export default LotsForm;