
import { KeyedMutator } from "swr";
import { useAuthSWR } from './useAuthSWR';
import { END_POINT_LOTS_BY_PRODUCTION_BAG_ORDER } from '../Axios/ENDPOINTS';


export interface BagDistributionProps{
  id: number,
  bag_order: number,
  zone: {
    id: number,
    code: string,
    description: string
  },
  assigned_percentage: string,
  yield_by_zone: number
}

export interface BagOrderProps{
  id: number,
  hybrid?: {
    id: number,
    code: string,
    gdus_to_flower: number,
    brand: {
      id: number,
      code: string
    },
    material_type?: {
      id: number,
      code: string
    },
    female_material?: {
      id: number,
      code: string,
      gdus_to_flower: number,
      brand: number,
      material_type: {
        id: number,
        code: string
      }
    },
    male_material?: {
      id: number,
      code: string,
      gdus_to_flower: number,
      brand: number,
      material_type: {
        id: number,
        code: string
      }
    },
    suggested_sow_relation?: {
      id: number,
      code: string,
      female_sow_relationship: number,
      male_sow_relationship: number
    },
    quantity: number,
    female_sow_gdus?: number,
    male_1_sow_gdus?: number,
    male_2_sow_gdus?: number,
    male_3_sow_gdus?: number
  },
  campaign?: {
    id: number,
    code: string,
    start_date: string,
    end_date: string
  },
  quantity?: number,
  female_availability?: string,
  female_coment?: string,
  male_availability?: string,
  male_coment?: string,
  sterility_percentage?: string,
  bag_distribution_set?: BagDistributionProps[]
  total_allocated_percentage?: number,
  total_allocated_bags?: number,
  total_allocated_has?: number,
  total_planned_bags_percentage?: number,
}

export interface ResponseBagOrder{
  total_quantity: number,
  assigned_total_quantity: number,
  total_planned_bags: number,
  volume_vs_planned_percentage: number,
  bag_orders: BagOrderProps[]  
}

export interface ResponseGetBagOrder{
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: BagOrderProps[]  
}

export const useGetBagOrder = () => {
   
  const {data, error, mutate }:{
    data: ResponseBagOrder, 
    error: any,
    mutate: KeyedMutator<any>
  } = useAuthSWR([END_POINT_LOTS_BY_PRODUCTION_BAG_ORDER])
  

  return {
    data,
    mutate,
    error
  };
};