import {useContext} from 'react';
import './styles.scss';
import Login from '../../Components/Login';
import { UserContext } from '../../Contexts/UserContext/UsersContext';
import AnimationLoading from '../../Components/Spiner';
import StartupBackground from '../../Components/StartupBackground';

export const AccessPage = ()=> {

    const { loading} = useContext(UserContext)    
    
  return (
      <div className='accessPages-container'> 
        {loading && <AnimationLoading/>}
        <StartupBackground/>
        <div className='accessPages_login'>
          <Login/>  
        </div>           
      </div>
    
  );
}

export default AccessPage;