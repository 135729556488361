import { useContext, useState } from 'react';
import { notification } from 'antd';
import { defineMessages } from 'react-intl.macro';
import { useIntl } from 'react-intl';
import { axiosBackEndInstance } from '../Axios';
import { END_POINT_LOTS_BY_PRODUCTION_PROGRESS_EXPORT } from '../Axios/ENDPOINTS';

const scope = 'hooks.use-downloadExcelActivities';

export const messages = defineMessages({
  notificationType: {
    id: `${scope}.notificationType`,
    defaultMessage: 'Descarga',
  },
  downloadPDFError: {
    id: `${scope}.downloadPDFError`,
    defaultMessage: 'No se pudo descargar el archivo excel',
  },
  downloadPDFSuccess: {
    id: `${scope}.downloadPDFSuccess`,
    defaultMessage: 'Archivo excel descargado con éxito',
  },
});

export const useDownloadExcel = (
 
): {
  downloadEXCEL: ( ids: string, setLoadingExcel: Function, URL:string, title: string, forParameters?: number[] ) => Promise<void>;
  error: any;
} => {
  const [error, setError] = useState<any>();

  const intl = useIntl();

  const downloadEXCEL = async (ids: string, setLoadingExcel: Function, URL: string, title: string, forParameters?: number[]) => {


    var params = new URLSearchParams();
    forParameters && params.append("zones", forParameters.join(',')) 
   
    let url = `${URL}/${ids}`
    console.log(url)
    try {
      const response = await axiosBackEndInstance.get(
        `${URL}/${ids}`, // ${1}/${0}
        {
          params,
          responseType: 'blob',
        }
      );

      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
  
      // Limpia el DOM
      if(link.parentNode) {link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);}
      setLoadingExcel(false)

      notification.success({
        message: intl.formatMessage(messages.notificationType),
        description: intl.formatMessage(messages.downloadPDFSuccess),
        placement: 'topRight',
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: intl.formatMessage(messages.notificationType),
        description: intl.formatMessage(messages.downloadPDFError),
        placement: 'topRight',
      });
      setLoadingExcel(false);
    }
    
  };

  return {
    downloadEXCEL,
    error,
  };
};
