import React, { useEffect, useState } from "react";
import './styles.scss';
import { Form, Input, Modal, Select } from "antd";
import { OwnerFormProps } from "./types";
import { useOwners } from "../../../Services/useOwners";

export interface FilterValues{
    value: string,
    id: string,
    key: number,
  }
export interface OwnerFormFilters {
    id: number,
    owner_code: string,
    name: string,
    lastname: string, 
}

export const OwnerForm: React.FC<OwnerFormProps> =({
    form_owner,
    rowSelect,
    handleCancelForm,
})=>{

    useEffect(()=>{
        form_owner.resetFields()
    },[])

    
    const [loadingBtn, setLoadingBtn] = useState(false)
    const {createOwner, editOwner} = useOwners()
  
    const handleCancel =()=>{     
        handleCancelForm() 
        setLoadingBtn(false)
    }

    const handleSave = async(values: { 
        code: string,
        name: string,
        last_name: string, 
        phone: string
    })=>{
        if(rowSelect.type === 'create'){
            await createOwner(handleCancel, values)
        }else if(rowSelect.type === 'edit' && rowSelect.id){
            await editOwner(handleCancel, rowSelect.id, values)
        }        
    }    
 

    return(
        <div>
            <Modal
                title={rowSelect.type === 'edit' ? 'Editar Dueño' : 'Nuevo Dueño'}
                open={rowSelect.show}
                onCancel={handleCancel}   
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={() => form_owner.submit()}
            >
                <Form
                    form={form_owner}
                    id='form_user'
                    onFinish={handleSave}
                    layout="vertical"
                    
                >
                    <Form.Item
                        label='Código de Dueño'
                        name='code'
                        initialValue={rowSelect?.code}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                            <Input
                             defaultValue={rowSelect?.code}
                                placeholder="Código"
                            />

                    </Form.Item>    
                    <Form.Item
                        label='Nombre'
                        name='name'
                        initialValue={rowSelect?.name}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Input
                            defaultValue={rowSelect?.name}
                            placeholder="Nombre"
                        />
                    </Form.Item>       
                    <Form.Item
                        label='Apellido'
                        name='last_name'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        initialValue={rowSelect?.last_name}
                    >
                        <Input
                            defaultValue={rowSelect?.last_name}
                            placeholder="Apellido"
                        />
                    </Form.Item>  
                    <Form.Item
                        label='Teléfono'
                        name='phone'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        initialValue={rowSelect?.phone}
                    >
                        <Input
                            placeholder="Teléfono"
                            defaultValue={rowSelect?.phone}
                        />
                    </Form.Item>                      
                </Form>
            </Modal>
        </div>
    )
}

export default OwnerForm;