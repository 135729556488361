import moment from 'moment';
import { axiosBackEndInstance } from '../Axios';
import { END_POINT_GENERIC_MATERIAL_FLOWERING_BY_MONTH } from '../Axios/ENDPOINTS';
import { notification } from 'antd';
import { useErrorHandling } from '../hooks/useErrorHandling';
import { useGetMaterialFloweringMonth } from './useGetMaterialFloweringMonth';
import { useErrorHandlingReturn400 } from '../hooks/useErrorHandlingReturn400';


export const useMaterialFloweringByMonth= () => {

    //const {errorHandling} = useErrorHandling()
    const {errorHandling} = useErrorHandlingReturn400()
    const {mutate} = useGetMaterialFloweringMonth()     

  const createMaterialFloweringbyMonth = async (
    callback: Function,
    newData:{
      month: number,
      material: number,
      zone: number | undefined,
      gdus_until_beginning_of_flowering: string,
      gdus_until_50_percent_of_flowering: string,
      gdus_until_beginning_of_pollen: string,
      gdus_until_50_percent_of_pollen: string,
    }      
    ): Promise<any> => {      
    try {
      const  data = await axiosBackEndInstance.post(
        END_POINT_GENERIC_MATERIAL_FLOWERING_BY_MONTH, newData
      )
      callback()   
      if(data.status === 200 || data.status === 201){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'GDUs de floración de material creados con éxito', 
          placement: 'topRight'
        })
      }  
    } catch (newError: any) {
      callback()      
      let status = errorHandling(newError).request.status
      if(status == 400){
        const errorMessage =
        newError.response && newError.response.data
          ? JSON.stringify(newError.response.data)
          : 'Unknown Error';
        if(errorMessage.includes('CODE 460')){
          notification.warning({
            message: 'Ya existe un GDU asignado para ese mes y ese Material', 
            placement: 'topRight'
          })
        }
      }
      console.log('status: ' + status + ' ' + newError);
    }
  };

  const editMaterialFloweringbyMonth  = async (
    callback: Function,
    id: number,
    newData:{
      month: number,
      material: number,
      zone: number | undefined,
      gdus_until_beginning_of_flowering: string,
      gdus_until_50_percent_of_flowering: string,
      gdus_until_beginning_of_pollen: string,
      gdus_until_50_percent_of_pollen: string,
    }
                            
  ): Promise<void> => {
      
    try {
      const data  = await axiosBackEndInstance.put(
        `${END_POINT_GENERIC_MATERIAL_FLOWERING_BY_MONTH}/${id}`,newData
      );
      callback()   
      if(data.status === 200 || data.status === 201){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'GDUs de floración de material editado con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      callback()
      let status = errorHandling(newError).request.status
      console.log('status: ' + status + ' ' + newError);
    }
  };

  const deleteMaterialFloweringbyMonth = async (
    callback: Function,
    id: number                            
  ): Promise<void> => {
      
    try {
      const data  = await axiosBackEndInstance.delete(
        `${END_POINT_GENERIC_MATERIAL_FLOWERING_BY_MONTH}/${id}`, 
      );
      callback()   
      if(data.status === 200){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'GDUs de floración eliminados con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      let status = errorHandling(newError).request.status
      console.log('status: ' + status + ' ' + newError);
      callback()
    }
  };

  return {
    createMaterialFloweringbyMonth,
    editMaterialFloweringbyMonth,
    deleteMaterialFloweringbyMonth
  };
};
