import { axiosBackEndInstance } from '../Axios';
import { END_POINT_GENERIC_PROVINCE,  } from '../Axios/ENDPOINTS';
import { ProvinceProps, useGetProvince } from './useGetProvince';
import { notification } from 'antd';
import { useErrorHandling } from '../hooks/useErrorHandling';


export const useProvince= () => {

    const {errorHandling} = useErrorHandling()

    const {mutate} = useGetProvince()
     
    const getFilteredProvince = async (
      callback: Function,
      province_id: string, 
      pageValue?: string | undefined,        
      ): Promise<ProvinceProps[] | undefined> => {
      
      var params = new URLSearchParams();
      pageValue && params.append("page", pageValue)
      province_id && params.append("province", province_id)  

      try {
        const {
          data,
        }: { data: any } = await axiosBackEndInstance.get(
          END_POINT_GENERIC_PROVINCE,
          { 
            params
          }
        );
        callback()
        return data;
      } catch (newError) {
        console.log(newError);
        callback()
        // throw newError;
      }
    };

    const createProvince = async (
      callback: Function,
      value: {name: string} 
      ) => {      
    try {
      const  data = await axiosBackEndInstance.post(
        END_POINT_GENERIC_PROVINCE, value
      )
      callback()
      if(data.status === 200 || data.status === 201){        
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Provincia creada con éxito', 
          placement: 'topRight'
      })
      }
    } catch (newError) {
      console.log(newError);
      callback()
      let status = errorHandling(newError).request.status
    }
  };

  const editProvince = async (
    callback: Function,
    id: number,
    value: {name: string}
                            
  ): Promise<void> => {
           
    try {
      const data  = await axiosBackEndInstance.put(
        `${END_POINT_GENERIC_PROVINCE}/${id}`,value
      );
      callback()   
      if(data.status === 200){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Provincia editada con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      console.log(newError);
      callback()
      let status = errorHandling(newError).request.status
    }
  };

  const deleteProvince = async (
    callback: Function,
    id: number                            
  ): Promise<void> => {
      
    try {
      const data  = await axiosBackEndInstance.delete(
        `${END_POINT_GENERIC_PROVINCE}/${id}`, 
      );
      callback()   
      if(data.status === 200){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Provincia eliminada con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      console.log(newError);
      callback()
      let status = errorHandling(newError).request.status
    }
  };

  return {
    getFilteredProvince,
    createProvince,
    editProvince,
    deleteProvince
  };
};
