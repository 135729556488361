import  { KeyedMutator } from "swr";
import { useAuthSWR } from './useAuthSWR';
import { END_POINT_LOTS_BY_PRODUCTION } from '../Axios/ENDPOINTS';
import { LotProps } from './useGetLot';
import { CampaignProps } from './useGetCampaigns';
import { Hybrid } from './useSowPlanificationView';
import { SowRelationProps } from "./useGetSowRelation";

export interface MaleMaterial {

}

export interface LotByProductionProps  {
  id: number,
  lot: LotProps,
  campaign: CampaignProps,
  hybrid: Hybrid, 
  tentative_sow_date: string,
  sow_date: string,  
  male_1_sow_date: string;
  male_2_sow_date: string;  
  male_3_sow_date: string; 
  harvest_date: string,
  detasseled_date: string,
  female_sow_gdus: number,
  male_1_sow_gdus: number,
  male_2_sow_gdus: number,
  male_3_sow_gdus: number,
  observations: string
  sow_relation?: SowRelationProps
}


export interface ResponseGetMaterials{
  count: number,
  current_page: number,
  next: string,
  previous: string
//  results: MaterialProps[]  
}

export const useGetLotByProduction = () => {
   
  const {data, error, mutate }:{
    data: LotByProductionProps[], 
    error: any,
    mutate: KeyedMutator<any>
  } = useAuthSWR([`${END_POINT_LOTS_BY_PRODUCTION}/${1}`]) 
  
  return {
    data,
    mutate,
    error
  };
};