import React, { useEffect, useState } from "react";
import './styles.scss';
import { Checkbox, Form, Input, Modal } from "antd";
import { FormZoneProps } from "./types";
import { useZone } from "../../../Services/useZone";

export interface ZoneFormFilters {
    zone_code: string,
    description: string
}

export const ZoneForm: React.FC<FormZoneProps> =({
    form_zone,
    rowSelect,
    handleCancelForm,
})=>{
   
    useEffect(()=>{
        form_zone.resetFields()
    },[])
    
    const {createZone, editZone} = useZone()
    const [loadingBtn, setLoadingBtn] = useState(false)
 
    const handleCancel =()=>{     
        handleCancelForm() 
        setLoadingBtn(false)
    }

    const handleSave = async(values: {code: string, description: string, has_late_sow: boolean})=>{
        console.log(values)
        if(rowSelect.type === 'create'){
            await createZone(handleCancel, values)
        }else if(rowSelect.type === 'edit' && rowSelect.id){
            await editZone(handleCancel, rowSelect.id, values)
        }        
    }    

    return(
        <div>
            <Modal
                title={rowSelect.type === 'edit' ? 'Editar Zona' : 'Nueva Zona'}
                open={rowSelect.show}
                onCancel={handleCancel}  
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={() => form_zone.submit()}
            >
                <Form
                    form={form_zone}
                    id='form_zone'
                    onFinish={handleSave}
                    layout="vertical"
                    
                >
                    <Form.Item
                        label='Código de zona'
                        name='code'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        initialValue={rowSelect?.code}
                    >
                            <Input
                               defaultValue={rowSelect?.code}
                            />

                    </Form.Item>
                    <Form.Item
                        label='Descripción'
                        name='description'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        initialValue={rowSelect?.description}
                    >
                            <Input
                                 defaultValue={rowSelect?.description}
                            />

                    </Form.Item>
                    <Form.Item
                        label='Es tardío'
                        name='has_late_sow'
                        valuePropName='checked'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                        initialValue={rowSelect?.has_late_sow == 'true' ? true : false}
                    >
                        <Checkbox
                            style={{marginLeft: '10px'}}
                        />

                    </Form.Item>
                </Form>


            </Modal>
        </div>
    )
}

export default ZoneForm;