import React, {  useContext, useEffect, useState } from 'react';
import './styles.scss'
import { Button, Col, Form, Input, Row, Select, Tooltip, Popover   } from 'antd';
import {
    ExclamationCircleOutlined,
    LoadingOutlined
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import { useGetZone } from '../../../Services/useGetZone';
import { infoByCalendar } from './types';
import dayjs from 'dayjs';
import waiting from '../../../assets/illustrations/waiting.gif' //#0A9EDC
import { UserContext } from '../../../Contexts/UserContext/UsersContext';
import {  useHistoricalGDUs, historicalGDUs } from '../../../Services/useHistoricalGDUs';

export interface DataType {
    index: number;
    id: number;
    zone_name: string,
    zone_id: number,
    date: string,
    gdu: number
}

interface CalendarByGDUProps {
    dataZonesFiltered: { [key: string]: infoByCalendar[] };
    setDataZonesFiltered: Function;
    zone: {
        key: string
    }
}

const CalendarByGDU: React.FC<CalendarByGDUProps> = ({ 
    zone,
    dataZonesFiltered, 
    setDataZonesFiltered
}) => {

    const { editCalendar,setEditCalendar } = useContext(UserContext)
    const { editHistoricalGDUs, createHistoricalGDUs } = useHistoricalGDUs()

    const monthNames = {
        '01': 'Enero',
        '02': 'Febrero',
        '03': 'Marzo',
        '04': 'Abril',
        '05': 'Mayo',
        '06': 'Junio',
        '07': 'Julio',
        '08': 'Agosto',
        '09': 'Septiembre',
        '10': 'Octubre',
        '11': 'Noviembre',
        '12': 'Diciembre'
      };

    const handleChangeInput = async (e: React.ChangeEvent<HTMLInputElement>, info: infoByCalendar, years_month: string)=>{  
        if(info && !info.id && e.target.value){
            const fechaFormateada =  `${info.years}-${info.month.toString().padStart(2, '0')}-${info.day.toString().padStart(2, '0')}`;
           
            const newValues = await createHistoricalGDUs(zone.key, fechaFormateada, e.target.value)
            if(newValues){
                const updatedList  = dataZonesFiltered[years_month].map(item => {
                    const date = new Date(newValues.historical_date);
                    const day = date.getUTCDate();
                   
                    if (day && Number(item.day) == day ) {// busco el years-month y luego el day para cambiar el valor del gdu y asignar un valor a id, por si el uduario llega a querer cambiar nuevamente el valor, ahora correspondería hacer un edit        
                        const years = date.getUTCFullYear();
                        const month = date.getUTCMonth() + 1;
                        item.years = years;
                        item.month = month;
                        item.day = day;
                        item.gdu = newValues.gdu;
                        item.id = newValues.id;  
                        item.date = newValues.historical_date;
                    }
                    return item
                });
                setDataZonesFiltered((prevState: { [key: string]: infoByCalendar[] }) => ({
                    ...prevState,
                    [years_month]: updatedList
                }));
            }            
        }else if(info && info.id && e.target.value){
            const newValues = await editHistoricalGDUs(info.id, e.target.value) 
            if(newValues){
                const updatedList  = dataZonesFiltered[years_month].map(item => {
                    const date = new Date(newValues.historical_date);
                    const day = date.getUTCDate();
                   
                    if (day && Number(item.day) == day ) {// busco el years-month y luego el day para cambiar el valor del gdu y asignar un valor a id, por si el uduario llega a querer cambiar nuevamente el valor, ahora correspondería hacer un edit        
                        const years = date.getUTCFullYear();
                        const month = date.getUTCMonth() + 1;
                        item.years = years;
                        item.month = month;
                        item.day = day;
                        item.gdu = newValues.gdu;
                        item.id = newValues.id;  
                        item.date = newValues.historical_date;
                    }
                    return item
                });
                setDataZonesFiltered((prevState: { [key: string]: infoByCalendar[] }) => ({
                    ...prevState,
                    [years_month]: updatedList
                }));
            } 
        }        
    }

    return (
        <>   
        <Row 
        className='row_scroll'
        style={{
            display:'flex',
            justifyContent: 'center',
            alignItems:'center',
            width: '100%',
            height: '78%',
            padding: '0px 30px',                        
           // border: '1px solid  #1e2957',
            overflowY:'scroll'
        }}>
            {Object.keys(dataZonesFiltered).map((years_month) => (
                <div 
                    style={{                        
                        padding: '10px 10px', 
                    }}      
                    key={years_month}>
                    <Row
                        style={{
                            background: 'cornflowerblue',
                            color: 'white',
                            padding: '3px 0px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderTopLeftRadius: '5px',
                            borderTopRightRadius: '5px',
                            width: '330px',
                        }}
                    >
                        {`${monthNames[years_month.slice(5) as keyof typeof monthNames]}`}
                    </Row>
                    <Row
                        style={{
                            width: '330px',
                            backgroundColor: 'white',
                            borderBottomLeftRadius: '5px',
                            borderBottomRightRadius: '5px',
                        }}
                    >
                        {dataZonesFiltered[years_month].map((info, index) => (
                            <Col
                                key={info.id}
                                span={3}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    borderBottom: '1px solid #e7e7e7',
                                    borderRight: '1px solid #e7e7e7',
                                   
                                }}
                            >
                                <Row
                                    style={{
                                        backgroundColor: '#f1f1f1',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '20px',
                                        fontSize: '11px',
                                    }}
                                >
                                    {info.day}
                                </Row>
                                <Row
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '25px',
                                    }}
                                >
                                    { editCalendar ?                                    
                                        <Input
                                            style={{
                                                color: 'cornflowerblue',
                                                height: '25px',
                                                padding:'0px',
                                                textAlign: 'center'                                                                                                                                  
                                            }}
                                            onBlur={(e) => handleChangeInput(e, info, years_month)}
                                            defaultValue={info?.gdu?.toString()}                                          
                                        />
                                        :      
                                        <Popover content='Habilita el modo edición haciendo click en el botón editar' trigger="click">
                                            <p style={{
                                                margin: '0px', 
                                                color: info.gdu !== undefined ?  'cornflowerblue' : 'transparent' //pongo el valor cero entrasparente para que me tome el popover
                                            }}>
                                               {info?.gdu?.toString() ?? ' 0'} 
                                            </p>
                                        </Popover>                                   
                                        
                                    }                                
                                </Row>
                            </Col>
                        ))}
                    </Row>
                </div>
            ))}            
            </Row>
        </>
    );
};

const GDUs_HistoricalSection: React.FC = () => {

    const [form_select_zone] = useForm()

    const { getHistoricalGDUs } = useHistoricalGDUs()
    const { setEditCalendar, editCalendar } = useContext(UserContext)
    const {data: zones} = useGetZone()
    const { Option } = Select;
   
    const [creatingList, setCreatingList] = useState<boolean>(false)
    const [dataZonesFiltered, setDataZonesFiltered] = useState<{[clave: string]: infoByCalendar[]} | undefined>()   

    useEffect(()=>{
        setEditCalendar(false)
    },[])


    const handleShearch = async (values: {
        zone:  {
            label: string,
            value: string,
            key: string,
        }
    }) =>{
        const data = await getHistoricalGDUs(values.zone.key)
    }

    const createList = async (data: historicalGDUs[]) =>{        
        const datosAgrupados: {[clave: string]: infoByCalendar[]} = {};
        data.forEach(objeto => {
            const fecha = new Date(objeto.historical_date);
            const years = fecha.getUTCFullYear();
            const month = fecha.getUTCMonth() + 1;
            const day = fecha.getUTCDate();
        
            const clave: string = `${years}-${month.toString().padStart(2, '0')}`;
        
            if (!datosAgrupados[clave]) {
                datosAgrupados[clave] = [];
            }        
            datosAgrupados[clave].push({
                years: years,
                month: month,
                day: day,
                gdu: objeto.gdu,
                id: objeto.id,
                date: objeto.historical_date
            });
        });
        //ordeno las fechas del mes por día(de menor a mayor)
        for (const clave in datosAgrupados) {
            if (datosAgrupados.hasOwnProperty(clave)) {
                datosAgrupados[clave].sort((a, b) => a.day - b.day);
            }
        }
        for (const clave in datosAgrupados) {
            if (datosAgrupados.hasOwnProperty(clave)) {
                const [years, month] = clave.split('-').map(Number);
                const daysInMonth = new Date(years, month, 0).getDate();
    
                // Crear un arreglo con los days existentes en el month
                const daysExistente = datosAgrupados[clave].map(item => item.day);
    
                // Iterar sobre todos los days del month
                for (let i = 1; i <= daysInMonth; i++) {
                    // Si el day no existe en el arreglo de days existentes, lo agrego en su lugar correspondiente
                    if (!daysExistente.includes(i)) {
                        const index = datosAgrupados[clave].findIndex(item => item.day > i);
                        const nuevoObjeto = {
                            years: years,
                            month: month,
                            day: i,
                            gdu: undefined, 
                            id: undefined, 
                        };
                        // Insertar el nuevo objeto en su lugar correspondiente
                        if (index !== -1) {
                            datosAgrupados[clave].splice(index, 0, nuevoObjeto);
                        } else {
                            datosAgrupados[clave].push(nuevoObjeto);
                        }
                    }
                }
            }
        }  
        return datosAgrupados       
    }

    const onChangeZone = async (values: {
        label: string,
        value: string,
        key: string,
        
    }) =>{
        setCreatingList(true)
        setEditCalendar(false)
        if(dataZonesFiltered){
            setDataZonesFiltered(undefined)
        }        
        if(values.key){
            const data = await getHistoricalGDUs(values.key)
            if (data){
                const datosAgrupados = await createList(data) 
                if(datosAgrupados && data.length > 0){
                    filterDataByDate(datosAgrupados, data[0].historical_date)
                }
                else if(datosAgrupados){
                    filterDataByDate(datosAgrupados)
                }
            }           
        }               
    }    

    const filterDataByDate = (datosAgrupados: { [clave: string]: infoByCalendar[] }, date?: string) => {

        //Siempre en el hitórico voy a mostrar desde Enero a Diciembre
        date = date ? date : '2024-01-01'
        const fecha = new Date(date);
        const years = fecha.getUTCFullYear();
        const startDate = dayjs( `${years}-01-01`);
        const endDate = dayjs(`${years}-12-01`);
        const monthList = generateMonthList(startDate, endDate);
    
        const newData: { [clave: string]: infoByCalendar[] } = {};
    
        monthList.forEach(month => {
            if (!datosAgrupados.hasOwnProperty(month)) {
                const [year, monthNum] = month.split('-').map(Number);
                const daysInMonth = new Date(year, monthNum, 0).getDate();
                const newMonthData: infoByCalendar[] = [];
    
                for (let i = 1; i <= daysInMonth; i++) {
                    newMonthData.push({
                        years: year,
                        month: monthNum,
                        day: i,
                        gdu: undefined,
                        id: undefined,
                    });
                }
    
                newData[month] = newMonthData;
            } else {
                newData[month] = datosAgrupados[month];
            }
        });

        if (newData) {
            setDataZonesFiltered(newData);           
        }  
        setCreatingList(false)
    };
    
    const generateMonthList = (start: dayjs.Dayjs, end: dayjs.Dayjs): string[] => {
        const monthList: string[] = [];
        let currentDate = start.startOf('month');
        while (currentDate.isBefore(end) || currentDate.isSame(end, 'month')) {
        monthList.push(currentDate.format('YYYY-MM'));
        currentDate = currentDate.add(1, 'month');
    
        }          
        return monthList;
    };
    
    return( 
        <> 
            <div className='GDUsHtorical_section_container'>                                  
                <Row
                    style={{                    
                        display: 'flex', 
                        alignItems: 'center',
                        width: '100%',
                        borderBottom: '1px solid  #6495ed'
                    }}
                >
                    <Col
                        span={22}
                    >
                    </Col>
                    <h2>GDUs Históricos</h2>   
                              
                </Row>
                <Row 
                    style={{
                        width: '100%',
                        padding:'20px 0px',
                    }}
                >
                    <Col 
                        span={20}
                        style={{          
                            display: 'flex',
                            flexDirection: 'row',
                            paddingLeft: '30px'
                        }}    
                    >
                        <Form
                            form={form_select_zone}
                            id='form_select_zone'
                            onFinish={handleShearch}
                            layout='inline'
                        >                 
                            <Form.Item
                                name='zone'
                                rules={[
                                    {
                                        required: true,
                                        message:'Este campo es obligatorio'
                                    }
                                ]}
                            >
                                <Select
                                    placeholder='Zona'
                                    labelInValue
                                    onChange={onChangeZone}
                                >
                                    {zones && zones.map((item) => (
                                        <Option value={item.code} key={item.id}>
                                            {item.code}
                                        </Option>
                                    ))} 
                                </Select>
                            </Form.Item>                    
                            <Form.Item>
                                <Tooltip
                                    title= 'Selecciona una zona para visualizar los GDUs historicos.'
                                    placement='right'
                                >
                                    <ExclamationCircleOutlined style={{
                                        color:'orange',
                                        cursor: 'pointer',
                                        marginLeft: '10px'}}/>
                                </Tooltip>  
                                                    
                            </Form.Item>                                               
                        </Form>
                        { creatingList &&
                            <> 
                                <LoadingOutlined style={{color:'gray'}}/> 
                                <p style={{margin: '5px', color:'gray'}}>cargando</p>
                            </>
                        }            
                    </Col>
                    <Col 
                        span={4}
                        style={{
                            display: 'flex',
                            justifyContent:'end',
                            paddingRight: '30px'

                        }}>
                        <Tooltip
                            title= 'Una vez activado el modo edición, modifica el directamente valor del gdu que deseas cambiar y listo, se guardará automáticamente.'
                            placement='right'
                        >
                            <ExclamationCircleOutlined style={{
                                color:'orange',
                                cursor: 'pointer',
                                marginRight: '10px'}}/>
                        </Tooltip> 
                        <Button
                            type='primary'
                            disabled={!dataZonesFiltered}
                            onClick={()=>setEditCalendar(!editCalendar)}
                        >
                            {
                                editCalendar ? 'Terminar edición' : 'Editar'
                            }
                        </Button> 
                    </Col>                   
                </Row>                                   
                  {
                    dataZonesFiltered &&
                    <CalendarByGDU 
                        dataZonesFiltered={dataZonesFiltered} 
                        zone={form_select_zone.getFieldValue('zone')}
                        setDataZonesFiltered={setDataZonesFiltered}
                    />
                  }  
                  {
                    !dataZonesFiltered && 
                    <div className='GDUsHtorical_section_no-data'>
                        <img src={waiting} alt='no data' />
                        <h3 >Haz una búsqueda </h3>
                        <p>Selecciona una zona para ver los GDUs históricos.</p>
                    </div>                   
                  }                 
                                   
            </div>
        </>
        
        
)};

export default GDUs_HistoricalSection;