import { axiosBackEndInstance } from '../Axios';
import { END_POINT_GENERIC_HISTORICAL_GDUs_BY_ZONE } from '../Axios/ENDPOINTS';
import { ZoneProps } from './useGetZone';
import { useErrorHandling } from '../hooks/useErrorHandling';
import { notification } from 'antd';

export interface historicalGDUs{
  id: number,
  zone: ZoneProps,
  historical_date: string,
  gdu: number,
}

export const useHistoricalGDUs = () => {
   
  const {errorHandling} = useErrorHandling()
     
  const getHistoricalGDUs =  async(
    zone: string
  ): Promise<historicalGDUs[] | undefined> =>{
    try {
      const  data = await axiosBackEndInstance.get(
        `${END_POINT_GENERIC_HISTORICAL_GDUs_BY_ZONE}/${zone}`,
      )
      //callback()
      if(data){
        return data.data
      }
    } catch (newError) {
      //callback()
      const status =  await errorHandling(newError).request.status      
      console.log(newError);
    }
  }    

    const createHistoricalGDUs = async (
      zone: string,
      date: string,
      gdu: string,        
    ): Promise<historicalGDUs | undefined> => {
      
      let newData = {        
        zone: zone,
        historical_date: date,
        gdu: gdu
      }
    try {
      const  data = await axiosBackEndInstance.post(
        END_POINT_GENERIC_HISTORICAL_GDUs_BY_ZONE, newData
      );
      if(data){
        return (data.data)
      } 
    } catch (newError) {
      let status: number = errorHandling(newError).request.status
      console.log(status);
      
    }
  };

  const editHistoricalGDUs = async (
    id: number,
    gdu: string,                             
  ): Promise<historicalGDUs | undefined> => {            
    try {
      const data  = await axiosBackEndInstance.patch(
        `${END_POINT_GENERIC_HISTORICAL_GDUs_BY_ZONE}/${id}`,{
          gdu:gdu
        } );
      if(data){
        return (data.data)
      }     
    } catch (newError) {  
      let status = errorHandling(newError).request.status
      console.log(newError);
      console.log(status)
    }
  };

  const deleteHistoricalGDUs = async (
    callback: Function,
    id: number                            
  ): Promise<void> => {        
    try {
      const data  = await axiosBackEndInstance.delete(
        `${END_POINT_GENERIC_HISTORICAL_GDUs_BY_ZONE}/${id}`, 
      );
      callback()   
      if(data.status === 200){
        //mutate()
        notification.success({
          message: 'Éxito', 
          description: 'GDU por zona  eliminada con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      callback()
      let status = errorHandling(newError).request.status
      console.log(newError);
      console.log(status)
    }
  };


  return {
    createHistoricalGDUs,
    deleteHistoricalGDUs,
    editHistoricalGDUs,
    getHistoricalGDUs
    
  };
};


