import React, { useContext, useEffect, useState } from 'react';
import './styles.scss'
import { Button, Modal, Row, Table } from 'antd';
import {
    EditOutlined,
    DeleteOutlined
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import  { ColumnsType } from 'antd/es/table';
import { MaterialFloweringMonthProps, useGetMaterialFloweringMonth,  } from '../../../Services/useGetMaterialFloweringMonth';
import { useMaterialFloweringByMonth } from '../../../Services/useMaterialFloweringByMonth';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import MaterialsFloweringByGDUsForm from '../../Forms/MaterialsFloweringByGDUsForm';
import { StylesContext } from '../../../Contexts/UserContext/StylesContext';

export interface DataType {
    index: number,
    id: number,
    material_id: string,
    material_code: string,
    zone_code: string,
    zone_id: string,
    month: string,
    gdus_until_beginning_of_flowering: string | undefined,
    gdus_until_50_percent_of_flowering: string | undefined,
    gdus_until_beginning_of_pollen: string | undefined,
    gdus_until_50_percent_of_pollen: string | undefined,
}

const MaterialsFloweringByMonth: React.FC = () => {

    const {data: dataApi} = useGetMaterialFloweringMonth()
    const {deleteMaterialFloweringbyMonth} = useMaterialFloweringByMonth()
    const {adjustedTableHeigthScroll} = useContext(StylesContext)
    
    const [form_Material_flowering_by_month] = useForm()
    const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
    const [dataTable, setDataTable] = useState<DataType[]>([])
    const [rowSelect, setRowSelect] = useState<DataType | undefined>(undefined)
    const [openForm, setOpenForm] = useState<boolean>()
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)

    const months = {
        '1': 'Enero',
        '2': 'Febrero',
        '3': 'Marzo',
        '4': 'Abril',
        '5': 'Mayo',
        '6': 'Junio',
        '7': 'Julio',
        '8': 'Agosto',
        '9': 'Septiembre',
        '10': 'Octubre',
        '11': 'Noviembre',
        '12': 'Diciembre'
    };

    useEffect(()=>{
        setLoadingTableData(true)
        if(dataApi){
            rowData() 
            setLoadingTableData(false)
        }
      },[dataApi])   

    const rowData = (data? : MaterialFloweringMonthProps[]) =>{
        let filterData: DataType[]= []
        let list: MaterialFloweringMonthProps[] = data ? data : dataApi
      
        if(list){
          list.map((data, index)=>{            
            filterData.push({
                index: index,
                id: data.id,
                material_id: data.material.id.toString(),
                material_code: data.material.code,
                zone_code: data.zone?.code || '',
                zone_id: data.zone?.id.toString() || '',
                month: data.month.toString(),
                gdus_until_beginning_of_flowering: data.gdus_until_beginning_of_flowering,
                gdus_until_50_percent_of_flowering: data.gdus_until_50_percent_of_flowering,
                gdus_until_beginning_of_pollen: data.gdus_until_beginning_of_pollen,
                gdus_until_50_percent_of_pollen: data.gdus_until_50_percent_of_pollen,                
            })
          })            
        }       
        setDataTable(filterData)
    }


    const callback = () =>{
        setLoadingTableData(false)
        setShowConfirmDelete(false) 
        setOpenForm(false)   
        form_Material_flowering_by_month.resetFields() 
        setRowSelect(undefined)     
    }

    const ModalConfirmDelete = () => {
        return(
            <Modal
                title="Eliminar"
                open={showConfirmDelete}
                onOk={()=>{
                    if(rowSelect && rowSelect.id){
                        deleteMaterialFloweringbyMonth(callback, rowSelect.id)
                    }}}
                onCancel={()=>setShowConfirmDelete(false)}
                okText="Eliminar"
                cancelText="Cancelar"
            >
                <p>¿Estás seguro de que desea eliminar los GDUs de floración asociados a {rowSelect?.material_code}?</p>
            </Modal>
        )
    }

    const handleRenderer = (text: string, data: DataType) =>{
        return (
            <div className='zone_action_action_render'>
                <EditOutlined 
                    className='icons'
                    onClick={()=>{
                        setRowSelect(data)
                        setOpenForm(true)
                    }}
                />
                <DeleteOutlined 
                    onClick={()=>{
                        setRowSelect(data)
                        setShowConfirmDelete(true)
                    }}
                    style={{marginLeft:'30px'}}
                    className='icons'
                    />
            </div>  
        )      
    }

    const handleRendererMonth = (text: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12') => {          
        return (
            <p>{months[text] || 'Mes no válido'}</p>
        )
    }

    // Guardia de tipo para chequear si el valor tiene una propiedad 'code'
    function hasCodeProperty(value: any): value is { code: string } {
        return value && typeof value === 'object' && 'code' in value;
    }

    const filters = (property: keyof MaterialFloweringMonthProps) => {
        let filter: { text: string; value: string }[] = [];
        if (dataApi) {
            dataApi.forEach((item) => {
                const value = item[property];
                if (hasCodeProperty(value)) {
                    const code = value.code;
                    if (!filter.some((existingItem) => existingItem.text === code)) {
                      filter.push({
                        text: code,
                        value: code,
                      });
                    }
                }
          });
        }
        return filter;
    }

    const filtersByMonth = () => {
        let filter: { text: string; value: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' }[] = [];
        if (dataApi) {
            dataApi.forEach((item) => {
                const value: string = String(item.month); // Convertir el número a cadena
                if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'].includes(value)) {
                    if (!filter.some((existingItem) => existingItem.value === value)) {
                        filter.push({
                            text: months[value as '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12'], // Utilizamos una aserción de tipo para informar a TypeScript sobre el tipo de índice esperado
                            value: value as '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12',
                        });
                    }
                }
            });
        }
        return filter;
    }

    const columns: ColumnsType<DataType> = [
        {
            title: 'Material',
            dataIndex: 'material_code',
            filters: filters('material'),
            onFilter: (value, record) =>
                typeof value === 'string' && record.material_code.includes(value),
            filterSearch: true,
            key: '1'
        },
        {
            title: 'Mes',
            dataIndex: 'month',
            render: handleRendererMonth,
            filters: filtersByMonth(),
            onFilter: (value, record) =>
                typeof value === 'string' && record.month.includes(value),
            filterSearch: true,
            key: '2'
        },
        {
            title: 'Zona',
            dataIndex: 'zone_code',
            filters: filters('zone'),
            onFilter: (value, record) =>
                typeof value === 'string' && record.zone_code.includes(value),
            filterSearch: true,
            key: '3'
        },
        {
            title: 'GDUs hasta el principio de floración',
            dataIndex: 'gdus_until_beginning_of_flowering',
            key: '4'
        },
        {
            title: 'Al 50% de floración',
            dataIndex: 'gdus_until_50_percent_of_flowering',
            key: '5'
        },    
        {
            title: 'GDUs hasta el pricipio del polen ',
            dataIndex: 'gdus_until_beginning_of_pollen',
            key: '6'
        },       
        {
            title: 'Al 50% frl polen',
            dataIndex: 'gdus_until_50_percent_of_pollen',
            key: '7',
            width: 150,
            ellipsis: true
        }, 
        {
            title: 'Acciones',
            dataIndex: 'id',
            render:  handleRenderer,
            key: '8',
            width: 110,
            ellipsis: true,     
            fixed: 'right'       
        },     
    ];

    const handleCancelForm = ()=>{
        form_Material_flowering_by_month.resetFields() 
        setRowSelect(undefined) 
        setOpenForm(false) 
        setLoadingTableData(false)
        setShowConfirmDelete(false) 
        setOpenForm(false) 
    } 

    return( 
        <>
            <ModalConfirmDelete/>    
            {
                openForm &&
                <MaterialsFloweringByGDUsForm
                    form_Material_flowering_by_month={form_Material_flowering_by_month}
                    openForm={openForm}
                    callback={callback}
                    rowSelect={rowSelect}
                />
            }
            <div className='material_section_container'>                                  
                <Row
                    className='bodyContainer_title'
                >
                    <h2>Floración de parentales - GDUs hasta el principio de floración</h2>                
                </Row> 
                <Row 
                   className='header_row'
                >              
                    <Button
                    type='primary'
                    onClick={()=>{
                        setOpenForm(true)
                    }}
                    >   Nueva carga
                    </Button>             
                </Row>                        
                         
                <Row 
                    className='bodyContent_container_table'
                >
                    {
                        loadingTableData
                        ? <SpinerHorizontal/> 
                        : <Table 
                            style={{
                                width:'95%',
                            }}
                            bordered
                            columns={columns} 
                            dataSource={dataTable}                                      
                            scroll={{ y: adjustedTableHeigthScroll }}
                            pagination={false}
                        />    
                    } 
                </Row>             
            </div>
        </>       
    )
};

export default MaterialsFloweringByMonth;