import { Form, Input, InputRef, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { useContext, useEffect, useRef, useState } from "react";
import { DataType } from "../Sections/SowPlanificationViewSection/types"; 
import { useLotByProduction } from "../../Services/useLotByProduction";
import { PlanificationContext } from "../../Contexts/UserContext/PlanificationContext";
import {
    LoadingOutlined
  } from '@ant-design/icons';
import { UserContext } from "../../Contexts/UserContext/UsersContext";

export const EditableCell: React.FC<{record: DataType, mutateTable: Function}> = ({
    record,
    mutateTable
}) => {
    const [editingCell, setEditingCell] = useState(false);
    const [form_edit_cell] = useForm();
    const inputRef = useRef<InputRef>(null);
    const [updatingData, setUpdatingData] = useState(false)
    const [invalidFormat, setInvalidFormat] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();

   const {editLotByProduction_patch} = useLotByProduction()
   const {zoneAndCampaignSelected, dataTable } = useContext(PlanificationContext)
   const {userData} = useContext(UserContext)

    useEffect(() => {
        if (editingCell) {
            (inputRef.current as HTMLInputElement | null)?.focus();
        }
    }, [editingCell]);

    useEffect(() => {
        if (updatingData) {
            setUpdatingData(false)
        }
    }, [dataTable]);

    const callback = () =>{
        setEditingCell(false)
    }

    const save = async () => {            
        setUpdatingData(true)
        setEditingCell(false)
        setInvalidFormat(false)
        try {
            const value = await form_edit_cell.validateFields();
            const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
            if (!dateRegex.test(value.date)) {
                setEditingCell(true)
                setInvalidFormat(true)
                messageApi.open({
                    type: 'error',
                    content: 'Formato de fecha inválido. Esperado: "0000-00-00".',
                  });
                throw new Error('Formato de fecha inválido. Debe ser "0000-00-00".');
            }
            const data = await editLotByProduction_patch(callback, Number(record.id), {tentative_sow_date: value.date});
            if(data){
                if(zoneAndCampaignSelected && data == 'update'){
                    mutateTable(zoneAndCampaignSelected)                    
                } 
            }                      
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };
  
    return (
        <div   style={{height: '18.84px', padding: '0px', margin:'0px', background: record.dateCellColor}} >  
            {contextHolder}          
            {editingCell ? 
                <Form
                    form={form_edit_cell}
                    name='form_edit_cell'
                >
                    <Form.Item
                        name='date'
                        style={{ padding: '0px', margin:'0px',height: '18.84px', }}
                        initialValue={record.tentative_sow_date}
                    >
                        <Input
                            //onClick={onClickInput}
                            style={{color: invalidFormat ? 'red': 'black', height: '18.84px', padding: '0px', marginTop:'0px', borderRadius: '0px', fontSize: '12px', textAlign: 'center', background:'#f8f8ec' }} 
                            ref={inputRef}  onPressEnter={save} />
                    </Form.Item>  
                </Form> 
                                                     
            : 
                <div 
                    className="editable-cell-value-wrap" 
                    style={{ 
                        textAlign: 'center',
                    }} 
                    onClick={()=>setEditingCell(true)} 
                >                        
                    {updatingData ? <> actualizando  <LoadingOutlined/> </>   : record.tentative_sow_date}
                </div>
            }
        </div>
    );
};

export default EditableCell;