import { useContext } from 'react';
import { notification } from 'antd';
import { AxiosResponse } from 'axios';
import { UserContext } from '../Contexts/UserContext/UsersContext';


export const useErrorHandlingReturn400 =() => {

  const {setAccesToken} = useContext(UserContext)

  const errorHandling = (data: AxiosResponse<any> | any ) =>{
    if(data.request.status === 200 || data.request.status === 201 || data.request.status === 400){                       
        return data   
    }else if(data.request.status === 401){
      console.log('useErrorHandlingReturn400')
      setAccesToken(undefined)
        notification.error({
          message: 'Error', 
          description:
            'Sesión vencida, vuelva a loguearse.', 
          placement: 'top'
        })
        return data
    }else{
        let error = data.request?.error ? data.request.error :  'Hubo un error, por favor comuníquese con el administrador'
        notification.error({
            message: 'Error', 
            description: error, 
            placement: 'topRight'
        })
        console.log(data.request?.error)
        return data
    } 
}

  return {
    errorHandling
  };
}