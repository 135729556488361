import React, { useEffect, useState } from 'react';
import './styles.scss'
import { Button,  Modal, Row, Table } from 'antd';
import {
    EditOutlined,
    DeleteOutlined
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { LotsForm } from '../../Forms/LotForm';
import { LotProps, useGetLot } from '../../../Services/useGetLot';
import { useLot } from '../../../Services/usetLot';
import moment from 'moment';

export interface DataType {
    index: number;
    id: number;
    code: string,
    zone_id: string,
    zone_code: string,
    farm_code: string,
    farm_id: number, 
    farm_description: string,
    hectares: number,
    contract_start_date: string,
    contract_end_date: string
}

const LotsSection: React.FC = () => {

    const {data: dataApi} = useGetLot()
    
    const {deleteLot} = useLot()
    const [form_lot] = useForm()
    const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
    const [loadingFilters, setLoadingFilters] = useState(false)
    const [dataTable, setDataTable] = useState<DataType[]>([])
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [rowSelect, setRowSelect] = useState<{
      show: boolean ;
      id: number | undefined;
      code: string | undefined,
      zone_id: string | undefined,
      zone_code: string | undefined,
      farm_code: string | undefined,
      farm_description: string | undefined,
      farm_id: number | undefined, 
      hectares: number | undefined,
      contract_start_date: string | undefined,
      contract_end_date: string | undefined,
      type: 'edit' | 'create' | 'delete' | undefined
  }>({
      show: false,
      id: undefined,
      code: undefined,
      zone_id: undefined,
      zone_code: undefined,
      farm_code: undefined,
      farm_id: undefined, 
      farm_description: undefined,
      hectares: undefined,
      contract_start_date: undefined,
      contract_end_date: undefined,
      type: undefined
  })


    useEffect(()=>{
      setLoadingTableData(true)
      if(dataApi){
          rowData() 
          setLoadingTableData(false)
      }
    },[dataApi])   

    const rowData = (data? : LotProps[]) =>{
        let filterData: DataType[]= []
        let list: LotProps[] = data ? data : dataApi
      
        if(list && list){
          list.map((data, index)=>{            
            filterData.push({
                index: index,
                id: data.id,
                code: data.code,                
                zone_id: data.farm.city.zone.id.toString(),
                zone_code: data.farm.city.zone.code,
                farm_code: data.farm.code,
                farm_id: data.farm.id,
                farm_description: data.farm.description,
                hectares: data.hectares,
                contract_start_date: data.contract_start_date,
                contract_end_date: data.contract_end_date
            })
          })         
        }       
        setDataTable(filterData)
    }


    const callback = () =>{
        setLoadingFilters(false)
        setLoadingTableData(false)
        setShowConfirmDelete(false)   
    }

    const handleRenderer = (text: string, data: DataType) =>{
      return (
        <div className='zone_action_action_render'>
            <EditOutlined 
              className='icons'
              onClick={()=>{
                  setRowSelect({
                      show: true,
                      id: data.id,
                      code: data.code,
                      zone_id: data.zone_id,
                      zone_code: data.zone_code,
                      farm_code: data.farm_code,
                      farm_id: data.farm_id, 
                      farm_description: data.farm_description,
                      hectares: data.hectares,
                      contract_start_date: data.contract_start_date,
                      contract_end_date: data.contract_end_date,
                      type: 'edit'
                  })
              }}
            />
            <DeleteOutlined 
              className='icons'
              onClick={()=>{                
                  setRowSelect({
                      show: false,
                      id: data.id,
                      code: data.code,
                      zone_id: data.zone_id,
                      zone_code: data.zone_code,
                      farm_code: data.farm_code,
                      farm_id: data.farm_id,                       
                      farm_description: data.farm_description,
                      hectares: data.hectares,
                      contract_start_date: data.contract_start_date,
                      contract_end_date: data.contract_end_date,
                      type: 'delete'
                  })
                  setShowConfirmDelete(true)
              }}
              style={{marginLeft:'30px'}} />
        </div>  
      )      
  }

  const ModalConfirmDelete = () => {
    return(
        <Modal
            title="Eliminar"
            open={showConfirmDelete}
            onOk={()=>{
                if(rowSelect && rowSelect.id){
                    deleteLot(callback, rowSelect.id)
                }}}
            onCancel={()=>setShowConfirmDelete(false)}
            okText="Eliminar"
            cancelText="Cancelar"
        >
            <p>¿Estás seguro de que desea eliminar {rowSelect?.code}?</p>
        </Modal>
    )
}
  
  const renderStartDate =(text: string, record: DataType)=>{
     let start_day: any = record.contract_start_date
      const startDate = start_day && moment(start_day).format('YYYY-MM-DD')
    return(
      <>{startDate}</>
    )
  }

  const renderEndDate =(text: string, record: DataType)=>{
     let end_day: any = record.contract_end_date
     const endDate = end_day && moment(end_day).format('YYYY-MM-DD') 
   return(
     <>{endDate}</>
   )
 }

  // const filters = ()=>{
  //   let textAndValue: {text: string, value: string}[] =[]
  //   dataApi?.map((item)=>{
  //       textAndValue.push({
  //           text: item.code,
  //           value:  item.code
  //       })
  //   })
  //   return textAndValue
  // }

  const filters = (type: 'zone' | 'code' ) => {
    let textAndValue: { text: string, value: string }[] = [];
    let uniqueValues = new Set();

    dataApi?.forEach((item) => {
        const code = type == 'zone' ? item.farm.city.zone.code : item.code;

        if (!uniqueValues.has(code)) {
            uniqueValues.add(code);
            textAndValue.push({
                text: code,
                value: code
            });
        }
    });
    return textAndValue;
  }

  const columns: ColumnsType<DataType> = [
      {
          title: 'Nombre de Lote',
          dataIndex: 'code',
          key: '1',
          filters: filters('code'),
          onFilter: (value, record) => typeof value === 'string' && record.code == (value),
          filterSearch: true,
          ellipsis: true,
      },
      {
        title: 'Zona',
        dataIndex: 'zone_code',
        key: '2',       
        filters: filters('zone'),
        onFilter: (value, record) => typeof value === 'string' && record.zone_code == (value),
        filterSearch: true,
        ellipsis: true,
      },
      {
          title: 'Campo',
          dataIndex: 'farm_code',
          key: '3',
          render: (text: string, record: DataType)=><p>{record.farm_code} - {record.farm_description}</p>,
          ellipsis: true,
      },
      {
          title: 'Hectáreas',
          dataIndex: 'hectares',
          render: (text: string)=><p>{text} ha</p>,
          key: '4',
          ellipsis: true,
      },
      // {
      //     title: 'Inicio del contrato',
      //     dataIndex: 'contract_start_date',
      //     key: 'contract_start_date',
      //     render: renderStartDate,
      //     ellipsis: true,
      // },
      // {
      //     title: 'Fin del contrato',
      //     dataIndex: 'contract_end_date',
      //     key: 'contract_end_date',          
      //     render: renderEndDate,
      //     ellipsis: true,
      // },   
      {
        title: 'Acciones',
        dataIndex: 'id',
        render:  handleRenderer,
        key: '5',
        width: 110,
        ellipsis: true,            
    },   
  ];

  const handleCancelForm = ()=>{
    form_lot.resetFields() 
    setRowSelect({
      show: false,
      id: undefined,
      code: undefined,
      zone_id: undefined,
      zone_code: undefined,
      farm_code: undefined,
      farm_id: undefined,       
      farm_description: undefined,
      hectares: undefined,
      contract_start_date: undefined,
      contract_end_date: undefined,
      type: undefined
    })        
}

  return( 
    <>
      <ModalConfirmDelete/>
      {rowSelect.show && <LotsForm
          form_lot = {form_lot}
          rowSelect = {rowSelect}
          handleCancelForm = {handleCancelForm}
      />}      
      <div className='zone_section_container'>                                  
          <Row
            className='bodyContainer_title'
          >
              <h2>Lotes</h2>                
          </Row>
          <Row 
            className='header_row'
          >              
            <Button
            type='primary'
            onClick={()=>{
                setRowSelect({
                  show: true,
                  id: undefined,
                  code: undefined,
                  zone_id: undefined,
                  zone_code: undefined,
                  farm_code: undefined,
                  farm_id: undefined, 
                  farm_description: undefined,
                  hectares: undefined,
                  contract_start_date: undefined,
                  contract_end_date: undefined,
                  type: 'create'
                })
                }}
            >   Nuevo lote
            </Button>             
          </Row>          
          <Row 
            className='bodyContent_container_table'
          >
            {
              loadingTableData
              ? <SpinerHorizontal/> 
              : <Table 
                  style={{
                      width: '80%',
                  }}
                  columns={columns} 
                  dataSource={dataTable}  
                  pagination={{
                      pageSize: 50,
                      showSizeChanger: false,                                      
                  }}
                  scroll={{ y: 580 }}
                />    
              } 
          </Row>             
      </div>
    </>                
  )
};

export default LotsSection;