import React, { useEffect, useState } from 'react';
import './styles.scss'
import { Button,  Modal, Row, Table } from 'antd';
import {
    EditOutlined,
    DeleteOutlined
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { CampaignProps, useGetCampaigns } from '../../../Services/useGetCampaigns';
import { useCampaigns } from '../../../Services/useCampaigns';
import CampaignForm from '../../Forms/CampaignForm';

export interface DataType {
    index: number;
    id: number;
    code: string,
    start_date: string,
    end_date: string
}

const CampaignsSection: React.FC = () => {

    const {data: dataApi} = useGetCampaigns()    
    const { deleteCampaign} = useCampaigns()
    
    const [form_campaign] = useForm()
    const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
    const [loadingFilters, setLoadingFilters] = useState(false)
    const [dataTable, setDataTable] = useState<DataType[]>([])
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [rowSelect, setRowSelect] = useState<{
        show: boolean ;
        id: number | undefined;
        code: string  | undefined;
        start_date: string | undefined,
        end_date: string | undefined,
        type: 'edit' | 'create' | 'delete' | undefined
    }>({
        show: false,
        id: undefined,
        code:  undefined,
        start_date: undefined,
        end_date: undefined,
        type: undefined
    })
    
    useEffect(()=>{
        setLoadingTableData(true)
        if(dataApi){
            rowData() 
            setLoadingTableData(false)
        }
    },[dataApi])  

    const rowData = (data? : CampaignProps[]) =>{
        let filterData: DataType[]= []
        let list: CampaignProps[] = data ? data : dataApi
      
        if(list && list){
          list.map((data, index)=>{            
            filterData.push({
                index: index,
                id: data.id,
                code: data.code,
                start_date: data.start_date,
                end_date: data.end_date
            })
          }) 
        }       
        setDataTable(filterData)
    }

    const callback = () =>{
        setLoadingFilters(false)
        setLoadingTableData(false)
        setShowConfirmDelete(false) 
    }

    const handleCancelForm = ()=>{
        form_campaign.resetFields() 
        setRowSelect({
            show: false,
            id: undefined,
            code:  undefined,
            start_date: undefined,
            end_date: undefined,
            type: undefined
        })        
    }


    const handleRenderer = (text: string, data: DataType) =>{
        return (
            <div className='zone_action_action_render'>
                <EditOutlined 
                    className='icons'
                    onClick={()=>{
                        setRowSelect({
                            show: true,
                            id: data.id,
                            code: data.code,
                            start_date: data.start_date,
                            end_date: data.end_date,
                            type: 'edit'
                        })
                    }}
                />
                <DeleteOutlined 
                    className='icons'
                    onClick={()=>{
                        setRowSelect({
                            show: false,
                            id: data.id,
                            code: data.code,
                            start_date: data.start_date,
                            end_date: data.end_date,
                            type: 'delete'
                        })
                        setShowConfirmDelete(true)
                    }}
                    style={{marginLeft:'30px'}} />
            </div>  
        )      
    }

    const ModalConfirmDelete = () => {
        return(
            <Modal
                title="Eliminar"
                open={showConfirmDelete}
                onOk={()=>{
                    if(rowSelect && rowSelect.id){
                        deleteCampaign(callback, rowSelect.id)
                    }}}
                onCancel={()=>setShowConfirmDelete(false)}
                okText="Eliminar"
                cancelText="Cancelar"
            >
                <p>¿Estás seguro de que desea eliminar {rowSelect?.code}?</p>
            </Modal>
        )
    }
  
    const columns: ColumnsType<DataType> = [
        {
            title: 'Código de Campaña',
            dataIndex: 'code',
            key: 'id',
            ellipsis: true,
        },
        {
            title: 'Fecha de inicio',
            dataIndex: 'start_date',
            key: 'start_date',
            ellipsis: true,
        },
        {
          title: 'Fecha de finalización',
          dataIndex: 'end_date',
          key: 'end_date',
          ellipsis: true,
      }, 
      
      {
        title: 'Acciones',
        dataIndex: 'id',
        render:  handleRenderer,
        key: 'id',
        width: 110,
        ellipsis: true,            
    },      
    ];

    return( 
        <>
            <ModalConfirmDelete/>
            {rowSelect.show &&<CampaignForm
                form_campaign={form_campaign}
                rowSelect = {rowSelect}
                handleCancelForm={handleCancelForm}
            />}      
            <div className='zone_section_container'>                                  
                <Row
                    className='bodyContainer_title'
                >
                    <h2>Campañas</h2>                
                </Row>
                <Row 
                   className='header_row'
                >              
                    <Button
                    type='primary'
                    onClick={()=>{
                        setRowSelect({
                            show: true,
                            id: undefined,
                            code: undefined,
                            start_date: undefined,
                            end_date: undefined,
                            type: 'create'
                        })
                        }}
                    >   Nuevo campaña
                    </Button>                    
                </Row>                
                <Row 
                    className='bodyContent_container_table'
                >
                    {
                        loadingTableData
                        ? <SpinerHorizontal/> 
                        : <Table 
                            style={{
                                width: '80%',
                            }}
                            columns={columns} 
                            dataSource={dataTable}  
                            pagination={{
                                pageSize: 50,
                                showSizeChanger: false,
                            }}
                            scroll={{ y: 580 }}
                        />    
                    } 
                </Row>             
            </div>
        </>
        
        
)};

export default CampaignsSection;