import { useContext } from "react";
import { useErrorLoguin } from "../hooks/useErrorLoguin";
import { UserContext } from "../Contexts/UserContext/UsersContext";
import { END_POINT_ACCOUNT_PROFILE } from '../Axios/ENDPOINTS';
import { axiosBackEndInstance } from "../Axios";
import { ProfileProps } from "./useGetProfile";
import { useNavigate } from 'react-router';
import { notification } from 'antd';

export const useProfile = () => {

    const { errorHandling } = useErrorLoguin();
    const { setUnlog, setAccesToken, accesToken } = useContext(UserContext);
    const navigate = useNavigate();
     
    const getProfile = async (
        callback?: Function,
    ): Promise<ProfileProps | void> => {      
        try {
            const { data } = await axiosBackEndInstance.get(END_POINT_ACCOUNT_PROFILE);
            callback && callback();
            if (data) {
                setUnlog(true);
                return data;
            }
        } catch (error: any) {
            callback && callback();
            if (error?.response?.status === 401) { // token vencido o no autorizado 
                setAccesToken(undefined);
                navigate('/');     
                console.log(error);  
                notification.error({
                    message: 'Error', 
                    description:
                      'Sesión vencida', 
                    placement: 'top'
                  })   
            } else {
                errorHandling(error); 
            }
        }
    };

    return {
        getProfile
    };
};