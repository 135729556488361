import { axiosBackEndInstance } from '../Axios';
import { END_POINT_GENERIC_FARM_OWNER } from '../Axios/ENDPOINTS';
import { notification } from 'antd';
import { useErrorHandling } from '../hooks/useErrorHandling';
import { OwnersProps, ResponseGetOwners, useGetOwners } from './useGetOwners';


export const useOwners= () => {

    const {errorHandling} = useErrorHandling()
    const { mutate } = useGetOwners()
     
    const getFilteredOwner = async (
      callback: Function,
      owner_id: number,
      owner_code: string,
      name: string,
      lastname: string,
      pageValue?: string | undefined,        
      ): Promise<OwnersProps[] | undefined> => {
      
      var params = new URLSearchParams();
      pageValue && params.append("page", pageValue)
      owner_id && params.append("owner_id", owner_id.toString())  
      owner_code && params.append("owner_code", owner_code)  
      name && params.append("locality_code", name)  
      lastname && params.append("locality_id", lastname)  
     
      try {
        const {
          data,
        }: { data: any } = await axiosBackEndInstance.get(
          END_POINT_GENERIC_FARM_OWNER,
          { 
            params
          }
        );
        callback()
        return data;
      } catch (newError) {
        console.log(newError);
        callback()
       // throw newError;
      }
    };

    const createOwner = async (
      callback: Function,
      newOwner:{
        code: string,
        name: string,
        last_name: string, 
        phone: string
      }
      
      ): Promise<any | undefined> => {      
    try {
      const  data = await axiosBackEndInstance.post(
        END_POINT_GENERIC_FARM_OWNER, newOwner
      )
      callback()   
      if(data.status === 200 || data.status === 201){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Dueño creado con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      console.log(newError);
      callback()
      //throw newError;
    }
  };

  const editOwner = async (
    callback: Function,
    id: number,
    values:{
      code: string,
      name: string,
      last_name: string, 
      phone: string
    }
                            
  ): Promise<void> => {
            
    try {
      const data  = await axiosBackEndInstance.put(
        `${END_POINT_GENERIC_FARM_OWNER}/${id}`,values
      );
      callback()   
      if(data.status === 200 || data.status === 201){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Dueño editada con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {      
      callback()
      let status = errorHandling(newError).request.status
      console.log(newError);
      console.log(status)
    }
  };

  const deleteOwner = async (
    callback: Function,
    id: number                            
  ): Promise<void> => {
      
    try {
      const data  = await axiosBackEndInstance.delete(
        `${END_POINT_GENERIC_FARM_OWNER}/${id}`, 
      );
      callback()   
      if(data.status === 200){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Dueño eliminado con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      callback()
      let status = errorHandling(newError).request.status
      console.log(newError);
      console.log(status)
    }
  };

  return {
    getFilteredOwner,
    createOwner,
    editOwner,
    deleteOwner
  };
};
